import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { ImageCropper, Loader } from "../../Components";
import { isString } from "../../Utility/validations";
import { useFormik } from "formik";
import * as Yup from "yup";
import { bytesToKB, routeName, showToast } from "../../Utility";
import {
  getInsightDetailApi,
} from "../../Redux/Services/OtherServices";
import { useLocation, useNavigate } from "react-router-dom";
import EditText from "../../Components/TextEditor";
import {
  addEditBlogApi,
  uploadMedia,
} from "../../Redux/Services/AdminServices";

const AddBlogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [cropped, setCropped] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [loading2, setLoading2] = useState(false)

  const uploadProfilePhoto = async (data, type) => {
    let uploadData = new FormData();
    uploadData.append("file_type", data);
    setLoading(true);
    let res = await dispatch(uploadMedia(uploadData));
    setLoading(false);
    if (res?.status == 200) {
      setCropped(false);
      setSelectedFile([]);
      setProfileImage({
        ...profileImage,
        uri: res?.results?.uri,
        name: res?.results.name,
        size: res?.results.size,
        type: res?.results?.type,
        id: res?.results?.attachment_id,
      });
    }
  };

  const handleChooseProfile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      const detail = {
        uri: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        type: file?.type,
      };
      setSelectedFile(detail);
      setCropped(true);
    } else {
      setProfileImage(null);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Caption is required"),
    // address: Yup.string().required("Description is required"),
  });

  const getSingleBlogDetail = async () => {
    setLoading2(true);
    let body = {
      post_type: "blog",
      post_id: id,
    };
    let res = await dispatch(getInsightDetailApi(body));
    setLoading2(false);
    if (res?.status == 200) {
      formik.setFieldValue("name", res?.results?.post_title || "");
      setDescription(res?.results?.post_content || "");
      const detail = {
        uri: res?.results?.thumbnail?.url,
        name: res?.results?.thumbnail?.url?.split(".").pop(),
        size: 58221,
        type: "image/jpg",
        id: res?.results?.thumbnail?.thumb_id || "",
      };
      setProfileImage(detail);
    }
  };

  const onSubmit = async (values) => {
    if (!profileImage) {
      showToast("PLease upload the thumbnail image");
      return;
    }
    try {
      console.log("Form values:", values);
      let body = {
        post_title: formik.values.name,
        post_description: description,
        thumbnail_id: profileImage?.id,
      };
      if (id) {
        body.action = "update";
        body.blog_id = id;
      }

      setLoading(true);
      let res = await dispatch(addEditBlogApi(body));

      setLoading(false);

      console.log("identityVerification res---", res);
      if (res?.status === 200) {
        navigate(routeName.MANAGE_BLOGS, { replace: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const removeGalleryImage = async (eachImage, type) => {
    setProfileImage("");
  };

  useEffect(() => {
    if (id) {
      getSingleBlogDetail();
    }
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading || loading2} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className=" idVerification">
            <div className="col-md-12">
              <form
                onSubmit={formik.handleSubmit}
                className="post-identity-form wt-haslayout wt-attachmentsholder"
              >
                <div className="wt-dashboardbox">
                  <div className="wt-dashboardboxtitle wt-titlewithsearch">
                    <h2>{id ? "Update Blog" : "Add New Blog"}</h2>
                  </div>
                  <div className="wt-dashboardboxcontent">
                    <div className="wt-helpsupportcontents">
                      <div className="form-grou">
                        <div className="wt-formtheme wt-formidentityinfo wt-formprojectinfo wt-formcategory wt-userform">
                          <fieldset>
                            <div className="form-group ">
                              <h2>Title</h2>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Write a Title"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.name}
                              />
                              {formik.errors.name && formik.touched.name && (
                                <div className="error">
                                  {formik.errors.name}
                                </div>
                              )}
                            </div>

                            <div className="wt-tabscontenttitle">
                              <h2>Upload Thumbnail Image</h2>
                            </div>
                            <div class="wt-bannerphoto wt-tabsinfo">
                              <div class="wt-profilephotocontent">
                                <div class="form-group form-group-label">
                                  <div class="wt-labelgroup">
                                    <label for="filep">
                                      <span class="wt-btn">Select Files</span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="filep"
                                        onChange={handleChooseProfile}
                                      />
                                    </label>
                                    <span>Choose file to upload</span>
                                  </div>
                                </div>
                                {selectedFile && (
                                  <ImageCropper
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    imageSrc={selectedFile?.uri}
                                    type={"profile"}
                                    show={cropped}
                                    setShow={setCropped}
                                    cropped={cropped}
                                    setCropped={setCropped}
                                    profileImage={profileImage}
                                    setProfileImage={setProfileImage}
                                    onCrop={uploadProfilePhoto}
                                  />
                                )}
                                {profileImage && (
                                  <div class="form-group">
                                    <ul class="wt-attachfile wt-attachfilevtwo">
                                      <li class="wt-uploadingholder">
                                        <div class="wt-uploadingbox">
                                          <div class="wt-designimg">
                                            <input
                                              id="demoq"
                                              type="radio"
                                              name="employees"
                                              value="company"
                                            />
                                            <label for="demoq">
                                              <img
                                                src={
                                                  profileImage?.url
                                                    ? profileImage?.url
                                                    : profileImage?.uri
                                                }
                                                alt="img description"
                                              />
                                              <i class="fa fa-check"></i>
                                            </label>
                                          </div>
                                          <div class="wt-uploadingbar">
                                            <span class="uploadprogressbar"></span>
                                            <span>{profileImage?.name}</span>
                                            <em>
                                              {profileImage?.size && (
                                                <>
                                                  File size:{" "}
                                                  {bytesToKB(
                                                    profileImage?.size
                                                  )}
                                                </>
                                              )}
                                              <i
                                                class="fa fa-times lnr lnr-cross"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  //  setProfileImage("")
                                                  removeGalleryImage(
                                                    profileImage,
                                                    "profile"
                                                  )
                                                }
                                              ></i>
                                            </em>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <h2>Description</h2>
                              {loading2 ? undefined : (
                                <EditText
                                  content={description}
                                  setContent={setDescription}
                                  placeholder={"Blog Description"}
                                />
                              )}
                            </div>
                            <button
                              type="submit"
                              className="wt-btn wt-save-identity"
                            >
                              Submit
                            </button>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </SidebarLayout>
  );
};

export default AddBlogs;
