import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../Redux/Services/AuthServices";
import { DeletePost, Loader, PlansCard } from "../../Components";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routeName, showToast } from "../../Utility";
import { getData, storageKey } from "../../Utility/Storage";
import { deletePackageApi } from "../../Redux/Services/AdminServices";

export const ManagePackages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [packagesList, setPackagesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    getAllPackages(1);
  }, []);

  const getAllPackages = async (type) => {
    setLoading(true);
    let res = await dispatch(getPackages());
    setLoading(false);
    if (res?.status == 200) {
      setPackagesList(res?.results);
    }
  };

  const handleDeletePackage = async () => {
    const userId = await getData(storageKey.USER_ID);
    try {
      let body = {
        user_id: userId ? JSON.parse(userId) : "",
        package_id: selectedId,
      };
      setLoading(true);
      let res = await dispatch(deletePackageApi(body));
      setLoading(false);
      if (res?.status === 200) {
        showToast("Package deleted successfully", "success");
        getAllPackages();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>

      <div className="list_cnnt">
              <h3>{`MANAGE PACKAGES`}</h3>

              <a
                class="wt-btn"
                onClick={() => {
                  navigate(routeName.ADD_PACKAGE);
                }}
              >
                {` Add Package`}
              </a>
            </div>
            
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <section className="packages">
            <div className="wt-sectionhead" />

            <div className=" container">
              <div className="wt-tabs">
                <div className="row">
                  {packagesList?.map((item) => {
                    return (
                      <>
                        <PlansCard
                          setSelectedId={setSelectedId}
                          setIsDeleteModal={setIsDeleteModal}
                          type="edit"
                          cardData={item}
                          isDelete= {item?.subscribed_status}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeletePackage}
          text="Are you sure you want to delete this package?"
        />
      ) : undefined}
    </SidebarLayout>
  );
};
