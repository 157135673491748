import React, { useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { JobPostForm, Loader } from "../../Components";
import { useLocation } from "react-router-dom";

const JobPost = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation()

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  // console.log("Your Param Value:", id);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <JobPostForm type='jobPost' setLoading={setLoading} id={id || ""} />
        </div>
      </main>
    </SidebarLayout>
  );
};

export default JobPost;
