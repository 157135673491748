import React, { useEffect, useState } from "react";
import SidebarLayout from "../../../layout/SidebarLayout";
import { DeletePost, Loader, PostCard } from "../../../Components";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePost,
  getUserSocialPosts,
  likeDislike,
} from "../../../Redux/Services/OtherServices";
import { getAdminPostsApi } from "../../../Redux/Services/AdminServices";

const AdminPosts = () => {
  const dispatch = useDispatch();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const getPortfolioData = async () => {
    try {
      let body = {
        page_number: 1,
        per_page: 20
      };
      setLoading(true);
      let res = await dispatch(getAdminPostsApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setPortfolios(res?.results?.data);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeletePost = async () => {
    // try {
    //   let body = {
    //     user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
    //     action: "delete",
    //     post_id: selectedId,
    //   };
    //   setLoading(true);
    //   let res = await dispatch(deletePost(body));
    //   setLoading(false);
    //   if (res?.status === 200) {
    //     getPortfolioData();
    //   }
    // } catch (err) {
    //   setLoading(false);
    // }
  };

  const handleLikeUnlike = async (type, id) => {
    try {
      let body = {
        type: type,
        post_id: id,
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(likeDislike(body));
      setLoading(false);
      if (res?.status === 200) {
        getPortfolioData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPortfolioData();
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
        <div className="post-card-grid">
          <h3 className="postTitl" >Social Posts</h3>

          {portfolios?.length ? (
            portfolios?.map((item, index) => (
              <div key={index} className="post-card-grid">
                  <PostCard
                    setIsDeleteModal={setIsDeleteModal}
                    type="post"
                    cardData={item}
                    setSelectedId={setSelectedId}
                    handleLikeUnlike={handleLikeUnlike}
                    page="manage"
                    route="admin"
                  />
                </div>
            ))
          ) : loading ? undefined : (
            <span>No Social Post Found</span>
          )}
        </div>
        </div>
      </main>

      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeletePost}
          text="Are you sure you want to delete this post?"
        />
      ) : undefined}
    </SidebarLayout>
  );
};

export default AdminPosts;
