// Filename - Home.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {  useNavigate } from "react-router-dom";
import { Header, Footer, PlansCard, BlogCard, Loader } from "../../Components";
import Carousel from "react-bootstrap/Carousel";
import { Colors, Images } from "../../Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Spinner } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import {
  getBlogReviewsList,
  getJobsList,
  getKidsList,
  getModelsList,
  sendNewsLetter,
  userFollowing,
} from "../../Redux/Services/OtherServices";
import { getPackages, getUserDetail } from "../../Redux/Services/AuthServices";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {  Login } from "../../Components/Popups";
import { routeName, showToast } from "../../Utility";
import { getData, storageKey } from "../../Utility/Storage";
import { LoginAlert } from "../../Components/Popups/LoginAlert";

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [packagesList, setPackagesList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [tab, setTab] = useState(1);
  const [blogPage, setBlogPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewMoreLoad, setViewMoreLoad] = useState(false);
  const [searchValue, setSearchValue] = useState("Model");
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [addTitle, setAddTitle] = useState("")

  const getUserData = async () => {
    if (loggedInUser?.user_data?.user_id) {
      let body = {
        user_id: loggedInUser?.user_data?.user_id || "",
      };
      await dispatch(getUserDetail(body));
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      console.log(currentSlide, "lcurenttt");
      // Check if the last slide is reached
      if (currentSlide === 16) {
        // Navigate to another screen when the last slide is reached
        // Replace the following line with your navigation logic
        console.log("Last slide reached. Navigating to another screen.");
        navigate(routeName.SEARCH_TALENT);
      }
    },
  };
  var sculpReview = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: true,
          dots: false,
        },
      },
    ],
  };
  var jobList = {
    dots: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setLoading(true);
    setTab(1);
    getAllModelsList();
    getAllKidsList();
    getAllJobsList();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllPackages(1);
    handleBlogList();
    handleReviewsList();
  }, []);

  const getAllPackages = async (type) => {
    // setLoading(true);
    let res = await dispatch(getPackages());
    if (res?.status == 200) {
      if (type == 1) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "monthly"
        );
        setPackagesList(data);
        setLoading(false);
      } else if (type == 2) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "yearly"
        );
        setPackagesList(data);
        setLoading(false);
      }
      setTab(type);
    }
  };
  const handleBlogList = async () => {
    setLoading(true);
    var body = {
      post_type: "blogs",
      page_number: "1",
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(res?.results?.post);
    }
  };

  const getMoreBlogList = async () => {
    setViewMoreLoad(true);
    var body = {
      post_type: "blogs",
      page_number: blogPage + 1,
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(blogList?.concat(res?.results?.post));
      setBlogPage(blogPage + 1);
      setViewMoreLoad(false);
    }
  };

  const handleReviewsList = async () => {
    setLoading(true);
    var body = {
      post_type: "reviews",
      page_number: "1",
      per_page: "20",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setReviewsList(res?.results?.post);
    }
  };
  const getAllModelsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getModelsList(body));
    if (res?.status == 200) {
      let arr = res?.results?.user_list?.filter(
        (item) => item?.post_meta_details?.subscription_pro_id != 107
      );
      setModelsList(arr);
      setLoading(false);
    }
  };
  const getAllKidsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getKidsList(body));
    if (res?.status == 200) {
      setKidsList(res?.results?.user_list);
      setLoading(false);
    }
  };

  const getAllJobsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
    };
    let res = await dispatch(getJobsList(body));
    if (res?.status == 200) {
      setJobsList(res?.results?.list);
      setLoading(false);
    }
  };
  const handleSubscribe = async () => {
    if (!email) {
      showToast("Please enter the email", "error");
    } else {
      var body = {
        user_email: email,
      };
      let res = await dispatch(sendNewsLetter(body));
      if (res?.status == 200) {
        setEmail("");
      }
    }
  };

  const handleSearch = () => {
    if (searchValue == "Model" || searchValue == "Kid") {
      navigate(routeName?.SEARCH_TALENT);
    } else if (searchValue == "Job") {
      navigate(routeName?.SEARCH_JOBS);
    }
  };

  return (
    <div>
      <Header />
      <Loader
        loading={
          viewMoreLoad
            ? null
            : loading
            ? loading
            : other?.isLoading
            ? other?.isLoading
            : auth?.isLoading
            ? auth?.isLoading
            : false
        }
      />

      <section className="heroSlider">
        <Carousel className="carousel-fade">
          <Carousel.Item>
            <img src={Images?.hero1} />
          </Carousel.Item>
          <Carousel.Item>
            <img src={Images?.hero2} />
          </Carousel.Item>
        </Carousel>
        <div className="wt-bannercontent">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                <div className="wt-bannerhead">
                  <div className="wt-title">
                    <h1>Find Talent Near You</h1>
                  </div>
                  <div className="wt-description">
                    <p>
                      Matching the needs of advertising professionals,
                      photographers, commercial studios, print, tv, and film
                    </p>
                  </div>
                </div>
                <form className="wt-formtheme wt-formbanner">
                  <fieldset>
                    <div className="form-group">
                      <input
                        disabled
                        type="text"
                        name="fullname"
                        className="form-control"
                        placeholder="I’m looking for"
                      />
                      <div className="wt-formoptions">
                        <Form.Select
                          size="lg"
                          onChange={(ele) => {
                            setSearchValue(ele.target.value);
                          }}
                          value={searchValue}
                        >
                          <option>Model</option>
                          <option>Job</option>
                          <option>Kids</option>
                        </Form.Select>
                        <a
                          className="wt-searchbtn"
                          onClick={() => handleSearch()}
                        >
                          <i className="lnr lnr-magnifier"></i>
                        </a>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modelsList && modelsList?.length != 0 && (
        <section className="modelList">
          <div className="wt-sectionhead wt-textcenter">
            <div className="wt-sectiontitle">
              <h2>Featured Talent</h2>
            </div>
            <div className="wt-description">
              <p>Find the talent for your needs</p>
            </div>
          </div>
          <Container>
            <div className="fullSlide">
              <Slider {...settings}>
                {modelsList?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        const queryParams = new URLSearchParams({
                          id: item?.post_meta_details?.user_id,
                        });
                        navigate(
                          `${
                            routeName.TALENT_PROFILE
                          }?${queryParams.toString()}`
                        );
                      }}
                      key={index}
                    >
                      <TalentCard
                        cardData={item}
                        setLoading={setLoading}
                        getApiData={getAllModelsList}
                        setLoginModalVisible={setLoginModalVisible}
                        setAddTitle={setAddTitle}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </Container>
        </section>
      )}

      {kidsList && kidsList?.length != 0 && (
        <section className="kidList">
          <div className="wt-sectionhead wt-textcenter">
            <div className="wt-sectiontitle">
              <h2>Featured Kids</h2>
            </div>
          </div>
          <Container>
            <div className="fullSlide">
              <Slider {...settings}>
                {kidsList?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        if(!loggedInUser?.user_data?.user_id){
                          setAddTitle("Only approved members will see kids profile")
                          setLoginModalVisible(true);
                          return
                        }
                        const queryParams = new URLSearchParams({
                          id: item?.post_meta_details?.user_id,
                        });
                        navigate(
                          `${
                            routeName.TALENT_PROFILE
                          }?${queryParams.toString()}`
                        );
                      }}
                      key={index}
                    >
                      <TalentCard
                        cardData={item}
                        setLoading={setLoading}
                        getApiData={getAllKidsList}
                        setLoginModalVisible={setLoginModalVisible}
                        setAddTitle={setAddTitle}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </Container>
        </section>
      )}
      {jobsList && jobsList?.length != 0 && (
        <section className="jobList">
          <div className="wt-sectionhead wt-textcenter">
            <div className="wt-sectiontitle">
              <h2>Listings Projects</h2>
            </div>
            <div className="wt-description">
              <p>Find the Job for your needs</p>
            </div>
          </div>
          <Container>
            <div className="fullSlide">
              <Slider {...jobList} infinite={jobList.length >= 3}>
                {jobsList?.map((item) => {
                  return (
                    <>
                      <JobCard cardData={item} />
                    </>
                  );
                })}
              </Slider>
            </div>
          </Container>
        </section>
      )}

      <section className="bookBanner">
        <div className="bannerCaption container">
          <h2>Book Talent Anywhere</h2>
          <p>
            A platform dedicated to helping find diverse talent for all types of
            projects. Sign up today and start looking for talent!
          </p>
        </div>
      </section>

      <section className="sculpReviw">
        <div className="wt-sectionhead wt-textcenter">
          <div className="wt-sectiontitle">
            <h2>What our users say</h2>
          </div>
          <div className="wt-description">
            <p>Find the Job for your needs</p>
          </div>
        </div>
        <Container>
          <div className="fullSlide">
            <Slider {...sculpReview}>
              {reviewsList?.map((item) => {
                return (
                  <>
                    <ReviewCard cardData={item} />
                  </>
                );
              })}
            </Slider>
          </div>
        </Container>
      </section>

      {loggedInUser?.user_data?.user_role == 12 ||
      loggedInUser?.user_data?.user_role == 14 ? undefined : (
        <section className="packages">
          <div className="wt-sectionhead wt-textcenter">
            <div className="wt-sectiontitle">
              <h2>Talent, Choose the Right Plan For You!</h2>
            </div>
          </div>
          <div className=" container">
            <div className="wt-tabs">
              <div className="tabFlex">
                <div
                  className={tab !== 1 ? "active" : null}
                  onClick={() => {
                    getAllPackages(1);
                  }}
                >
                  Monthly
                </div>
                <div
                  className={tab !== 2 ? "active" : null}
                  onClick={() => {
                    getAllPackages(2);
                  }}
                >
                  Yearly
                </div>
              </div>
              <div className="row">
                {packagesList?.map((item) => {
                  return (
                    <>
                      <PlansCard cardData={item} />
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bookBanner newsletter">
        <div className="bannerCaption container">
          <div className="subForm">
            <h2>Subscribe to our Newsletter</h2>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e?.target?.value);
                  }}
                />
              </Form.Group>

              <Button variant="primary" onClick={() => handleSubscribe()}>
                Subscribe
              </Button>
            </Form>
          </div>
        </div>
      </section>

      <section className="blogsScreen">
        <Container>
          <div className="wt-sectionhead wt-textcenter">
            <div className="wt-sectiontitle">
              <h2>From The Insights</h2>
            </div>
            <div className="wt-description">
              <p>Latest News From The Insights</p>
            </div>
          </div>
          <div className="blogList">
            <div className="row">
              {blogList?.map((item) => {
                return (
                  <>
                    <BlogCard cardData={item} />
                  </>
                );
              })}
              {viewMoreLoad ? (
                <div className="loadMore">
                  <Button variant="" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col-md-12 text-center"
                    onClick={() => getMoreBlogList()}
                  >
                    <button class="moreBlog">View More</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>

      <Footer />
      {loginModalVisible && (
        <LoginAlert
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
          title={addTitle}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
    </div>
  );
};

const TalentCard = (props) => {
  const { cardData, getApiData, setLoading, setLoginModalVisible, setAddTitle } = props;
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);


  const handleFollowUnFollow = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      const isFollow =
        cardData?.post_meta_details?.user_followers?.length &&
        loggedInUser?.user_data?.user_id
          ? cardData?.post_meta_details?.user_followers?.includes(
              loggedInUser?.user_data?.user_id
            )
          : false;
      setLoading(true);
      let body = {
        user_id: user,
        post_id: cardData?.profile?.ID,
        action: isFollow ? "unfollow" : "follow",
      };
      let res = await dispatch(userFollowing(body));
      setLoading(false);
      if (res?.status == 200) {
        getApiData();
        // showToast(
        //   isFollow ? "UnFollow Successfully" : "Follow successfully",
        //   "success"
        // );
      }
    } else {
      setAddTitle("")
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  return (
    <>
      <div>
        <div className="rpc-post-image">
          <a href="#">
            <div className="fixed-height-image">
              <img src={cardData?.profile_image} />
            </div>
          </a>
        </div>
        <a href="#" className="craContent" id="" tabindex="-1">
          <div className="bottomArea ">
            {cardData?.post_meta_details?.user_rating > 0 ? (
              <div
                className="numerical-rating high"
                data-rating={cardData?.post_meta_details?.user_rating}
              >
                <i className="fa fa-star"></i>
                {cardData?.post_meta_details?.user_rating}
              </div>
            ) : null}

            <h3 className="rpc-post-title">
              <div className="rpc-title" target="_self">
                <span
                  className="wt-tipso tipso_style"
                  data-tipso="ID-Verified"
                  data-toggle="tooltip"
                >
                  <i
                    className="fa fa-check"
                    color={
                      cardData?.post_meta_details?.is_verified == "yes"
                        ? Colors?.themeColor
                        : Colors?.red
                    }
                  ></i>
                </span>
                {`${cardData?.post_meta_details?.first_name || ""} ${
                  cardData?.post_meta_details?.last_name?.slice(0,1) || ""
                }`}
              </div>
            </h3>

            <span className="location">
              <i className="fa fa-crosshairs"></i>
              {cardData?.post_meta_details?.country +
                " | " +
                cardData?.post_meta_details?.city}
            </span>

            <Tooltip
              style={{ marginLeft: 15 }}
              arrow
              placement="top-start"
              title={
                cardData?.post_meta_details?.user_followers?.includes(
                  loggedInUser?.user_data?.user_id
                )
                  ? "Unfollow"
                  : "Follow"
              }
            >
              <span
                className=" wt-clicksave  wt_login_follow_offer "
                data-url=""
                data-userid="0"
                data-postid="22230"
                data-type="follower"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFollowUnFollow();
                }}
              >
                <i
                  className={
                    cardData?.post_meta_details?.user_followers?.includes(
                      loggedInUser?.user_data?.user_id
                    )
                      ? "fa fa-heart"
                      : "fa fa-heart-o"
                  }
                  style={{ color: Colors?.white }}
                ></i>
              </span>
            </Tooltip>
          </div>

          <div className="topArea">
            <span className="hourRate">
              <i className="fa fa-money"></i>
              {`$${cardData?.post_meta_details?.perhour_rate} / Hr`}
            </span>

            <div className="listing-small-badge featured-badge"></div>
          </div>
        </a>
      </div>
    </>
  );
};

const JobCard = (props) => {
  const { cardData } = props;
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="wt-latestproject">
          <div className="pVerified">
            Payment verified <i className="fa fa-check"></i>
          </div>
          <figure className="wt-projectuser-img">
            <img
              decoding="async"
              src={cardData?.profile_image}
              alt="Testaccount test"
            />
          </figure>
          <div className="wt-usertags">
            <a
              onClick="event_preventDefault(event);"
              className="wt-hourlytag"
              tabindex="0"
            >
              {cardData?.post_meta_details?._project_type}
            </a>
            <a
              onClick="event_preventDefault(event);"
              className="wt-add-to-saved_projects click_saved wt-likeproject"
              data-type="v2"
              data-userid=""
              data-id="32227"
              data-text="Saved"
              tabindex="0"
            >
              <i className="ti-heart"></i>
            </a>
          </div>
          <div className="wt-project_title">
            <h3>
              <a>{cardData?.profile?.post_title}</a>
            </h3>
            {cardData?.profile?.post_content && (
              <p>{cardData?.profile?.post_content}</p>
            )}
          </div>
          <ul className="wt-projectinfo">
            <li className="wt-projectinfo-budget viewJob">
              <div>
                <span>
                  Project budget:{" "}
                  {`$${cardData?.post_meta_details?.project_budget}`
                    ? `$${cardData?.post_meta_details?._hourly_rate} ${
                        cardData?.post_meta_details?._project_type ===
                        "Hourly Rate"
                          ? "/ Hourly"
                          : ""
                      }`
                    : cardData?.post_meta_details?.project_budget}
                </span>
                {cardData?.post_meta_details?._project_type ===
                "Hourly Rate" ? (
                  <strong>
                    {cardData?.post_meta_details?._estimated_hours
                      ? `Estimated Hours - ${cardData?.post_meta_details?._estimated_hours}`
                      : "0/hr"}
                  </strong>
                ) : null}
              </div>
              <div>
                <a
                  onClick={() => {
                    const queryParams = new URLSearchParams({
                      id: cardData?.profile?.ID,
                    });
                    navigate(
                      `${routeName.JOB_DETAILS}?${queryParams.toString()}`
                    );
                  }}
                  className="wt-hourlytag"
                >
                  View Job
                </a>
              </div>
            </li>
          </ul>
          <div className="wt-prjectstags"></div>
          <div className="ratingLocation">
            {/* <div className="numerical-rating high" data-rating="5.0">
              <i className="fa fa-star"></i>
              {cardData?.rating}
            </div> */}
            <span className="useer_location_">
              <span> {cardData?.post_meta_details?.country}</span>
              <span> / {cardData?.post_meta_details?.city}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const ReviewCard = (props) => {
  const { cardData } = props;

  return (
    <>
      <div>
        <div className="reviewSlide">
          <div className="item-content-wrapper">
            <div className="item-content">{cardData?.review_content}</div>
          </div>
          <div className="profile-img-wrapper">
            <img
              alt="reviewImage"
              className="rt-responsive-img"
              src={cardData?.thumbnail?.url}
            />
          </div>
          <h3 className="author-name">{cardData?.review_title}</h3>
        </div>
      </div>
    </>
  );
};
