import React from "react";
import { useSelector } from "react-redux";
import { expertises, languages } from "../../Global";
import {
  DropdownList,
  InputField,
  MultiSelectDropdown,
} from "../../Components";

const PersonalDetail = ({
  talent,
  language,
  setLanguage,
  englishLevel,
  setEnglishLevel,
  bioDetails,
  setBioDetails,
  height,
  setHeight,
  weight,
  setWeight,
  hairColor,
  setHairColor,
  ethnicity,
  setEthnicity,
  eyeColor,
  setEyeColor,
  experience,
  setExperience,
  mySkill,
  setMySkill,
  customSkills,
  setCustomSkills,
  tattooLoc,
  setTattoLoc,
  memberOf,
  setMemberOf,
  educationTraining,
  setEducationTraining,
  theaterExp,
  setTheaterExp,
  awards,
  setAwards,
  commercialExp,
  setCommercialExp,
  basicDetails,
  shirtSize,
  setShirtSize,
  shirtSizeC,
  setShirtSizeC,
  shirtSizeF,
  setShirtSizeF,
  setBraCup,
  braCup,
  setBraSize,
  braSize,
  pantSize,
  pantSizeL,
  pantSizeW,
  pantSizeWC,
  setPantSize,
  setPantSizeL,
  setPantSizeW,
  setPantSizeWC,
  shoeSize,
  shoeSizeC,
  setShoeSize,
  setShoeSizeC,
  jacket,
  setJacket,
  neckSize,
  setNeckSize,
  shirtSleeve,
  setShirtSleeve,
  dressShirt,
  setDressShirt,
  dressSize,
  setDressSize,
  dressSizeC,
  setDressSizeC,
  setBasicDetails,
  error,
  chestSize,
  setChestSize,
  gender,
  expertise,
  setExpertise,
  setShoeSizeF,
  shoeSizeF
}) => {
  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  return (
    <>
      {talent != "Client" &&
        talent != "Photographer" &&
        talent != "Partners" ? (
        <>
          <div class="form-section">
            <div className="form-section-title">Languages you can speak</div>
            <div class="form-group form-group-50">
              <MultiSelectDropdown
                required
                title="Select Languages"
                // options={optionsType?.languages}
                options={languages}
                value={language}
                setValue={setLanguage}
                isValid={error && language?.length == 0}
              />
              <DropdownList
                required
                title="Language Level"
                placeholder="Select Language Level"
                options={optionsType?.englishLevel}
                value={englishLevel}
                setValue={setEnglishLevel}
                isValid={error && !englishLevel}
              />
            </div>
          </div>

          <div class="form-section">
            <div className="form-section-title">
              {talent == "Client" || talent == "Partners"
                ? "Company Info"
                : talent == "Photographer"
                  ? "Photographer Details"
                  : talent == "Model Kid"
                    ? "Model Kid Details"
                    : talent == "Actor"
                      ? "Actor Details"
                      : talent === "Actor Kid"
                        ? "Actor Kid Details"
                        : "Model Details"}
            </div>
            <div class="form-group form-group-50">
              {talent === "Actor" || talent === "Actor Kid" ? undefined : (
                <InputField
                  required
                  title="Professional Bio"
                  type="textarea"
                  placeholder="Professional Bio"
                  value={bioDetails?.professionalBio}
                  onChangeText={(e) => {
                    setBioDetails({
                      ...bioDetails,
                      professionalBio: e?.target?.value,
                    });
                  }}
                  isValid={error && !bioDetails?.professionalBio}
                />
              )}
              {talent === "Actor" || talent === "Actor Kid" ? undefined : (
                <InputField
                  required
                  title="Personal bio"
                  type="textarea"
                  placeholder="Personal bio"
                  value={bioDetails?.personalBio}
                  onChangeText={(e) => {
                    setBioDetails({
                      ...bioDetails,
                      personalBio: e?.target?.value,
                    });
                  }}
                  isValid={error && !bioDetails?.personalBio}
                />
              )}

              <DropdownList
                required
                title="Height (Inches)"
                placeholder="Select Height"
                options={optionsType?.otherOptions?.height?.field_meta?.choices}
                value={height}
                setValue={setHeight}
                isValid={error && !height}
              />
              <DropdownList
                required
                title="Weight (Pounds)"
                placeholder="Select Weight"
                options={optionsType?.otherOptions?.weight?.field_meta?.choices}
                value={weight}
                setValue={setWeight}
                isValid={error && !weight}
              />

              <MultiSelectDropdown
                required
                title="Select Ethnicity"
                placeholder="Select Ethnicity"
                options={
                  optionsType?.otherOptions?.ethnicity?.field_meta?.choices
                }
                value={ethnicity}
                setValue={setEthnicity}
                isValid={error && ethnicity?.length == 0}
              />
              <DropdownList
                required
                title="Hair color"
                placeholder="Select Hair color"
                options={
                  optionsType?.otherOptions?.hair_colour?.field_meta?.choices
                }
                value={hairColor}
                setValue={setHairColor}
                isValid={error && !hairColor}
              />
              <DropdownList
                required
                title="Eye color"
                placeholder="Select Eye color"
                options={
                  optionsType?.otherOptions?.eye_color?.field_meta?.choices
                }
                value={eyeColor}
                setValue={setEyeColor}
                isValid={error && !eyeColor}
              />
              <DropdownList
                required
                title="Experience level"
                placeholder="Select Experience level"
                options={
                  optionsType?.otherOptions?.experience_level?.field_meta
                    ?.choices
                }
                value={experience}
                setValue={setExperience}
                isValid={error && !experience}
              />
              <MultiSelectDropdown
                // required
                title="My Skills"
                placeholder="Select Skills"
                options={
                  optionsType?.otherOptions?.add_new_skill?.field_meta?.choices
                }
                value={mySkill}
                setValue={setMySkill}
                // isValid={error && mySkill?.length == 0}
              />
              {talent === "Actor" || talent === "Actor Kid" ? undefined : (
                <InputField
                  // required
                  title="Custom Skills"
                  type="text"
                  placeholder="Add Custom Skills. eg. Baseball, Football..."
                  value={customSkills}
                  onChangeText={(e) => {
                    setCustomSkills(e?.target?.value);
                  }}
                />
              )}
              {talent === "Actor" ? (
                <>
                  <InputField
                    // required
                    title="Tattoo/Design/Location"
                    type="text"
                    placeholder="Add Tattoo/Design/Location."
                    value={tattooLoc}
                    onChangeText={(e) => {
                      setTattoLoc(e?.target?.value);
                    }}
                  />
                </>
              ) : undefined}

              {talent === "Actor" || talent === "Actor Kid" ? (
                <>
                  <InputField
                    // required
                    title="Member of any union"
                    type="text"
                    placeholder="Add Member of any union eg. SAG/AFTRA"
                    value={memberOf}
                    onChangeText={(e) => {
                      setMemberOf(e?.target?.value);
                    }}
                  />
                </>
              ) : undefined}
            </div>
          </div>

          {talent === "Actor" || talent === "Actor Kid" ? (
            <div class="form-section">
              <div className="form-section-title">
                {talent == "Actor" || talent === "Actor Kid"
                  ? "Experience"
                  : ""}
              </div>
              {talent == "Actor" || talent === "Actor Kid" ? (
                <div class="form-group form-group-50">
                  <InputField
                    required
                    title="Professional Bio"
                    type="textarea"
                    placeholder="Professional Bio"
                    value={bioDetails?.professionalBio}
                    onChangeText={(e) => {
                      setBioDetails({
                        ...bioDetails,
                        professionalBio: e?.target?.value,
                      });
                    }}
                    isValid={error && !bioDetails?.professionalBio}
                  />
                  <InputField
                    required
                    title="Personal bio"
                    type="textarea"
                    placeholder="Personal bio"
                    value={bioDetails?.personalBio}
                    onChangeText={(e) => {
                      setBioDetails({
                        ...bioDetails,
                        personalBio: e?.target?.value,
                      });
                    }}
                    isValid={error && !bioDetails?.personalBio}
                  />

                  <InputField
                    title="Education or Training"
                    type="textarea"
                    placeholder="Education or Training"
                    value={educationTraining}
                    onChangeText={(e) => {
                      setEducationTraining(e?.target?.value);
                    }}
                  />

                  <InputField
                    title="Theater experience"
                    type="textarea"
                    placeholder="Theater experience"
                    value={theaterExp}
                    onChangeText={(e) => {
                      setTheaterExp(e?.target?.value);
                    }}
                  />

                  <InputField
                    title="Awards"
                    type="textarea"
                    placeholder="Awards"
                    value={awards}
                    onChangeText={(e) => {
                      setAwards(e?.target?.value);
                    }}
                  />

                  <InputField
                    title="Commercial experience"
                    type="textarea"
                    placeholder="Commercial experience"
                    value={commercialExp}
                    onChangeText={(e) => {
                      setCommercialExp(e?.target?.value);
                    }}
                  />
                  <InputField
                    // require  d
                    title="Custom Skills"
                    type="textarea"
                    placeholder="Add Custom Skills. eg. Baseball, Football..."
                    value={customSkills}
                    onChangeText={(e) => {
                      setCustomSkills(e?.target?.value);
                    }}
                  />
                </div>
              ) : undefined}
            </div>
          ) : undefined}

          {(talent == "Actor Kid" || talent == "Actor") && gender ? (
            <div class="form-section">
              <div className="form-section-title">
                {talent == "Actor Kid" &&
                  (basicDetails?.age <= 2 ||
                    basicDetails?.age?.includes("M") ||
                    basicDetails?.age?.includes("Month"))
                  ? "Measurements"
                  : talent == "Actor Kid" &&
                    basicDetails?.age > 2 &&
                    basicDetails?.age <= 5
                    ? "Measurements"
                    : talent == "Actor Kid" &&
                      basicDetails?.age > 5 &&
                      basicDetails?.age <= 13
                      ? "Measurements"
                      : talent == "Actor"
                        ? gender == "Male" || gender == "male"
                          ? "Male Actor Measurement"
                          : gender == "Female" || gender == "female"
                            ? "Female Actor Measurement"
                            : "Actor Measurement For Non Binary"
                        : "Actor Measurement"}
              </div>
              <div class="form-group form-group-50">
                {talent == "Actor Kid" &&
                  (basicDetails?.age <= 13 ||
                    basicDetails?.age?.includes("M") ||
                    basicDetails?.age?.includes("Month")) ? (
                  <>
                    <MultiSelectDropdown
                      required
                      options={
                        optionsType?.childOptions?.toddler_shirt_size
                          ?.field_meta?.choices
                      }
                      title="Shirt size"
                      placeholder="Select Shirt size"
                      value={shirtSizeC}
                      setValue={setShirtSizeC}
                      isValid={error && shirtSizeC?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title="Pant Size"
                      placeholder="Select Pant Size"
                      value={pantSizeWC}
                      setValue={setPantSizeWC}
                      options={
                        optionsType?.childOptions?.toddler_pant_size?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSizeWC?.length == 0}
                    />

                    <MultiSelectDropdown
                      required
                      title="Shoe Size"
                      placeholder="Select Shoe Size"
                      value={shoeSizeC}
                      setValue={setShoeSizeC}
                      options={
                        optionsType?.childOptions?.toddler_shoe_size?.field_meta
                          ?.choices
                      }
                      isValid={error && shoeSizeC?.length == 0}
                    />
                    {gender == "Female" || gender == "female" ? (
                      <MultiSelectDropdown
                        required
                        title={"Dress Size"}
                        placeholder={"Select Dress Size"}
                        value={dressSizeC}
                        setValue={setDressSizeC}
                        options={
                          optionsType?.childOptions?.toddler_dress_size
                            ?.field_meta?.choices
                        }
                        isValid={error && dressSizeC?.length == 0}
                      />
                    ) : null}
                  </>
                ) : gender == "Male" || gender == "male" ? (
                  <>
                    <MultiSelectDropdown
                      required
                      title={"Shirt Size"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSize}
                      setValue={setShirtSize}
                      options={
                        optionsType?.maleOptions?.shirt_size?.field_meta
                          ?.choices
                      }
                      isValid={error && shirtSize?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title={"Pant Size (Waist)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeW}
                      setValue={setPantSizeW}
                      options={
                        optionsType?.maleOptions?.pant_size_waist?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSizeW?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title={"Pant Size (Length)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeL}
                      setValue={setPantSizeL}
                      options={
                        optionsType?.maleOptions?.pant_size_length?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSizeL?.length == 0}
                    />

                    <MultiSelectDropdown
                      required
                      title={"Shoe Size"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSize}
                      setValue={setShoeSize}
                      options={
                        optionsType?.maleOptions?.shoe_size?.field_meta?.choices
                      }
                      isValid={error && shoeSize?.length == 0}
                    />

                    <DropdownList
                      required
                      title="Dress Shirt Size"
                      placeholder="Select Dress Shirt Size"
                      value={dressShirt}
                      setValue={setDressShirt}
                      options={
                        optionsType?.maleOptions?.dress_shirt_size?.field_meta
                          ?.choices
                      }
                      isValid={error && !dressShirt}
                    />

                    <DropdownList
                      required
                      title="Dress Shirt Sleeve"
                      placeholder="Select Dress Shirt Sleeve"
                      value={shirtSleeve}
                      setValue={setShirtSleeve}
                      options={
                        optionsType?.maleOptions?.dress_shirt_sleeve?.field_meta
                          ?.choices
                      }
                      isValid={error && !shirtSleeve}
                    />

                    <DropdownList
                      required
                      title="Neck Size"
                      placeholder="Select Neck Size"
                      value={neckSize}
                      setValue={setNeckSize}
                      options={
                        optionsType?.maleOptions?.neck_size?.field_meta?.choices
                      }
                      isValid={error && !neckSize}
                    />

                    <DropdownList
                      required
                      title="Chest Size"
                      placeholder="Select Chest Size"
                      value={chestSize}
                      setValue={setChestSize}
                      options={
                        optionsType?.maleOptions?.chest_size?.field_meta
                          ?.choices
                      }
                      isValid={error && !chestSize}
                    />
                    <DropdownList
                      required
                      title="Jacket"
                      placeholder="Select Jacket"
                      value={jacket}
                      setValue={setJacket}
                      options={
                        optionsType?.maleOptions?.jacket?.field_meta?.choices
                      }
                      isValid={error && !jacket}
                    />
                  </>
                ) : gender == "Female" || gender == "female" ? (
                  <>
                    <MultiSelectDropdown
                      required
                      title={"Shirt Size"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSizeF}
                      setValue={setShirtSizeF}
                      options={
                        optionsType?.femaleOptions?.shirt_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && shirtSizeF?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title={"Pant Size"}
                      placeholder={"Select Pant Size"}
                      value={pantSize}
                      setValue={setPantSize}
                      options={
                        optionsType?.femaleOptions?.pant_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSize?.length == 0}
                    />
                    <div className="fullCol">
                      <span>
                      Bra size and Bra cup will only be visible to clients.
                      </span>
                    </div>
                    <DropdownList
                      required
                      title="Bra Cup"
                      placeholder="Select Bra Cup"
                      value={braCup}
                      setValue={setBraCup}
                      options={
                        optionsType?.femaleOptions?.bra_cup?.field_meta?.choices
                      }
                      isValid={error && !braCup}
                    />
                    <DropdownList
                      required
                      title="Bra Size"
                      placeholder="Select Bra Size"
                      value={braSize}
                      setValue={setBraSize}
                      options={
                        optionsType?.femaleOptions?.bra_size?.field_meta
                          ?.choices
                      }
                      isValid={error && !braSize}
                    />
                    
                    <DropdownList
                      required
                      title="Dress Size"
                      placeholder="Select Dress Size"
                      value={dressSize}
                      setValue={setDressSize}
                      options={
                        optionsType?.femaleOptions?.dress_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && !dressSize}
                    />

                    <MultiSelectDropdown
                      required
                      title={"Shoe Size"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSizeF}
                      setValue={setShoeSizeF}
                      options={
                        optionsType?.femaleOptions?.shoe_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && shoeSizeF?.length == 0}
                    />
                  </>
                ) : (
                  <>
                    <MultiSelectDropdown
                      title={"Shirt Size (M)"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSize}
                      setValue={setShirtSize}
                      options={
                        optionsType?.maleOptions?.shirt_size?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Pant Size (Waist)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeW}
                      setValue={setPantSizeW}
                      options={
                        optionsType?.maleOptions?.pant_size_waist?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Pant Size (Length)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeL}
                      setValue={setPantSizeL}
                      options={
                        optionsType?.maleOptions?.pant_size_length?.field_meta
                          ?.choices
                      }
                    />

                    <MultiSelectDropdown
                      title={"Shoe Size (M)"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSize}
                      setValue={setShoeSize}
                      options={
                        optionsType?.maleOptions?.shoe_size?.field_meta?.choices
                      }
                    />

                    <DropdownList
                      title="Dress Shirt Size (M)"
                      placeholder="Select Dress Shirt Size"
                      value={dressShirt}
                      setValue={setDressShirt}
                      options={
                        optionsType?.maleOptions?.dress_shirt_size?.field_meta
                          ?.choices
                      }
                    />

                    <DropdownList
                      title="Dress Shirt Sleeve (M)"
                      placeholder="Select Dress Shirt Sleeve"
                      value={shirtSleeve}
                      setValue={setShirtSleeve}
                      options={
                        optionsType?.maleOptions?.dress_shirt_sleeve?.field_meta
                          ?.choices
                      }
                    />

                    <DropdownList
                      title="Neck Size (M)"
                      placeholder="Select Neck Size"
                      value={neckSize}
                      setValue={setNeckSize}
                      options={
                        optionsType?.maleOptions?.neck_size?.field_meta?.choices
                      }
                    />

                    <DropdownList
                      title="Chest Size (M)"
                      placeholder="Select Chest Size"
                      value={chestSize}
                      setValue={setChestSize}
                      options={
                        optionsType?.maleOptions?.chest_size?.field_meta
                          ?.choices
                      }
                    />

                    <DropdownList
                      title="Jacket (M)"
                      placeholder="Select Jacket"
                      value={jacket}
                      setValue={setJacket}
                      options={
                        optionsType?.maleOptions?.jacket?.field_meta?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Shirt Size (F)"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSizeF}
                      setValue={setShirtSizeF}
                      options={
                        optionsType?.femaleOptions?.shirt_size_f?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Pant Size (F)"}
                      placeholder={"Select Pant Size"}
                      value={pantSize}
                      setValue={setPantSize}
                      options={
                        optionsType?.femaleOptions?.pant_size_f?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Shoe Size (F)"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSizeF}
                      setValue={setShoeSizeF}
                      options={
                        optionsType?.femaleOptions?.shoe_size_f?.field_meta
                          ?.choices
                      }
                    />
                    <div className="fullCol">
                      <span>
                      Bra size and Bra cup will only be visible to clients.
                      </span>
                    </div>
                    <DropdownList
                      title="Bra Cup (F)"
                      placeholder="Select Bra Cup"
                      value={braCup}
                      setValue={setBraCup}
                      options={
                        optionsType?.femaleOptions?.bra_cup?.field_meta?.choices
                      }
                    />

                    <DropdownList
                      title="Bra Size (F)"
                      placeholder="Select Bra Size"
                      value={braSize}
                      setValue={setBraSize}
                      options={
                        optionsType?.femaleOptions?.bra_size?.field_meta
                          ?.choices
                      }
                    />
                    
                    <DropdownList
                      title="Dress Size (F)"
                      placeholder="Select Dress Size"
                      value={dressSize}
                      setValue={setDressSize}
                      options={
                        optionsType?.femaleOptions?.dress_size_f?.field_meta
                          ?.choices
                      }
                    />

                  </>
                )}
              </div>
            </div>
          ) : null}

          {(talent == "Model" && gender) ||
            (talent == "Model Kid" && gender) ? (
            <div class="form-section">
              <div className="form-section-title">
                {talent == "Model Kid" &&
                  (basicDetails?.age <= 2 ||
                    basicDetails?.age?.includes("M") ||
                    basicDetails?.age?.includes("Month"))
                  ? "Measurements"
                  : talent == "Model Kid" &&
                    basicDetails?.age > 2 &&
                    basicDetails?.age <= 5
                    ? "Measurements"
                    : talent == "Model Kid" &&
                      basicDetails?.age > 5 &&
                      basicDetails?.age <= 13
                      ? "Measurements"
                      : talent == "Model"
                        ? gender == "Male" || gender == "male"
                          ? "Male Model Measurement"
                          : gender == "Female" || gender == "female"
                            ? "Female Model Measurement"
                            : "Model Measurement For Non Binary"
                        : "Model Measurement"}
              </div>
              <div class="form-group form-group-50">
                {talent == "Model Kid" || talent == "child" ? (
                  <>
                    <MultiSelectDropdown
                      required
                      options={
                        optionsType?.childOptions?.toddler_shirt_size
                          ?.field_meta?.choices
                      }
                      title="Shirt size"
                      placeholder="Select Shirt size"
                      value={shirtSizeC}
                      setValue={setShirtSizeC}
                      isValid={error && shirtSizeC?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title="Pant Size"
                      placeholder="Select Pant Size"
                      value={pantSizeWC}
                      setValue={setPantSizeWC}
                      options={
                        optionsType?.childOptions?.toddler_pant_size?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSizeWC?.length == 0}
                    />

                    <MultiSelectDropdown
                      required
                      title="Shoe Size"
                      placeholder="Select Shoe Size"
                      value={shoeSizeC}
                      setValue={setShoeSizeC}
                      options={
                        optionsType?.childOptions?.toddler_shoe_size?.field_meta
                          ?.choices
                      }
                      isValid={error && shoeSizeC?.length == 0}
                    />
                    {gender == "Female" || gender == "female" ? (
                      <MultiSelectDropdown
                        required
                        title={"Dress Size"}
                        placeholder={"Select Dress Size"}
                        value={dressSizeC}
                        setValue={setDressSizeC}
                        options={
                          optionsType?.childOptions?.toddler_dress_size
                            ?.field_meta?.choices
                        }
                        isValid={error && dressSizeC?.length == 0}
                      />
                    ) : null}
                  </>
                ) : gender == "Male" || gender == "male" ? (
                  <>
                    <MultiSelectDropdown
                      required
                      title={"Shirt Size"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSize}
                      setValue={setShirtSize}
                      options={
                        optionsType?.maleOptions?.shirt_size?.field_meta
                          ?.choices
                      }
                      isValid={error && shirtSize?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title={"Pant Size (Waist)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeW}
                      setValue={setPantSizeW}
                      options={
                        optionsType?.maleOptions?.pant_size_waist?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSizeW?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title={"Pant Size (Length)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeL}
                      setValue={setPantSizeL}
                      options={
                        optionsType?.maleOptions?.pant_size_length?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSizeL?.length == 0}
                    />

                    <MultiSelectDropdown
                      required
                      title={"Shoe Size"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSize}
                      setValue={setShoeSize}
                      options={
                        optionsType?.maleOptions?.shoe_size?.field_meta?.choices
                      }
                      isValid={error && shoeSize?.length == 0}
                    />

                    <DropdownList
                      required
                      title="Dress Shirt Size"
                      placeholder="Select Dress Shirt Size"
                      value={dressShirt}
                      setValue={setDressShirt}
                      options={
                        optionsType?.maleOptions?.dress_shirt_size?.field_meta
                          ?.choices
                      }
                      isValid={error && !dressShirt}
                    />

                    <DropdownList
                      required
                      title="Dress Shirt Sleeve"
                      placeholder="Select Dress Shirt Sleeve"
                      value={shirtSleeve}
                      setValue={setShirtSleeve}
                      options={
                        optionsType?.maleOptions?.dress_shirt_sleeve?.field_meta
                          ?.choices
                      }
                      isValid={error && !shirtSleeve}
                    />

                    <DropdownList
                      required
                      title="Neck Size"
                      placeholder="Select Neck Size"
                      value={neckSize}
                      setValue={setNeckSize}
                      options={
                        optionsType?.maleOptions?.neck_size?.field_meta?.choices
                      }
                      isValid={error && !neckSize}
                    />

                    <DropdownList
                      required
                      title="Chest Size"
                      placeholder="Select Chest Size"
                      value={chestSize}
                      setValue={setChestSize}
                      options={
                        optionsType?.maleOptions?.chest_size?.field_meta
                          ?.choices
                      }
                      isValid={error && !chestSize}
                    />
                    <DropdownList
                      required
                      title="Jacket"
                      placeholder="Select Jacket"
                      value={jacket}
                      setValue={setJacket}
                      options={
                        optionsType?.maleOptions?.jacket?.field_meta?.choices
                      }
                      isValid={error && !jacket}
                    />
                  </>
                ) : gender == "Female" || gender == "female" ? (
                  <>
                    <MultiSelectDropdown
                      required
                      title={"Shirt Size"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSizeF}
                      setValue={setShirtSizeF}
                      options={
                        optionsType?.femaleOptions?.shirt_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && shirtSizeF?.length == 0}
                    />
                    <MultiSelectDropdown
                      required
                      title={"Pant Size"}
                      placeholder={"Select Pant Size"}
                      value={pantSize}
                      setValue={setPantSize}
                      options={
                        optionsType?.femaleOptions?.pant_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && pantSize?.length == 0}
                    />
                    <div className="fullCol">
                      <span>
                      Bra size and Bra cup will only be visible to clients.
                      </span>
                    </div>
                    <DropdownList
                      required
                      title="Bra Cup"
                      placeholder="Select Bra Cup"
                      value={braCup}
                      setValue={setBraCup}
                      options={
                        optionsType?.femaleOptions?.bra_cup?.field_meta?.choices
                      }
                      isValid={error && !braCup}
                    />
                    <DropdownList
                      required
                      title="Bra Size"
                      placeholder="Select Bra Size"
                      value={braSize}
                      setValue={setBraSize}
                      options={
                        optionsType?.femaleOptions?.bra_size?.field_meta
                          ?.choices
                      }
                      isValid={error && !braSize}
                    />
                    <DropdownList
                      required
                      title="Dress Size"
                      placeholder="Select Dress Size"
                      value={dressSize}
                      setValue={setDressSize}
                      options={
                        optionsType?.femaleOptions?.dress_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && !dressSize}
                    />

                    <MultiSelectDropdown
                      required
                      title={"Shoe Size"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSizeF}
                      setValue={setShoeSizeF}
                      options={
                        optionsType?.femaleOptions?.shoe_size_f?.field_meta
                          ?.choices
                      }
                      isValid={error && shoeSize?.length == 0}
                    />
                  </>
                ) : (
                  <>
                    <MultiSelectDropdown
                      title={"Shirt Size (M)"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSize}
                      setValue={setShirtSize}
                      options={
                        optionsType?.maleOptions?.shirt_size?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Pant Size (Waist)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeW}
                      setValue={setPantSizeW}
                      options={
                        optionsType?.maleOptions?.pant_size_waist?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Pant Size (Length)"}
                      placeholder={"Select Pant Size"}
                      value={pantSizeL}
                      setValue={setPantSizeL}
                      options={
                        optionsType?.maleOptions?.pant_size_length?.field_meta
                          ?.choices
                      }
                    />

                    <MultiSelectDropdown
                      title={"Shoe Size (M)"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSize}
                      setValue={setShoeSize}
                      options={
                        optionsType?.maleOptions?.shoe_size?.field_meta?.choices
                      }
                    />

                    <DropdownList
                      title="Dress Shirt Size (M)"
                      placeholder="Select Dress Shirt Size"
                      value={dressShirt}
                      setValue={setDressShirt}
                      options={
                        optionsType?.maleOptions?.dress_shirt_size?.field_meta
                          ?.choices
                      }
                    />

                    <DropdownList
                      title="Dress Shirt Sleeve (M)"
                      placeholder="Select Dress Shirt Sleeve"
                      value={shirtSleeve}
                      setValue={setShirtSleeve}
                      options={
                        optionsType?.maleOptions?.dress_shirt_sleeve?.field_meta
                          ?.choices
                      }
                    />

                    <DropdownList
                      title="Neck Size (M)"
                      placeholder="Select Neck Size"
                      value={neckSize}
                      setValue={setNeckSize}
                      options={
                        optionsType?.maleOptions?.neck_size?.field_meta?.choices
                      }
                    />

                    <DropdownList
                      title="Chest Size (M)"
                      placeholder="Select Chest Size"
                      value={chestSize}
                      setValue={setChestSize}
                      options={
                        optionsType?.maleOptions?.chest_size?.field_meta
                          ?.choices
                      }
                    />

                    <DropdownList
                      title="Jacket (M)"
                      placeholder="Select Jacket"
                      value={jacket}
                      setValue={setJacket}
                      options={
                        optionsType?.maleOptions?.jacket?.field_meta?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Shirt Size (F)"}
                      placeholder={"Select Shirt Size"}
                      value={shirtSizeF}
                      setValue={setShirtSizeF}
                      options={
                        optionsType?.femaleOptions?.shirt_size_f?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Pant Size (F)"}
                      placeholder={"Select Pant Size"}
                      value={pantSize}
                      setValue={setPantSize}
                      options={
                        optionsType?.femaleOptions?.pant_size_f?.field_meta
                          ?.choices
                      }
                    />
                    <MultiSelectDropdown
                      title={"Shoe Size (F)"}
                      placeholder={"Select Shoe Size"}
                      value={shoeSizeF}
                      setValue={setShoeSizeF}
                      options={
                        optionsType?.femaleOptions?.shoe_size_f?.field_meta
                          ?.choices
                      }
                    />
                    <div className="fullCol">
                      <span>
                      Bra size and Bra cup will only be visible to clients.
                      </span>
                    </div>
                    <DropdownList
                      title="Bra Cup (F)"
                      placeholder="Select Bra Cup"
                      value={braCup}
                      setValue={setBraCup}
                      options={
                        optionsType?.femaleOptions?.bra_cup?.field_meta?.choices
                      }
                    />

                    <DropdownList
                      title="Bra Size (F)"
                      placeholder="Select Bra Size"
                      value={braSize}
                      setValue={setBraSize}
                      options={
                        optionsType?.femaleOptions?.bra_size?.field_meta
                          ?.choices
                      }
                    />
                    <DropdownList
                      title="Dress Size (F)"
                      placeholder="Select Dress Size"
                      value={dressSize}
                      setValue={setDressSize}
                      options={
                        optionsType?.femaleOptions?.dress_size_f?.field_meta
                          ?.choices
                      }
                    />

                  </>
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : talent == "Photographer" ? (
        <>
          <div class="form-section">
            <div className="form-section-title">Photographer Details</div>
            <div class="form-group form-group-50">
              <InputField
                required
                title="Professional Bio"
                type="textarea"
                placeholder="Professional Bio"
                value={bioDetails?.professionalBio}
                onChangeText={(e) => {
                  setBioDetails({
                    ...bioDetails,
                    professionalBio: e?.target?.value,
                  });
                }}
                isValid={error && !bioDetails?.professionalBio}
              />
              <InputField
                required
                title="Personal bio"
                type="textarea"
                placeholder="Personal bio"
                value={bioDetails?.personalBio}
                onChangeText={(e) => {
                  setBioDetails({
                    ...bioDetails,
                    personalBio: e?.target?.value,
                  });
                }}
                isValid={error && !bioDetails?.personalBio}
              />

              <MultiSelectDropdown
                required
                title="Select Expertise"
                options={expertises}
                value={expertise}
                setValue={setExpertise}
                isValid={error && !expertise?.length}
              />
              <InputField
                title="Custom Expertise"
                type="text"
                value={basicDetails?.customExpertise}
                placeholder="Eg. Baseball, Football etc"
                onChangeText={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    customExpertise: e?.target?.value,
                  });
                }}
              />
              <DropdownList
                required
                title="Experience level"
                placeholder="Select Experience level"
                options={
                  optionsType?.otherOptions?.experience_level?.field_meta
                    ?.choices
                }
                value={experience}
                setValue={setExperience}
                isValid={error && !experience}
              />
              <InputField
                required
                title="Years of experience"
                type="text"
                value={basicDetails?.yearsExperience}
                placeholder="Eg. 1, 2, 3 etc"
                onChangeText={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    yearsExperience: e?.target?.value,
                  });
                }}
                isValid={error && !basicDetails?.yearsExperience}
              />
            </div>
          </div>
          <div class="form-section">
            <div className="form-section-title">My Skills</div>
            <div class="form-group form-group-50">
              <MultiSelectDropdown
                // required
                title="My Skills"
                placeholder="Select Skills"
                options={
                  optionsType?.otherOptions?.add_new_skill?.field_meta?.choices
                }
                value={mySkill}
                setValue={setMySkill}
                // isValid={error && mySkill?.length == 0}
              />
              <InputField
                // required
                title="Custom Skills"
                type="text"
                placeholder="Add Custom Skills. eg. Baseball, Football..."
                value={customSkills}
                onChangeText={(e) => {
                  setCustomSkills(e?.target?.value);
                }}
                // isValid={error && !customSkills}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PersonalDetail;
