import React, { useEffect, useRef, useState } from "react";
import { Routing } from "./Routing";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/normalize.css";
import "./Assets/css/scrollbar.css";
import "./Assets/css/fontawesome/fontawesome-all.css";
import "./Assets/css/font-awesome.min.css";
import "./Assets/css/owl.carousel.min.css";
import "./Assets/css/linearicons.css";
import "./Assets/css/jquery-ui.css";
import "./Assets/css/tipso.css";
import "./Assets/css/chosen.css";
import "./Assets/css/main.css";
import "./Assets/css/dashboard.css";
import "./Assets/css/color.css";
import "./Assets/css/transitions.css";
import "./Assets/css/style.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Assets/css/responsive.css";
import { useDispatch } from "react-redux";
import { getOptionsData } from "./Redux/Services/AuthServices";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptionsData());
  }, []);

   console.log = () => {}
  
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
