import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveEventsApi } from "../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../Utility/Storage";
import { Loader } from "../../Components";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";

const localizer = momentLocalizer(moment);

const ManageAvailability = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [localEvents, setLocalEvents] = useState([]);
  const [view, setView] = useState("month");
  const [loading, setLoading] = useState(false);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    setLoading(true);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      setLoading(false);
      if (res?.status == 200) {
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
      }
    } else {
      setLoading(false);
    }
  };

  function formatDate(date) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`;
  }

  function formatEndDate(date) {
    var year = date.getUTCFullYear();
    var month = date.getUTCMonth() + 1;
    var day = date.getUTCDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`;
  }

  function formatTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}:00`;
  }

  const addEventsData = async (newEvent) => {
    setLoading(true);
    let userId = await getData(storageKey?.USER_ID);
    if (userId) {
      let body = {
        user_id: userId,
        action: "add_event",
        event_title: "Unavaliable",
        event_start: formatDate(new Date(newEvent?.start)),
        event_end: formatEndDate(new Date(newEvent?.end)),
        start_time: formatTime(new Date(newEvent?.start)),
        end_time: formatTime(new Date(newEvent?.end)),
      };

      let res = await dispatch(addRemoveEventsApi(body));
      setLoading(false);
      if (res?.status === 200) {
        getUserData();
      }
    } else {
    }
  };

  const removeEventsData = async (id) => {
    setLoading(true);
    let userId = await getData(storageKey?.USER_ID);
    if (userId) {
      let body = {
        user_id: userId,
        action: "remove_event",
        event_id: id,
      };
      let res = await dispatch(addRemoveEventsApi(body));
      setLoading(false);
      if (res?.status === 200) {
        getUserData();
      }
    } else {
    }
  };

  function isOverlapping(newStart, newEnd, events) {
    return events.some((event) => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      return newStart < eventEnd && newEnd > eventStart;
    });
  }

  const handleSelecting = ({ start, end }) => {
    // Check if the proposed selection overlaps with unavailable dates
    const selectingStart = new Date(start);
    const selectingEnd = new Date(end);

    if (isOverlapping(selectingStart, selectingEnd, localEvents)) {
        return false; // This will block the selection
    }
    return true;
};

  // const handleSelectSlot = (slotInfo) => {
  //   const { start, end } = slotInfo;
  //   const newStart = new Date(start);
  //   const newEnd = new Date(end);

  //   // Check if the selected time slot overlaps with existing events
  //   if (isOverlapping(newStart, newEnd, localEvents)) {
  //     alert(
  //       "This time slot overlaps with existing events and cannot be selected."
  //     );
  //     return; // Prevent the event creation
  //   }

  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to select this time slot?"
  //   );
  //   if (isConfirmed) {
  //     const newEvent = {
  //       title: "Unavailable",
  //       start: newStart,
  //       end: newEnd,
  //       allDay:
  //         slotInfo.slots.length === 1 && slotInfo.action === "doubleClick",
  //     };
  //     addEventsData(newEvent);
  //     // Optionally update local state if you manage a local copy of events
  //     // setLocalEvents(prevEvents => [...prevEvents, newEvent]);
  //   }
  // };

  const handleSelectSlot = (slotInfo) => {
    // console.log(slotInfo,"klklklkl");
    const { start, end } = slotInfo;
    const isConfirmed = window.confirm(
      "Are you sure you want these dates unavailable?"
    );
    if (isConfirmed) {
      const newEvent = {
        title: "Unavailable",
        start,
        end,
        allDay:
          slotInfo.slots.length === 1 && slotInfo.action === "doubleClick",
      };
      console.log(newEvent);
      addEventsData(newEvent);
    //   setLocalEvents([...localEvents, newEvent]);
    }
  };

  const handleSelectEvent = (event) => {
   
    const isConfirmed = window.confirm(
      "Are you sure you want these dates available?"
    );
    if (isConfirmed) {
      const remainingEvents = localEvents.filter(
        (e) => e.start !== event.start || e.end !== event.end
      );
      removeEventsData(event?.event_id);
      //   setLocalEvents(remainingEvents);
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    if (loggedInUser?.event_details?.length) {
      const eventsArr = loggedInUser?.event_details?.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        allDay: event.allDay,
        allday: event.allDay,
      }));
      setLocalEvents(eventsArr);
    }
  }, [loggedInUser]);

  console.log(localEvents);


  const dayPropGetter = date => {
    const eventForDate = localEvents.find(event => moment(event.start).isSame(date, 'minute'));

    if (eventForDate) {
      return {
        className: 'event-date'
      };
    }

    return {};
  };

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className="wt-dashboardbox">
            <div className="wt-dashboardboxtitle wt-titlewithsearch">
              <h2>Manage Availability</h2>
              <Calendar
                localizer={localizer}
                events={localEvents}
                selectable={true}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                views={["month", "week", "day"]}
                defaultView="month"
                style={{ height: "100%" }}
                startAccessor="start"
                endAccessor="end"
                view={view}
                onView={handleViewChange}
                dayPropGetter={dayPropGetter}
              />
            </div>
          </div>
        </div>
      </main>
    </SidebarLayout>
  );
};

export default ManageAvailability;
