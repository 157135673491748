import * as url from "../../API Services/Url";
import * as Services from "../../API Services/services";
import {
  clearData,
  getData,
  storageKey,
  storeData,
} from "../../Utility/Storage";
import { routeName, showToast } from "../../Utility";
import {
  addPartnerListError,
  addPartnerListLoading,
  addPartnerListSuccess,
  checkEmailStatusError,
  checkEmailStatusLoading,
  checkEmailStatusSuccess,
  deletePartnerErrorLoading,
  deletePartnerListLoading,
  deletePartnerListSuccess,
  getPartnerListError,
  getPartnerListLoading,
  getPartnerListSuccess,
  sendMailSinglePartnerError,
  sendMailSinglePartnerLoading,
  sendMailSinglePartnerSuccess,
  viewPartnerProjectError,
  viewPartnerProjectLoading,
  viewPartnerProjectSuccess,
} from "../Actions/PartnerActions";

export const addPartnerListApi = (body) => async (dispatch) => {
  dispatch(addPartnerListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_PARTNER_DATA + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    console.log(response,"responseresponse");
    if (response?.status == 200) {
      dispatch(addPartnerListSuccess, undefined);
    } else {
      dispatch(addPartnerListError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addPartnerListError(false));
    return { message: error };
  }
};

export const getPartnerListApi = (body) => async (dispatch) => {
  dispatch(getPartnerListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.GET_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
    } else {
      dispatch(getPartnerListSuccess, undefined);
    }
    return response;
  } catch (error) {
    dispatch(getPartnerListError(false));
    return { message: error };
  }
};

export const deletePartnerFromListApi = (body) => async (dispatch) => {
  dispatch(deletePartnerListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.DELETE_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
    } else {
      dispatch(deletePartnerListSuccess, undefined);
    }
    return response;
  } catch (error) {
    dispatch(deletePartnerErrorLoading(false));
    return { message: error };
  }
};

export const projectPartnerListAPi = (body) => async (dispatch) => {
  dispatch(viewPartnerProjectLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.VIEW_PARTNER_LIST_PROJECT + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
    } else {
      dispatch(viewPartnerProjectSuccess, undefined);
    }
    return response;
  } catch (error) {
    dispatch(viewPartnerProjectError(false));
    return { message: error };
  }
};

export const sendSinglePartnerMailApi = (body) => async (dispatch) => {
  dispatch(sendMailSinglePartnerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.SEND_MAIL_SINGLE_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
    } else {
      dispatch(sendMailSinglePartnerSuccess, undefined);
    }
    return response;
  } catch (error) {
    dispatch(sendMailSinglePartnerError(false));
    return { message: error };
  }
};

export const checkEmailStatusApi = (body) => async (dispatch) => {
  dispatch(checkEmailStatusLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.CHECK_STATUS_EMAIL + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
    } else {
      dispatch(checkEmailStatusSuccess, undefined);
    }
    return response;
  } catch (error) {
    dispatch(checkEmailStatusError(false));
    return { message: error };
  }
};

export const addPartnerAccessApi = (body) => async (dispatch) => {
  dispatch(checkEmailStatusLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.AGENCY_ACCESS_SETTING + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
    } else {
      dispatch(checkEmailStatusSuccess, undefined);
    }
    return response;
  } catch (error) {
    dispatch(checkEmailStatusError(false));
    return { message: error };
  }
};