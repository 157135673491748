import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addExtraPayRequest } from "../../Redux/Services/OtherServices";
import { isNumber, isString } from "../../Utility/validations";
import { useDispatch } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";

export const RequestPayment = (props) => {
  const dispatch = useDispatch();
  const {
    setVisible,
    visible,
    selectedItem,
    getAllJobsList,
    setTab,
    setLoading,
    tab
  } = props;

  function getValidationSchema(values) {
    return Yup.object().shape({
      reason: Yup.string().required(
        values.type === "extra"
          ? "Reason is required"
          : "Description is Required"
      ),
      amount:
        values.type !== "extra"
          ? Yup.string()
          : Yup.string().required("Amount is required"),
    });
  }

  const onSubmit = async (values) => {
    let userID = await getData(storageKey?.USER_ID);
    try {
      setLoading(true);
      let body = {
        action:
          values?.type === "extra" ? "extra_pay" : "project_complete_request",
        user_id: userID ? JSON.parse(userID) : "",
        project_id: selectedItem?.profile?.ID,
        proposal_id: selectedItem?.post_meta_details?.proposal_id,

        description: values.reason,
      };
      if (values.type === "extra") {
        body.amount = values.amount;
      }

      const res = await dispatch(addExtraPayRequest(body));
      setLoading(false);
      if (res?.status === 200) {
        getAllJobsList("ongoing");
        setTab("ongoing");
        formik.setFieldValue("amount", "");
        formik.setFieldValue("type", "extra");
        formik.setFieldValue("reason", "");
        setVisible(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      reason: "",
      type: "extra",
    },
    validationSchema: () => getValidationSchema(formik.values),
    onSubmit: onSubmit,
  });

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Request a Payment
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="form-group">
            <select
              name="type"
              id="type"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.type}
            >
              <option value={"extra"}>Extra payment request</option>
              {tab === "completed" ? undefined : (
                <option value={"complete"}>Project complete request</option>
              )}
            </select>
            {formik.errors.type && formik.touched.type && (
              <div className="error">{formik.errors.type}</div>
            )}
          </div>
          {formik.values.type === "extra" ? (
            <div className="form-group">
              <input
                type="number"
                name="amount"
                id="amount"
                class="form-control"
                placeholder="Add Amount"
                onChange={(val) => {
                  formik.handleChange(val);
                  // if (val.target.value === " " || val.target.value === ".") {
                  // } else if (isNumber(val.target.value)) {
                    
                  // }
                }}
                value={formik.values.amount}
              />
              {formik.errors.amount && formik.touched.amount && (
                <div className="error">{formik.errors.amount}</div>
              )}
            </div>
          ) : undefined}
          <div className="form-group">
            <textarea
              type="textarea"
              name="reason"
              id="reason"
              class="form-control"
              placeholder={
                formik.values.type === "extra"
                  ? "Add reason"
                  : "Add Description"
              }
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              value={formik.values.reason}
            />
            {formik.errors.reason && formik.touched.reason && (
              <div className="error">{formik.errors.reason}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <button
            type="submit"
            className="wt-btn wt-save-identity"
            // type="submit"
          >
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
