


import { Colors } from "../../Constants";

const SitePagination = (props) => {
  const {
    module,
    page,
    onPageChange,
    totalPages,
    setPage,
  } = props;
  return (
    <div className="wt-pagination">


      {module?.length ?
        <div
          className={page === 1 ? "actv_arrow inactv_arrow" : "actv_arrow"}
          onClick={() => {
            if (page === 1) {
            } else {
              onPageChange(page - 1);
            }
          }}
        >
          <i className="lnr lnr-chevron-left"></i>
        </div> : undefined}
      <div className="count">
        {totalPages > 6
          ? Array.from({ length: totalPages })
            .slice(0, 5)
            .map((_, index) => (
              <div
                className={page === index + 1 ? "actv" : "inActv"}
                key={index}

                onClick={() => {
                  if (page === index + 1) {
                  } else {
                    onPageChange(index + 1);
                  }
                }}
              >
                {index + 1}
              </div>
            ))
            .concat(
              <span
                key="ellipsis"
                className="ellipsis"
                style={{ color: "black" }}
              >
                ...
              </span>,
              <div
                className="actv"
                style={{
                  display:
                    page < 6 || page === totalPages ? "none" : undefined,
                }}
                key={totalPages}
              >
                {page}
              </div>,
              <div
                className={page === totalPages ? "actv" : "inActv"}
                key={totalPages}
                onClick={() => onPageChange(totalPages)}
              >
                {totalPages}
              </div>
            )
          : Array.from({ length: totalPages }).map((_, index) => (
            <div
              className={page === index + 1 ? "actv" : "inActv"}
              key={index}
              onClick={() => {
                if (page === index + 1) {
                } else {
                  onPageChange(index + 1);
                }
              }}
            >
              {index + 1}
            </div>
          ))}
      </div>

      {totalPages > 5 ? (
        <form fullWidth>
          <select
            className="select_pgntn"
            // style={{ width: 60 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            value={page}
            onChange={(pages) => onPageChange(pages.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            {Array.from({ length: totalPages }).map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </form>
      ) : null}

      {module?.length ? <div
        className={page === totalPages ? "actv_arrow inactv_arrow" : "actv_arrow"}
        onClick={() => {
          if (page === totalPages) {
          } else {
            onPageChange(Number(page) + 1);
          }
        }}
      >
        <a>
          <i className="lnr lnr-chevron-right"></i>
        </a>
      </div> : undefined}

    </div>
  );
};

export default SitePagination;