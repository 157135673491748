import React, { useEffect, useRef, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { Loader } from "../../Components";
import { ChatCompo, ChatList } from "../../features/chat";
import { getData, storageKey } from "../../Utility/Storage";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatList,
  getUserChat,
  sendMessage,
} from "../../Redux/Services/OtherServices";

const Chat = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatListing, setChatListing] = useState([]);
  const [selectedList, setSelectedList] = useState({});
  const [chatMsg, setChatMsg] = useState([]);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const getChatListData = async () => {
    setLoading(true);
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: partnerPermissions?.user_id || userID,
      };
      let res = await dispatch(getChatList(body));
      setLoading(false);
      if (res?.status === 200) {
        setChatListing(res?.results || []);
        if (res?.results?.length) {
          setSelectedList(res?.results?.[0]);
        }
      }
    } else {
      setLoading(false);
    }
  };



  useEffect(() => {
    getChatListData();
  }, []);


  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div>
        <div className="messages">
          {messages.map((message, index) => (
            <div
              key={index}
              className={
                message.sender === "user" ? "user-message" : "other-message"
              }
            >
              {message.text}
            </div>
          ))}
        </div>

        <section class=" chatArea">
          <div class="row">
            <div class="col-xs-12">
              <div class="wt-dashboardbox wt-messages-holder">
                <div class="wt-dashboardboxtitle">
                  <h2>Messages</h2>
                </div>
                <div class="wt-dashboardboxtitle wt-titlemessages">
                  <a href="javascript:void(0);" class="wt-back">
                    <i class="ti-arrow-left"></i>
                  </a>
                  <div class="wt-userlogedin">
                    <figure class="wt-userimg">
                      <img src="images/user-img.jpg" alt="image description" />
                    </figure>
                    <div class="wt-username">
                      <h3>
                        <i class="fa fa-check-circle"></i> Louanne Mattioli
                      </h3>
                      <span>Amento Tech</span>
                    </div>
                  </div>
                  <a href="javascript:void(0);" class="wt-viewprofile">
                    View Profile
                  </a>
                </div>
                <div class="wt-dashboardboxcontent wt-dashboardholder wt-offersmessages">
                  <ul>
                    <ChatList
                      chatListing={chatListing}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                      getChatListData={getChatListData}
                      setChatListing={setChatListing}
                    />
                    <ChatCompo
                      chatMsg={chatMsg}
                      selectedList={selectedList}
                      setChatMsg={setChatMsg}
                      setLoading={setLoading}
                      chatListing={chatListing}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </SidebarLayout>
  );
};

export default Chat;
