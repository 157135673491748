import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { Images } from "../../Constants";
import { useDispatch } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import {
  addStripeAccount,
  getPayoutsList,
  getStripeBalance,
  handleWithdraw,
} from "../../Redux/Services/OtherServices";
import { Loader } from "../../Components";
import { WithdrawlAmount } from "../../Components/Popups";
import { showToast } from "../../Utility";
import moment from "moment";

const PayoutSetting = () => {
  const dispatch = useDispatch();
  const [bankDetails, setBankDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [isWithDrawModal, setIsWithDrawModal] = useState(false);
  const [payDetail, setPayDetail] = useState([]);
  const [tab, setTab] = useState(1);
  const [balanceDetails, setBalanceDetails] = useState("");

  const handleAddAccount = async () => {
    try {
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        let body = {
          user_id: userID,
          action: "add_react",
        };
        const res = await dispatch(addStripeAccount(body));
        setLoading(false);
        if (res?.status === 200) {
          window.open(res?.results, "_blank");
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleEditAccount = async () => {
    try {
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        let body = {
          user_id: userID,
          action: "update_react",
        };
        const res = await dispatch(addStripeAccount(body));
        setLoading(false);
        if (res?.status === 200) {
          window.open(res?.results?.url, "_blank");
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getBankAccDetails = async () => {
    try {
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        let body = {
          user_id: userID,
          action: "get_react",
        };
        const res = await dispatch(addStripeAccount(body));
        setLoading(false);
        if (res?.status === 200) {
          setBankDetails(res?.results);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleWithdrawClick = async () => {
    try {
      if (!selectedAmount) {
        showToast("Add amount first", "error");
        return;
      }
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        let body = {
          user_id: userID,
          action: "withdraw_react",
          amount: selectedAmount,
        };
        const res = await dispatch(handleWithdraw(body));
        setLoading(false);
        if (res?.status === 200) {
          setIsWithDrawModal(false);
          getBankAccDetails();
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getPayoutHisDetail = async () => {
    try {
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        let body = {
          user_id: userID,
        };
        const res = await dispatch(getPayoutsList(body));
        setLoading(false);
        if (res?.status === 200) {
          setPayDetail(res?.results);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getAvailableBalance = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getStripeBalance(body));
      if (res?.status === 200) {
        setBalanceDetails(res?.results || "");
      }
    } else {
    }
  };

  function formatString(value) {
    let words = value?.split("_");
    let formattedWords = words?.map((word) => {
      return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    });

    return formattedWords.join(" ");
  }

  useEffect(() => {
    getBankAccDetails();
    getPayoutHisDetail();
    getAvailableBalance();
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div class="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder">
          <div class="wt-payout">
            <div class="wt-dashboardtabs">
              <ul class="wt-tabstitle nav navbar-nav">
                <li onClick={() => setTab(1)} class="nav-item">
                  <a class={tab === 1 ? "active" : ""}>Payouts Settings</a>
                </li>
                <li
                  onClick={() => {
                    setTab(2);
                  }}
                  class="nav-item"
                >
                  <a class={tab === 2 ? "active" : ""}>Your Payouts</a>
                </li>
                <li
                  onClick={() => {
                    setTab(3);
                  }}
                  class="nav-item"
                >
                  <a class={tab === 3 ? "active" : ""}>Payment Inprogress</a>
                </li>
              </ul>
            </div>
            {tab === 1 ? (
              <div class="wt-tabscontent">
                <div id="wt-account">
                  <div class="wt-tabsinfo">
                    {!bankDetails?.user_stripe_bank_details ? (
                      <div class="wt-settingscontent">
                        <div class="">
                          <p>
                            Please add your bank account information for payout{" "}
                          </p>
                        </div>
                        <div class="">
                          <div class="form-group wt-btnarea">
                            <a
                              type="submit"
                              class="wt-btn "
                              onClick={handleAddAccount}
                            >
                              Add Your Account{" "}
                            </a>
                          </div>
                          <br />

                          <p>
                            <b>Please note:</b> When proceeding to the next step
                            after clicking “add account”, please select “Other
                            personal services” under “Personal services” and add
                            your BookSculp profile page for your website link.{" "}
                          </p>
                        </div>

                        <div class="wt-formtheme wt-userform payout-holder"></div>
                      </div>
                    ) : (
                      <div class="wt-settingscontent">
                        <p>
                          All of your earnings will go into the bank account
                          listed below.
                        </p>

                        <div className="payccards">
                          <div class="wt-insightsitemq  ">
                          <i class='fas fa-wallet'></i>

                            <div class="wt-title">
                              <span>Available balance</span>
                              <h3>{`${bankDetails?.curency ? "" : "$"}${
                                bankDetails?.amount || 0
                              } ${bankDetails?.curency || ""}`}</h3>
                              <a
                                onClick={() => {
                                  setIsWithDrawModal(true);
                                }}
                                onclick="event_preventDefault(event);"
                                data-toggle="modal"
                                data-target=".wt-withdraw-form"
                                class="wt-btn wt-withdraw-now"
                              >
                                Withdraw now
                              </a>
                            </div>
                          </div>

                          <div class="wt-insightsitemq ">
                            <div className="dfl">
                              <i class="fa fa-bank"></i>
                              <div class="Box-root">
                                <div class="">
                                  <div class="col-md-12">
                                    {bankDetails?.user_stripe_bank_details
                                      ?.bank_name || ""}
                                  </div>
                                  <div class="col-md-12">
                                    {bankDetails?.user_stripe_bank_details
                                      ?.currency || ""}
                                  </div>
                                  <div class="Box-root Padding-right--8">
                                    {bankDetails?.user_stripe_bank_details
                                      ?.routing_number || ""}
                                  </div>
                                  <div class="Box-root Padding-right--8">
                                    {" "}
                                    {` •••• ${
                                      bankDetails?.user_stripe_bank_details
                                        ?.last4 || ""
                                    }`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <a onClick={handleEditAccount} class="wt-btn">
                              Edit your Account
                            </a>
                          </div>
                        </div>
                        <p>
                          <b>Please note:</b> There is a different payout
                          process for different countries. It will take 3-7
                          business days for your funds to transfer to your bank
                          account.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : undefined}
            {tab === 2 ? (
              <div class="wt-tabscontent">
                <div id="wt-account">
                  <div class="wt-tabsinfo">
                    <div class="wt-settingscontent">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Currency</th>
                              <th>Payment Type</th>
                              <th>Created Date</th>
                              <th>Deposit Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payDetail?.length ? (
                              payDetail?.map((item, index) => (
                                <tr key={index}>
                                  <td>{item?.payout_amount || 0}</td>
                                  <td>{item?.payout_currency || ""}</td>
                                  <td>
                                    {formatString(item?.payout_type) || ""}
                                  </td>
                                  <td>{item?.payout_created_date || ""}</td>
                                  <td>{item?.payout_arrival_date || ""}</td>
                                  <td>
                                    <a
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      class="btn-payout"
                                    >
                                      {item?.payout_status || ""}
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <span>No Payout History Found</span>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : undefined}

            {tab === 3 ? (
              <div class="wt-tabscontent">
                <div id="wt-account">
                  <div class="wt-tabsinfo">
                    <div class="wt-settingscontent">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Created Date</th>
                              <th>Estimated Deposit Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {balanceDetails?.balance_transaction?.length ? (
                              balanceDetails?.balance_transaction?.map(
                                (item, index) => (
                                  <tr key={index}>
                                    <td>{item?.totle_price || 0}</td>

                                    <td>
                                      {moment(item?.created).format(
                                        "MMMM Do YYYY, hh:mm a"
                                      ) || ""}
                                    </td>
                                    <td>
                                      {moment(item?.available_on).format(
                                        "MMMM Do YYYY, hh:mm a"
                                      ) || ""}
                                    </td>
                                    <td>
                                      <a
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        class="btn-payout"
                                      >
                                        {formatString(item?.status) || ""}
                                      </a>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <span>No InProgress Payment Found</span>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : undefined}
          </div>
        </div>
      </main>
      {isWithDrawModal ? (
        <WithdrawlAmount
          setVisible={setIsWithDrawModal}
          visible={isWithDrawModal}
          amount={selectedAmount}
          setAmount={setSelectedAmount}
          handleClick={handleWithdrawClick}
        />
      ) : undefined}
    </SidebarLayout>
  );
};

export default PayoutSetting;
