import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { showToast } from "../../Utility";

export const RejectionReason = (props) => {
  const { setVisible, visible, title, text, value, setValue, handleSubmit } =
    props;

  const submit = () => {
    if (!value) {
      showToast("Add Rejection reason", "error");
      return;
    }
    handleSubmit();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
        <div className="form-group">
          <textarea
            type="textarea"
            name="reason"
            id="reason"
            class="form-control"
            placeholder={"Add Reason"}
            onChange={(val) => {
              setValue(val.target.value);
            }}
            value={value}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <button
          type="submit"
          className="wt-btn wt-save-identity"
          onClick={submit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};
