import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  createSession,
  getExtraPayList,
  getJobDetails,
} from "../../Redux/Services/OtherServices";
import { useLocation } from "react-router-dom";
import { Colors } from "../../Constants";
import { showToast } from "../../Utility";
import { Loader } from "../../Components";

const ExtraPayList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const queryParams = new URLSearchParams(location?.search);
  console.log("queryParams-----", queryParams);
  const project_id = queryParams?.get("id");
  const proposalId = queryParams?.get("proposalId");
  const name = queryParams?.get("name");
  const [RatingModal, setRatingModal] = useState(false);
  const [proposalData, setProposalData] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [extraPayList, setExtraPayList] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(name, "namename");

  useEffect(() => {
    getJobData();
    getExtraPayRequestList();
  }, []);

  const handlePayClick = async (item) => {
    let body = {
      project_id: project_id,
      extra_pay_id: item?.pay_id,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      action: "extra_payment_react",
      proposal_id: proposalId,
    };
    setLoading(true);
    let res = await dispatch(createSession(body));
    setLoading(false);
    if (res?.status == 200) {
      if (window && res?.results?.url) {
        window.open(res?.results?.url);
      } else {
        showToast("Something went wrong!");
      }
    }
  };

  const getExtraPayRequestList = async () => {
    let body = {
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      project_id: project_id,
      proposal_id: proposalId,
    };
    setLoading(true);
    let res = await dispatch(getExtraPayList(body));
    setLoading(false);
    // console.log("res?.resultsres?.results-------", res);
    if (res?.status == 200) {
      if (res?.results && res?.results?.length != 0) {
        setExtraPayList(res?.results.reverse());
      } else {
        setExtraPayList([]);
      }
    }
  };

  const getJobData = async () => {
    if (project_id) {
      let body = {
        project_id: project_id,
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(getJobDetails(body));
      setLoading(false);
      if (res?.status == 200) {
        setJobDetail(res?.results?.[0]);
      }
    } else {
    }
  };
  // console.log("jobDetail=====", jobDetail);
  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="dashboard_main">
          <div className="myJobs">
            <div class="wt-managejobcontent">
              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                  <div class="wt-contenthead">
                    <div class="wt-title">
                      {jobDetail?.post_meta_details?.project_status && (
                        <a
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <i
                            style={{
                              color:
                                jobDetail?.post_meta_details?.project_status ==
                                "completed"
                                  ? Colors?.green
                                  : jobDetail?.post_meta_details
                                      ?.project_status == "cancelled"
                                  ? Colors?.red
                                  : Colors?.blue,
                            }}
                            class={
                              jobDetail?.post_meta_details?.project_status ==
                              "completed"
                                ? "fa fa-check-circle"
                                : jobDetail?.post_meta_details
                                    ?.project_status == "cancelled"
                                ? "fa fa-times-circle"
                                : "fa fa-check-fa fa-spinner"
                            }
                          ></i>
                          {"  "}
                          {jobDetail?.post_meta_details?.project_status}
                        </a>
                      )}
                      <h2>{jobDetail?.profile?.post_title}</h2>
                    </div>
                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                      <li>
                        <span>
                          <i class="far fa-flag"></i> Location :{" "}
                          {jobDetail?.post_meta_details?.country} |{" "}
                          {jobDetail?.post_meta_details?.city}
                        </span>
                      </li>
                      <li>
                        <a class="wt-clicksavefolder">
                          <i class="far fa-folder"></i> Type:{" "}
                          {jobDetail?.post_meta_details?._project_type}
                        </a>
                      </li>
                    </ul>
                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="far fa-calendar"></i> Start Date :{" "}
                          {jobDetail?.post_meta_details?.starting_date}
                        </span>
                      </li>
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="far fa-calendar"></i> End Date :{" "}
                          {jobDetail?.post_meta_details?.end_date}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="wt-rightarea">
                    <div class="wt-hireduserstatus">
                      <h4>{jobDetail?.post_meta_details?.count_proposal}</h4>
                      <span>Proposal count</span>
                      <h4>Ongoing</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listproposal">
          <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
            {extraPayList?.length
              ? extraPayList?.map((item, index) => {
                  return (
                    <>
                      <div class="wt-userlistingcontent wt-userlistingcontentvtwo proposalDetails">
                        <div class="wt-title">
                          <span>
                            {name
                              ? `Requested By: ${
                                  name?.split(" ")?.[0] +
                                  " " +
                                  name?.split(" ")?.[1].substring(0, 1)
                                }`
                              : ""}
                          </span>
                          <h4>
                            {" "}
                            Request amount : ${" "}
                            {item?.amount ? item?.amount : "0.00"}
                          </h4>
                          <h6 style={{ color: Colors?.blue }}>
                            Reason : {item?.description || "-"}
                          </h6>
                          <div
                            class={
                              Number(loggedInUser?.user_data?.user_role) ===
                                12 && item?.status !== "pending"
                                ? "wt-btnarea disbaled"
                                : "wt-btnarea"
                            }
                          >
                            <div
                              onClick={() => {
                                if (
                                  Number(loggedInUser?.user_data?.user_role) ===
                                    12 &&
                                  item?.status === "pending"
                                ) {
                                  handlePayClick(item);
                                }
                              }}
                              className="wt-btn wt-request"
                            >
                              {Number(loggedInUser?.user_data?.user_role) ===
                                12 && item?.status === "pending"
                                ? "Pay"
                                : Number(loggedInUser?.user_data?.user_role) ===
                                    12 && item?.status !== "pending"
                                ? "Paid"
                                : item?.status === "completed"
                                ? "Received"
                                : "Requested"}
                            </div>
                          </div>

                          {/* <div className="tArea">
                            <div class="wt-title">
                              <h6 style={{ color: Colors?.blue }}>
                              {item?.description}
                              </h6>
                            </div>


                          </div> */}
                        </div>
                        <div class="wt-rightarea">
                          <div class="wt-hireduserstatus">
                            <h4>$ {item?.amount}</h4>
                            {/* <span> Quoted Price</span> */}
                            {item?.status && (
                              <a
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight: 600,
                                }}
                              >
                                <i
                                  style={{
                                    color:
                                      item?.status == "complete"
                                        ? Colors?.green
                                        : item?.status == "cancelled"
                                        ? Colors?.red
                                        : Colors?.blue,
                                  }}
                                  class={
                                    item?.status == "complete"
                                      ? "fa fa-check-circle"
                                      : item?.status == "cancelled"
                                      ? "fa fa-times-circle"
                                      : "fa fa-check-fa fa-spinner"
                                  }
                                ></i>
                                {"  "}
                                {item?.status}
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              : undefined}
          </div>
        </div>

        {/* <FeedbackModal
          setShow={setRatingModal}
          show={RatingModal}
          proposalId={proposalData?.basic?.ID}
          projectId={proposalData?.proposal_details?.project_id}
          type="Rating"
          // proposal_status= {proposalStatus}
        /> */}
      </SidebarLayout>
    </>
  );
};

export default ExtraPayList;
