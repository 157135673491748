import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import {
  clearUserChat,
  sendMessage,
  getUserChat,
} from "../../Redux/Services/OtherServices";
import { showToast } from "../../Utility";

const ChatCompo = ({
  selectedList,
  chatMsg,
  setChatMsg,
  setLoading,
  chatListing,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const [isConnect, setIsConnect] = useState(false);
  const [text, setText] = useState("");

  const socketRef = useRef();

  const deleteChat = async (msg) => {
    var body = {
      action: "message",
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      chat_to: selectedList?.user_id,
      msg_id: msg,
    };
    let res = await dispatch(clearUserChat(body));
    if (res?.status == 200) {
      let index = chatMsg?.findIndex((item) => item.id === msg);
      // console.log(index, "inddnckbvkjxb");
      if (index !== -1) {
        let newArr = [...chatMsg];
        // console.log(newArr, "llll11111");
        newArr.splice(index, 1);
        // console.log(newArr, "llll22222222");
        setChatMsg(newArr);
      }
      showToast("Message deleted !", "success");
    }
  };

  const handleMessageSend = async () => {
    if (text.trim() === "") {
      // Don't send empty messages
      return;
    }

    setLoading(true);
    let uploadData = new FormData();
    uploadData.append(
      "sender_id",
      partnerPermissions?.user_id || loggedInUser?.user_data?.user_id
    );
    uploadData.append("reciver_id", selectedList?.user_id);
    uploadData.append("message", text);

    let res = await dispatch(sendMessage(uploadData));
    setLoading(false);
    if (res?.status === 200) {
      // console.log(res, "jkjkjkjkj");
      let data = {
        id: Math.random(),
        sender_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
        receiver_id: selectedList?.user_id,
        chat_message: text,
        time_gmt: new Date(),
        user_details: {
          user_id:
            partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
          display_name: loggedInUser?.user_data?.display_name || "",
          email: loggedInUser?.user_data?.user_email,
          user_role: loggedInUser?.user_data?.user_role,
          profile_image: loggedInUser?.profile_image?.[0]?.guid || "",
        },
      };
      // setChatMsg([...chatMsg, data]);
      setChatMsg((prevChatMsg) => [...prevChatMsg, data]);
      setText("");
    }
  };

  console.log(chatMsg, "chatttttt");

  const getUserChatData = async () => {
    // setLoading(true);
    if (loggedInUser?.user_data?.user_id) {
      let body = {
        user_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
        chat_to: selectedList?.user_id,
      };
      let res = await dispatch(getUserChat(body));
      // setLoading(false);
      if (res?.status === 200) {
        setChatMsg(res?.results?.chat || []);
      }
    } else {
      // setLoading(false);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const ele = document.getElementById("scrollTo");
      ele?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 800);
  };

  // useEffect(() => {
  //   if (chatListing?.length) {
  //     getUserChatData();
  //     scrollToBottom()
  //   }
  // }, [selectedList, chatListing]);

  useEffect(() => {
    if (chatListing?.length) {
      getUserChatData();
      scrollToBottom();

      const intervalId = setInterval(getUserChatData, 5000);

      return () => clearInterval(intervalId);
    }
  }, [chatListing, selectedList]);

  // const leaveRoom = async () => {
  //   const data = {
  //     id: loggedInUser?.user_data?.user_id,
  //   };

  //   socketRef.current?.emit("disconnected", data);
  //   socketRef.current?.on("disconnected", async (message) => {
  //     console.log(message, "===room left====");
  //   });
  //   // console.log(socketRef?.current,"mnmnmbnvbvb");
  // };

  // useEffect(() => {
  //   if (isConnect) {
  //     socketRef.current.emit("add-user", loggedInUser?.user_data?.user_id);
  //     console.log(socketRef.current,"klklklklklksockkkk")
  //     // socketRef.current.on("add-userOk", async (msg) => {
  //     //   console.log("Chat socket gdsahgdhasgjdsa", msg);
  //     // });
  //     console.log("socket------", socketRef.current);
  //   }
  // }, [isConnect]);

  // useEffect(() => {

  //   // console.log(socket,"klklklklklksockkkk")
  //   socketRef.current = io("http://72.167.47.22:8098/");

  //   // let socket = socketRef.current;

  //   socketRef.current.on("connect", () => {
  //     console.log("Chat socket connection1", socketRef.current);
  //     setIsConnect(true);
  //   });
  //   return () => {
  //     setIsConnect(false);
  //     leaveRoom();
  //   };
  // }, []);

  return (
    <>
      <li>
        <div class="wt-chatarea">
          <div class="wt-messages wt-verticalscrollbar wt-dashboardscrollbar mCustomScrollbar _mCS_3">
            <div
              id="mCSB_3"
              class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
              tabindex="0"
            >
              <div id="mCSB_3_container" class="mCSB_container" dir="ltr">
                {chatMsg?.length ? (
                  chatMsg?.map((item) => (
                    <div
                      key={item?.id}
                      class={
                        loggedInUser?.user_data?.user_id ===
                          item?.receiver_id ||
                        partnerPermissions?.user_id === item?.receiver_id
                          ? "wt-offerermessage"
                          : "wt-memessage wt-readmessage"
                      }
                    >
                      <figure>
                        <img
                          src={
                            item?.user_details?.profile_image ||
                            "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                          }
                          alt="image description"
                          class="mCS_img_loaded"
                        />
                      </figure>
                      <div class="wt-description">
                        <p>{item?.chat_message || ""}</p>
                        <div class="clearfix"></div>
                        <div className="delDate">
                          <time datetime="2017-08-08">
                            {moment(item?.time_gmt).format("MMMM Do, YYYY")}
                          </time>
                          <small onClick={() => deleteChat(item?.id)}>
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </small>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p>No Message Yet</p>
                    <span>Start The Conversation</span>
                  </div>
                )}

                <div id="scrollTo" />

                {/* <div class="wt-memessage wt-readmessage">
                  <figure>
                    <img
                      src="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                      alt="image description"
                      class="mCS_img_loaded"
                    />
                  </figure>
                  <div class="wt-description">
                    <p>
                      Eiusmod tempor incididunt labore et dolore magna aliqiu
                      enim ad minim veniam qiuisru exercitation ullamco
                      laborisen nisi ut aliquip exea.
                    </p>
                    <div class="clearfix"></div>
                    <p>https://themeforest.net</p>
                    <div class="clearfix"></div>
                    <p>It that ok?</p>
                    <div class="clearfix"></div>
                    <time datetime="2017-08-08">Jun 28, 2017 09:30</time>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="wt-offerermessage">
                  <figure>
                    <img
                      src="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                      alt="image description"
                      class="mCS_img_loaded"
                    />
                  </figure>
                  <div class="wt-description">
                    <div class="clearfix"></div>
                    <p>
                      Consectetur adipisicing elit sei do eiusmod tempor
                      incididunt labore et dolore.
                    </p>
                    <div class="clearfix"></div>
                    <time datetime="2017-08-08">January 12th, 2011</time>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="wt-memessage wt-readmessage">
                  <figure>
                    <img
                      src="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                      alt="image description"
                      class="mCS_img_loaded"
                    />
                  </figure>
                  <div class="wt-description">
                    <div class="clearfix"></div>
                    <p>
                      Eiusmod tempor incididunt labore et dolore magna aliqiu
                      enim ad minim veniam qiuisru exercitation ullamco
                      laborisen nisi ut aliquip exea.
                    </p>
                    <div class="clearfix"></div>
                    <p>https://themeforest.net</p>
                    <div class="clearfix"></div>
                    <p>It that ok?</p>
                    <div class="clearfix"></div>
                    <time datetime="2017-08-08">Jun 28, 2017 09:30</time>
                    <div class="clearfix"></div>
                  </div>
                </div> */}
              </div>
              <div
                id="mCSB_3_scrollbar_vertical"
                class="mCSB_scrollTools mCSB_3_scrollbar mCS-light mCSB_scrollTools_vertical"
              >
                <div class="mCSB_draggerContainer">
                  <div id="mCSB_3_dragger_vertical" class="mCSB_dragger">
                    <div class="mCSB_dragger_bar"></div>
                  </div>
                  <div class="mCSB_draggerRail"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="wt-replaybox">
            <div class="form-group">
              <textarea
                class="form-control"
                name="reply"
                placeholder="Type message here"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              ></textarea>
            </div>
            <div class="wt-iconbox">
              <i class="lnr lnr-thumbs-up"></i>
              <i class="lnr lnr-thumbs-down"></i>
              <i class="lnr lnr-smile"></i>
              <a
                onClick={handleMessageSend}
                href="javascript:void(0);"
                class="wt-btnsendmsg"
              >
                Send
              </a>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default ChatCompo;
