import React from "react";
import { DropdownList, MultiSelectDropdown } from "../../Components";
import { useSelector } from "react-redux";

const SizeDetails = (props) => {
  const {
    error,
    talent,
    gender,
    shirtSizeC,
    setShirtSizeC,
    pantSizeWC,
    setPantSizeWC,
    shoeSizeC,
    setShoeSizeC,
    dressSizeC,
    setDressSizeC,
    shirtSize,
    setShirtSize,
    pantSizeW,
    setPantSizeW,
    pantSizeL,
    setPantSizeL,
    setShoeSize,
    shoeSize,
    dressShirt,
    setDressShirt,
    shirtSleeve,
    setShirtSleeve,
    neckSize,
    setNeckSize,
    chestSize,
    setChestSize,
    jacket,
    setJacket,
    shirtSizeF,
    setShirtSizeF,
    pantSize,
    setPantSize,
    braCup,
    setBraCup,
    braSize,
    setBraSize,
    dressSize,
    setDressSize,
    shoeSizeF,
    setShoeSizeF,
  } = props;

  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  return (
    <div>
      {(talent == "Model" && gender) ||
      (talent == "Model Kid" && gender) ||
      (talent == "Actor" && gender) ||
      (talent == "Actor Kid" && gender) ? (
        <div class="form-section">
          <div className="form-section-title">
            {talent == "Model Kid"
              ? "Kid Model Measurements"
              : talent == "Model"
              ? gender == "Male" || gender == "male"
                ? "Male Model Measurement"
                : gender == "Female" || gender == "female"
                ? "Female Model Measurement"
                : "Model Measurement For Non Binary"
              : ""}
            {talent == "Actor Kid"
              ? "Kid Actor Measurements"
              : talent == "Actor"
              ? gender == "Male" || gender == "male"
                ? "Male Actor Measurement"
                : gender == "Female" || gender == "female"
                ? "Female Actor Measurement"
                : "Actor Measurement For Non Binary"
              : ""}
          </div>
          <div class="form-group form-group-50">
            {talent == "Model Kid" || talent == "Actor Kid" || talent == "child" ? (
              <>
                <MultiSelectDropdown
                  required
                  options={
                    optionsType?.childOptions?.toddler_shirt_size?.field_meta
                      ?.choices
                  }
                  title="Shirt size"
                  placeholder="Select Shirt size"
                  value={shirtSizeC}
                  setValue={setShirtSizeC}
                  isValid={error && shirtSizeC?.length == 0}
                />
                <MultiSelectDropdown
                  required
                  title="Pant Size"
                  placeholder="Select Pant Size"
                  value={pantSizeWC}
                  setValue={setPantSizeWC}
                  options={
                    optionsType?.childOptions?.toddler_pant_size?.field_meta
                      ?.choices
                  }
                  isValid={error && pantSizeWC?.length == 0}
                />

                <MultiSelectDropdown
                  required
                  title="Shoe Size"
                  placeholder="Select Shoe Size"
                  value={shoeSizeC}
                  setValue={setShoeSizeC}
                  options={
                    optionsType?.childOptions?.toddler_shoe_size?.field_meta
                      ?.choices
                  }
                  isValid={error && shoeSizeC?.length == 0}
                />
                {gender == "Female" || gender == "female" ? (
                  <MultiSelectDropdown
                    required
                    title={"Dress Size"}
                    placeholder={"Select Dress Size"}
                    value={dressSizeC}
                    setValue={setDressSizeC}
                    options={
                      optionsType?.childOptions?.toddler_dress_size?.field_meta
                        ?.choices
                    }
                    isValid={error && dressSizeC?.length == 0}
                  />
                ) : null}
              </>
            ) : gender == "Male" || gender == "male" ? (
              <>
                <MultiSelectDropdown
                  required
                  title={"Shirt Size"}
                  placeholder={"Select Shirt Size"}
                  value={shirtSize}
                  setValue={setShirtSize}
                  options={
                    optionsType?.maleOptions?.shirt_size?.field_meta?.choices
                  }
                  isValid={error && shirtSize?.length == 0}
                />
                <MultiSelectDropdown
                  required
                  title={"Pant Size (Waist)"}
                  placeholder={"Select Pant Size"}
                  value={pantSizeW}
                  setValue={setPantSizeW}
                  options={
                    optionsType?.maleOptions?.pant_size_waist?.field_meta
                      ?.choices
                  }
                  isValid={error && pantSizeW?.length == 0}
                />
                <MultiSelectDropdown
                  required
                  title={"Pant Size (Length)"}
                  placeholder={"Select Pant Size"}
                  value={pantSizeL}
                  setValue={setPantSizeL}
                  options={
                    optionsType?.maleOptions?.pant_size_length?.field_meta
                      ?.choices
                  }
                  isValid={error && pantSizeL?.length == 0}
                />

                <MultiSelectDropdown
                  required
                  title={"Shoe Size"}
                  placeholder={"Select Shoe Size"}
                  value={shoeSize}
                  setValue={setShoeSize}
                  options={
                    optionsType?.maleOptions?.shoe_size?.field_meta?.choices
                  }
                  isValid={error && shoeSize?.length == 0}
                />

                <DropdownList
                  required
                  title="Dress Shirt Size"
                  placeholder="Select Dress Shirt Size"
                  value={dressShirt}
                  setValue={setDressShirt}
                  options={
                    optionsType?.maleOptions?.dress_shirt_size?.field_meta
                      ?.choices
                  }
                  isValid={error && !dressShirt}
                />

                <DropdownList
                  required
                  title="Dress Shirt Sleeve"
                  placeholder="Select Dress Shirt Sleeve"
                  value={shirtSleeve}
                  setValue={setShirtSleeve}
                  options={
                    optionsType?.maleOptions?.dress_shirt_sleeve?.field_meta
                      ?.choices
                  }
                  isValid={error && !shirtSleeve}
                />

                <DropdownList
                  required
                  title="Neck Size"
                  placeholder="Select Neck Size"
                  value={neckSize}
                  setValue={setNeckSize}
                  options={
                    optionsType?.maleOptions?.neck_size?.field_meta?.choices
                  }
                  isValid={error && !neckSize}
                />

                <DropdownList
                  required
                  title="Chest Size"
                  placeholder="Select Chest Size"
                  value={chestSize}
                  setValue={setChestSize}
                  options={
                    optionsType?.maleOptions?.chest_size?.field_meta?.choices
                  }
                  isValid={error && !chestSize}
                />
                <DropdownList
                  required
                  title="Jacket"
                  placeholder="Select Jacket"
                  value={jacket}
                  setValue={setJacket}
                  options={
                    optionsType?.maleOptions?.jacket?.field_meta?.choices
                  }
                  isValid={error && !jacket}
                />
              </>
            ) : gender == "Female" || gender == "female" ? (
              <>
                <MultiSelectDropdown
                  required
                  title={"Shirt Size"}
                  placeholder={"Select Shirt Size"}
                  value={shirtSizeF}
                  setValue={setShirtSizeF}
                  options={
                    optionsType?.femaleOptions?.shirt_size_f?.field_meta
                      ?.choices
                  }
                  isValid={error && shirtSizeF?.length == 0}
                />
                <MultiSelectDropdown
                  required
                  title={"Pant Size"}
                  placeholder={"Select Pant Size"}
                  value={pantSize}
                  setValue={setPantSize}
                  options={
                    optionsType?.femaleOptions?.pant_size_f?.field_meta?.choices
                  }
                  isValid={error && pantSize?.length == 0}
                />
                <div className="fullCol">
                  <span>
                    Bra size and Bra cup will only be visible to clients.
                  </span>
                </div>
                <DropdownList
                  required
                  title="Bra Cup"
                  placeholder="Select Bra Cup"
                  value={braCup}
                  setValue={setBraCup}
                  options={
                    optionsType?.femaleOptions?.bra_cup?.field_meta?.choices
                  }
                  isValid={error && !braCup}
                />
                <DropdownList
                  required
                  title="Bra Size"
                  placeholder="Select Bra Size"
                  value={braSize}
                  setValue={setBraSize}
                  options={
                    optionsType?.femaleOptions?.bra_size?.field_meta?.choices
                  }
                  isValid={error && !braSize}
                />
                <DropdownList
                  required
                  title="Dress Size"
                  placeholder="Select Dress Size"
                  value={dressSize}
                  setValue={setDressSize}
                  options={
                    optionsType?.femaleOptions?.dress_size_f?.field_meta
                      ?.choices
                  }
                  isValid={error && !dressSize}
                />

                <MultiSelectDropdown
                  required
                  title={"Shoe Size"}
                  placeholder={"Select Shoe Size"}
                  value={shoeSizeF}
                  setValue={setShoeSizeF}
                  options={
                    optionsType?.femaleOptions?.shoe_size_f?.field_meta?.choices
                  }
                  isValid={error && shoeSizeF?.length == 0}
                />
              </>
            ) : (
              <>
                <MultiSelectDropdown
                  title={"Shirt Size (M)"}
                  placeholder={"Select Shirt Size"}
                  value={shirtSize}
                  setValue={setShirtSize}
                  options={
                    optionsType?.maleOptions?.shirt_size?.field_meta?.choices
                  }
                />
                <MultiSelectDropdown
                  title={"Pant Size (Waist)"}
                  placeholder={"Select Pant Size"}
                  value={pantSizeW}
                  setValue={setPantSizeW}
                  options={
                    optionsType?.maleOptions?.pant_size_waist?.field_meta
                      ?.choices
                  }
                />
                <MultiSelectDropdown
                  title={"Pant Size (Length)"}
                  placeholder={"Select Pant Size"}
                  value={pantSizeL}
                  setValue={setPantSizeL}
                  options={
                    optionsType?.maleOptions?.pant_size_length?.field_meta
                      ?.choices
                  }
                />

                <MultiSelectDropdown
                  title={"Shoe Size (M)"}
                  placeholder={"Select Shoe Size"}
                  value={shoeSize}
                  setValue={setShoeSize}
                  options={
                    optionsType?.maleOptions?.shoe_size?.field_meta?.choices
                  }
                />

                <DropdownList
                  title="Dress Shirt Size (M)"
                  placeholder="Select Dress Shirt Size"
                  value={dressShirt}
                  setValue={setDressShirt}
                  options={
                    optionsType?.maleOptions?.dress_shirt_size?.field_meta
                      ?.choices
                  }
                />

                <DropdownList
                  title="Dress Shirt Sleeve (M)"
                  placeholder="Select Dress Shirt Sleeve"
                  value={shirtSleeve}
                  setValue={setShirtSleeve}
                  options={
                    optionsType?.maleOptions?.dress_shirt_sleeve?.field_meta
                      ?.choices
                  }
                />

                <DropdownList
                  title="Neck Size (M)"
                  placeholder="Select Neck Size"
                  value={neckSize}
                  setValue={setNeckSize}
                  options={
                    optionsType?.maleOptions?.neck_size?.field_meta?.choices
                  }
                />

                <DropdownList
                  title="Chest Size (M)"
                  placeholder="Select Chest Size"
                  value={chestSize}
                  setValue={setChestSize}
                  options={
                    optionsType?.maleOptions?.chest_size?.field_meta?.choices
                  }
                />

                <DropdownList
                  title="Jacket (M)"
                  placeholder="Select Jacket"
                  value={jacket}
                  setValue={setJacket}
                  options={
                    optionsType?.maleOptions?.jacket?.field_meta?.choices
                  }
                />
                <MultiSelectDropdown
                  title={"Shirt Size (F)"}
                  placeholder={"Select Shirt Size"}
                  value={shirtSizeF}
                  setValue={setShirtSizeF}
                  options={
                    optionsType?.femaleOptions?.shirt_size_f?.field_meta
                      ?.choices
                  }
                />
                <MultiSelectDropdown
                  title={"Pant Size (F)"}
                  placeholder={"Select Pant Size"}
                  value={pantSize}
                  setValue={setPantSize}
                  options={
                    optionsType?.femaleOptions?.pant_size_f?.field_meta?.choices
                  }
                />
                <MultiSelectDropdown
                  title={"Shoe Size (F)"}
                  placeholder={"Select Shoe Size"}
                  value={shoeSizeF}
                  setValue={setShoeSizeF}
                  options={
                    optionsType?.femaleOptions?.shoe_size_f?.field_meta?.choices
                  }
                />
                <div className="fullCol">
                  <span>
                    Bra size and Bra cup will only be visible to clients.
                  </span>
                </div>
                <DropdownList
                  title="Bra Cup (F)"
                  placeholder="Select Bra Cup"
                  value={braCup}
                  setValue={setBraCup}
                  options={
                    optionsType?.femaleOptions?.bra_cup?.field_meta?.choices
                  }
                />

                <DropdownList
                  title="Bra Size (F)"
                  placeholder="Select Bra Size"
                  value={braSize}
                  setValue={setBraSize}
                  options={
                    optionsType?.femaleOptions?.bra_size?.field_meta?.choices
                  }
                />
                <DropdownList
                  title="Dress Size (F)"
                  placeholder="Select Dress Size"
                  value={dressSize}
                  setValue={setDressSize}
                  options={
                    optionsType?.femaleOptions?.dress_size_f?.field_meta
                      ?.choices
                  }
                />
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SizeDetails;
