import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { InputField } from "../InputField";
import { useEffect, useState } from "react";
import { isValidEmail, showToast } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../Redux/Services/AuthServices";
import { Loader } from "../Loader";
import { OtpVerify } from "./OtpVerify";

export const ForgotPassword = (props) => {
  const {setModalType, modalType, setResponse} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.authReducer);

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [forget, setForget] = useState(false);
  const [error, setError] = useState(false);
//   const [response, setResponse] = useState("");

  const emailValid = isValidEmail(email);

  const handleForgetPassword = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    // setError(true);
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      if (!email) {
        showToast("Please Enter Email", "error");
      } else {
        var body = {
          user_email: email.trim(),
        };
        let res = await dispatch(forgetPassword(body));
        if (res?.status == 200) {
          setResponse(res?.results);
          setModalType('otp')
        }
      }
    }
  };

  return (
    <>
      
        <div className="passwordform Loginform">
          <div className="signUp" onClick={() => setForget(false)}>
            If you have an account? <a>Sign in</a>
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleForgetPassword}
          >
            <InputField
              required
              type="email"
              placeholder="Enter email"
              value={email}
              onChangeText={(e) => {
                setEmail(e?.target?.value);
              }}
              isValid={validated && emailValid}
              page="auth"
            />
            <Button variant="primary" type="submit">
              Get Password
            </Button>
          </Form>
        </div>
    
    </>
  );
};
