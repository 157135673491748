import React, { useState } from "react";
import { clearUserChat } from "../../Redux/Services/OtherServices";
import { DeletePost } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Utility";

const ChatList = ({
  chatListing,
  setSelectedList,
  selectedList,
  getChatListData,
  setChatListing,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredRes, setFilteredRes] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  function searchUsers() {
    const lowercasedSearchText = searchText.toLowerCase();

    const filteredData = chatListing?.filter(
      (user) =>
        user.first_name.toLowerCase().includes(lowercasedSearchText) ||
        user.last_name.toLowerCase().includes(lowercasedSearchText) ||
        user.display_name.toLowerCase().includes(lowercasedSearchText)
    );
    setFilteredRes(filteredData);
    if (lowercasedSearchText === "") {
      setIsSearch(false);
    }
  }

  const deleteChat = async () => {
    var body = {
      action: "single",
      user_id: partnerPermissions?.user_id ||  loggedInUser?.user_data?.user_id,
      chat_to: selectedId,
    };
    let res = await dispatch(clearUserChat(body));
    if (res?.status == 200) {
      getChatListData();
      showToast("Message deleted !", "success");
    }
  };

  return (
    <>
      <li>
        <form class="wt-formtheme wt-formsearch">
          <fieldset>
            <div class="form-group">
              <input
                type="text"
                name="Location"
                class="form-control"
                placeholder="Search Here"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSearch(true);
                  searchUsers();
                }}
                href="javascrip:void(0);"
                class="wt-searchgbtn"
              >
                <i class="lnr lnr-magnifier"></i>
              </a>
            </div>
          </fieldset>
        </form>
        <div class="wt-verticalscrollbar wt-dashboardscrollbar mCustomScrollbar _mCS_2 mCS_no_scrollbar">
          <div
            id="mCSB_2"
            class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
            tabindex="0"
          >
            <div
              id="mCSB_2_container"
              class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
              dir="ltr"
            >
              {filteredRes?.length || isSearch
                ? filteredRes?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => setSelectedList(item)}
                      class={`wt-ad wt-dotnotification ${
                        selectedList === item ? "wt-active" : ""
                      }`}
                    >
                      <figure>
                        <img
                          src={
                            item?.profile_image ||
                            "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                          }
                          alt="image description"
                          class="mCS_img_loaded"
                        />
                      </figure>
                      <small
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDeleteModal(true);
                          setSelectedId(partnerPermissions?.user_id || item?.user_id);
                        }}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </small>
                      <div class="wt-adcontent">
                        <h3>{`${item?.first_name || ""} ${
                          item?.last_name?.slice(0, 1) || ""
                        }`}</h3>
                        <span>{item?.last_eassage?.chat_message || ""}</span>
                      </div>
                    </div>
                  ))
                : chatListing?.length
                ? chatListing?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => setSelectedList(item)}
                      class={`wt-ad wt-dotnotification ${
                        selectedList === item ? "wt-active" : ""
                      }`}
                    >
                      <figure>
                        <img
                          src={
                            item?.profile_image ||
                            "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                          }
                          alt="image description"
                          class="mCS_img_loaded"
                        />
                      </figure>
                      <small
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDeleteModal(true);
                          setSelectedId(partnerPermissions?.user_id || item?.user_id);
                        }}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </small>
                      <div class="wt-adcontent">
                        <h3>{`${item?.first_name || ""} ${
                          item?.last_name?.slice(0, 1) || ""
                        }`}</h3>
                        <span>{item?.last_eassage?.chat_message || ""}</span>
                      </div>
                    </div>
                  ))
                : undefined}
              {/* <div class="wt-ad wt-dotnotification">
                <figure>
                  <img
                    src="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                    alt="image description"
                    class="mCS_img_loaded"
                  />
                </figure>
                <div class="wt-adcontent">
                  <h3>Jed Loeffler</h3>
                  <span>Consectetur adipisicing elit sed do...</span>
                </div>
              </div> */}
            </div>
            <div
              id="mCSB_2_scrollbar_vertical"
              class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
            >
              <div class="mCSB_draggerContainer">
                <div id="mCSB_2_dragger_vertical" class="mCSB_dragger">
                  <div class="mCSB_dragger_bar"></div>
                </div>
                <div class="mCSB_draggerRail"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={deleteChat}
          text="Are you sure you want to delete the chat with this user?"
        />
      ) : undefined}
    </>
  );
};

export default ChatList;
