import {
  ADD_PARTNERS_LIST_ERROR,
  ADD_PARTNERS_LIST_LOADING,
  ADD_PARTNERS_LIST_SUCCESS,
  CHECK_EMAIL_STATUS_ERROR,
  CHECK_EMAIL_STATUS_LOADING,
  CHECK_EMAIL_STATUS_SUCCESS,
  DELETE_PARTNER_ERROR,
  DELETE_PARTNER_LOADING,
  DELETE_PARTNER_SUCCESS,
  GET_PARTNERS_LIST_ERROR,
  GET_PARTNERS_LIST_LOADING,
  GET_PARTNERS_LIST_SUCCESS,
  MAIL_SINGLE_PARTNER_ERROR,
  MAIL_SINGLE_PARTNER_LOADING,
  MAIL_SINGLE_PARTNER_SUCCESS,
  PARTNER_ACCESS_PERMISSIONS,
  VIEW_PROJECT_PARTNER_ERROR,
  VIEW_PROJECT_PARTNER_LOADING,
  VIEW_PROJECT_PARTNER_SUCCESS,
} from "../Consts/PartnerConstants";

export const addPartnerListLoading = (isLoading) => ({
  type: ADD_PARTNERS_LIST_LOADING,
  isLoading: isLoading,
});
export const addPartnerListSuccess = (isLoading) => ({
  type: ADD_PARTNERS_LIST_SUCCESS,
  isLoading: isLoading,
});
export const addPartnerListError = (isLoading) => ({
  type: ADD_PARTNERS_LIST_ERROR,
  isLoading: isLoading,
});

export const getPartnerListLoading = (isLoading) => ({
  type: GET_PARTNERS_LIST_LOADING,
  isLoading: isLoading,
});
export const getPartnerListSuccess = (isLoading) => ({
  type: GET_PARTNERS_LIST_SUCCESS,
  isLoading: isLoading,
});
export const getPartnerListError = (isLoading) => ({
  type: GET_PARTNERS_LIST_ERROR,
  isLoading: isLoading,
});


export const deletePartnerListLoading = (isLoading) => ({
  type: DELETE_PARTNER_LOADING,
  isLoading: isLoading,
});
export const deletePartnerListSuccess = (isLoading) => ({
  type: DELETE_PARTNER_SUCCESS,
  isLoading: isLoading,
});
export const deletePartnerErrorLoading = (isLoading) => ({
  type: DELETE_PARTNER_ERROR,
  isLoading: isLoading,
});

export const viewPartnerProjectError = (isLoading) => ({
  type: VIEW_PROJECT_PARTNER_ERROR,
  isLoading: isLoading,
});
export const viewPartnerProjectSuccess = (isLoading) => ({
  type: VIEW_PROJECT_PARTNER_SUCCESS,
  isLoading: isLoading,
});
export const viewPartnerProjectLoading = (isLoading) => ({
  type: VIEW_PROJECT_PARTNER_LOADING,
  isLoading: isLoading,
});

export const sendMailSinglePartnerLoading = (isLoading) => ({
  type: MAIL_SINGLE_PARTNER_LOADING,
  isLoading: isLoading,
});
export const sendMailSinglePartnerSuccess = (isLoading) => ({
  type: MAIL_SINGLE_PARTNER_SUCCESS,
  isLoading: isLoading,
});
export const sendMailSinglePartnerError = (isLoading) => ({
  type: MAIL_SINGLE_PARTNER_ERROR,
  isLoading: isLoading,
});

export const checkEmailStatusLoading = (isLoading) => ({
  type: CHECK_EMAIL_STATUS_LOADING,
  isLoading: isLoading,
});
export const checkEmailStatusSuccess = (isLoading) => ({
  type: CHECK_EMAIL_STATUS_SUCCESS,
  isLoading: isLoading,
});
export const checkEmailStatusError = (isLoading) => ({
  type: CHECK_EMAIL_STATUS_ERROR,
  isLoading: isLoading,
});

export const partnerAccessPermissions = (data) => ({
  type: PARTNER_ACCESS_PERMISSIONS,
  data,
});