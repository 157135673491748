import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName, showToast } from "../../Utility";
import { Footer, Header } from "../../Components";
import { useDispatch } from "react-redux";
import {
  getUserDetail,
  userLoginRedirect,
} from "../../Redux/Services/AuthServices";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";
import { clearData, getData, storageKey } from "../../Utility/Storage";

const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const screen = queryParams?.get("page");
  const data = queryParams?.get("data");
  const dispatch = useDispatch();

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    // setUserId(userID);
    let userRole = await getData(storageKey?.USER_ROLE);
    // setUserRole(userRole);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));

      if (res?.status == 200) {
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
      }
    }
  };

  const logout = async () => {
    // clearData();
    dispatch(loggedInUsedAction(null));
    navigate(routeName.HOME, { replace: true });
  };

  const handleLogin = async (screenName) => {
    let decoded = "";
    if (window) {
      let decodeData = atob(data);
      decoded = atob(decodeData?.split("_")?.[1]);
      // console.log(decoded, decoded?.user_id, "lplpllplp");
    }

    // console.log(decoded, JSON.parse(decoded)?.user_id, "lplp222222");

    var body = {
      user_id: decoded ? JSON.parse(decoded)?.user_id : "",
      profile_id: decoded ? JSON.parse(decoded)?.profile_id : "",
    };
    let res = await dispatch(userLoginRedirect(body));
    if (res?.status == 200) {
      // console.log(
      //   "login response-----",
      //   res?.results?.user_role,
      //   Number(res?.results?.model_age)
      // );
      // setShow(false);
      getUserData();
      let modelType;
      if (res?.results?.user_role == 12) {
        modelType = "Client";
      } else if (res?.results?.user_role === 13) {
        modelType = "Photographer";
      } else if (res?.results?.model_type == "model") {
        modelType = "Model";
      } else if (
        res?.results?.model_type == "child" &&
        res?.results?.user_role === 11
      ) {
        modelType = "Model Kid";
      } else if (
        res?.results?.model_type == "photographer" ||
        res?.results?.user_role === 13
      ) {
        modelType = "Photographer";
      } else if (
        res?.results?.user_role == 15 &&
        res?.results?.model_type == "actor"
      ) {
        modelType = "Actor";
      } else if (
        res?.results?.user_role == 15 &&
        res?.results?.model_type == "child"
      ) {
        modelType = "Actor Kid";
      } else if (res?.results?.user_role == 14) {
        modelType = "Partners";
      }

      if (screenName === "register") {
        navigate(
          `${routeName?.REGISTER}?email=${res?.results?.email}&type=${modelType}`,
          {
            state: { prevRoute: routeName?.LOGIN, data: res?.results },
          }
        );
      } else if (screenName === "home") {
        navigate(routeName.HOME, { replace: true });
      } else if (screenName === "searchtalent") {
        navigate(routeName.SEARCH_TALENT);
      } else if (screenName === "searchJob") {
        navigate(routeName.SEARCH_JOBS);
      } else if (screenName === "insights") {
        navigate(routeName.INSIGHTS);
      } else if (screenName === "editProfile") {
        navigate(routeName.EDIT_PROFILE);
      }
    }
  };

  useEffect(() => {
    clearData();
    dispatch(loggedInUsedAction(null));
    if (screen === "register") {
      handleLogin("register");
    } else if (screen === "signup") {
      navigate("/signup");
    } else if (screen === "home") {
      handleLogin("home");
    } else if (screen === "editProfile") {
      handleLogin("editProfile");
    } else if (screen === "insights") {
      handleLogin("insights");
    } else if (screen === "searchJob") {
      handleLogin("searchJob");
    } else if (screen === "searchtalent") {
      handleLogin("searchtalent");
    } else if (screen === "logout") {
      logout();
    }
  }, [screen]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Header />
      <Footer />
    </div>
  );
};

export default Redirect;
