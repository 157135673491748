import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { routeName } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { storageKey, storeData } from "../../Utility/Storage";

export const NotifPopup = (props) => {
  const { text, show, setShow, selectedItem } = props;
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Notification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        {selectedItem?.notification_type === "send_offer" ? (
          <Button
            onClick={() => {
              const queryParams = new URLSearchParams({
                id: selectedItem?.post_id,
                type: "notification",
              });
              setShow(false);
              navigate(`${routeName.JOB_DETAILS}?${queryParams.toString()}`);
            }}
          >
            Continue
          </Button>
        ) : undefined}

        {selectedItem?.notification_type === "send_offer" ? (
          <Button
            onClick={() => {
              const queryParams = new URLSearchParams({
                id: selectedItem?.post_id,
                type: "notification",
              });
              setShow(false);
              navigate(`${routeName.JOB_DETAILS}?${queryParams.toString()}`);
            }}
          >
            Continue
          </Button>
        ) : undefined}

        {selectedItem?.notification_type === "Rejected_request_by_model" ||
        selectedItem?.notification_type === "ask_reject_request_to_client" ||
        selectedItem?.notification_type === "submit_proposal" ||
        selectedItem?.notification_type === "ask_reject_request_to_model" ||
        selectedItem?.notification_type === "hire" ? (
          <Button
            onClick={() => {
              if (
                loggedInUser?.user_data?.user_role === 12 ||
                loggedInUser?.user_data?.user_role === 14
              ) {
                storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
                const queryParams = new URLSearchParams({
                  id: selectedItem?.post_id,
                  status: "ongoing",
                });
                navigate(`${routeName.PROPOSALS}?${queryParams.toString()}`);
              } else {
                storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
                navigate(`${routeName.MODEL_JOBS}`);
              }
            }}
          >
            View
          </Button>
        ) : undefined}
      </Modal.Footer>
    </Modal>
  );
};
