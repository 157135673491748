import React, { useEffect, useState } from "react";
import { ImageCropper, InputField, Loader } from "../../../Components";
import { getCountryList } from "../../../Redux/Services/OtherServices";
import { Form } from "react-bootstrap";
import { bytesToKB, routeName, showToast } from "../../../Utility";
import {
  removeImage,
  uploadGalleryProfile,
} from "../../../Redux/Services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SocialMediaHandle } from "../../../features/profile";
import SidebarLayout from "../../../layout/SidebarLayout";
import { ClientBasics, CompanyInfo } from "../../../features/admin";
import {
  addClientByAdmin,
  addModelByIdApi,
  addPartnerByAdmin,
  getUsersDataById,
  updateClientByAdminApi,
  updateModelByIdApi,
  updatePartnerByAdminApi,
} from "../../../Redux/Services/AdminServices";

const AddEditClient = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  const type = queryParams?.get("type");

  const auth = useSelector((state) => state?.authReducer);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    displayName: "",
    phoneNo: "",
    email: "",
    password: "",
    tagline: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    organisation: "",
    title: "",
    businessEmail: "",
    businessWebite: "",
    businessYear: "",
    briefDesc: "",
  });
  const [socialLinks, setSocialLinks] = useState({
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    vimeo: "",
    tikTok: "",
  });

  const [tiktokFollowers, setTiktokFollowers] = useState("");
  const [vimeoFollowers, setVimeoFollowers] = useState("");
  const [instagramFollowers, setInstagramFollowers] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [reelLink, setReelLink] = useState("");
  const [twitterFollowers, setTwitterFollowers] = useState("");
  const [facebookFollowers, setFacebookFollowers] = useState("");
  const [youtubeFollowers, setYoutubeFollowers] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [locationList, setLocationList] = useState({
    countryList: "",
    stateList: "",
    cityList: "",
  });
  const [userResponse, setUserResponse] = useState("");
  const [editData, setEditData] = useState("");
  const [cropped, setCropped] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  const handleSelectCountry = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = locationList?.countryList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    getAllStateName(selectedOptionObject?.id);
    if (e.target.value == "") {
      setCountry("");
    } else {
      setCountry(e.target.value);
    }
  };

  const handleSelectState = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = locationList?.stateList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    getAllCityName(selectedOptionObject?.id);
    if (e.target.value == "") {
      setState("");
    } else {
      setState(e.target.value);
    }
  };

  const handleSelectCity = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = locationList?.cityList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    if (e.target.value == "") {
      setCity("");
    } else {
      setCity(e.target.value);
    }
  };

  const getAllCityName = async (id, countryId, countryData, stateData) => {
    const selectedOptionObject = countryId
      ? countryId
      : locationList?.countryList?.find(
          (option) => option.value === country || option.name === country
        );
    var body = {
      country: countryId ? countryId : selectedOptionObject?.id,
      state: id || "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        if (countryId) {
          setLocationList({
            ...locationList,
            countryList: countryData || [],
            stateList: stateData || [],
            cityList: data,
          });
        } else {
          setLocationList({
            ...locationList,
            cityList: data,
          });
        }
      } else {
        setLocationList({
          ...locationList,
          cityList: [],
        });
      }
    }
  };

  const getAllStateName = async (id, stateId, countryData) => {
    var body = {
      country: id || "",
      state: "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setLocationList({
          ...locationList,
          stateList: data,
          cityList: [],
        });
        if (stateId) {
          getAllCityName(stateId, id, countryData, data);
        }
      } else {
        setLocationList({
          ...locationList,
          stateList: [],
          cityList: [],
        });
      }
    }
  };

  const getAllCountryName = async (countryID, stateId) => {
    var body = {
      country: "",
      state: "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      data = res?.results;
      setLocationList({
        ...locationList,
        countryList: data,
      });
      if (countryID) {
        getAllStateName(countryID, stateId, data);
      }
    }
  };

  const handleChooseProfile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      // uploadProfilePhoto(file, "profile");
      const detail = {
        uri: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        type: file?.type,
      };
      setSelectedFile(detail);
      setCropped(true);
    } else {
      setProfileImage(null);
    }
  };

  const uploadProfilePhoto = async (data, type) => {
    let uploadData = new FormData();
    uploadData.append("upload_type", "profile");
    uploadData.append(
      "user_id",
      userResponse?.user_id || editData?.user_data?.user_id
    );
    uploadData.append(
      "profile_id",
      userResponse?.profile_id || editData?.user_data?.profile_id
    );
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);
      setSelectedFile([]);
      setProfileImage({
        ...profileImage,
        uri: res?.results?.uri,
        name: res?.results.name,
        size: res?.results.size,
        type: res?.results?.type,
      });
    }
  };

  const removeGalleryImage = async (eachImage, type) => {
    var body = {
      user_id: userResponse?.user_id || editData?.user_data?.user_id,
      profile_id: userResponse?.profile_id || editData?.user_data?.profile_id,
      image_type: type,
      image_id: profileImage?.attachment_id,
      action: "remove",
    };
    let res = await dispatch(removeImage(body));
    if (res?.status == 200) {
      setProfileImage("");
    }
  };

  const handleNavigate = () => {
    const queryParams = new URLSearchParams({
      type: type,
    });
    if (type === "client") {
      navigate(`${routeName.CLIENT_LIST}?${queryParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate(`${routeName.PARTNER_LIST}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  };

  const handleForm = async (event) => {
    setError(true);
    event.preventDefault();
    if (!values?.firstName) {
      showToast("First Name is required", "error");
      return;
    }

    if (!values?.lastName) {
      showToast("Last Name is required", "error");
      return;
    }
    if (!values?.displayName) {
      showToast("Display Name is required", "error");
      return;
    }
    if (!values?.phoneNo) {
      showToast("Phone number is required", "error");
      return;
    }
    if (!values?.email) {
      showToast("Email is required", "error");
      return;
    }
    if (!values?.password && !id) {
      showToast("Password is required", "error");
      return;
    }
    if (!values?.organisation) {
      showToast("Organisation is required", "error");
      return;
    }
    if (!values?.businessEmail) {
      showToast("Business Email is required", "error");
      return;
    }
    if (!country) {
      showToast("Country is required", "error");
      return;
    }
    if (locationList?.stateList?.length && !state) {
      showToast("State is required", "error");
      return;
    }
    if (locationList?.cityList?.length && !city) {
      showToast("City is required", "error");
      return;
    }
    if (!values?.addressLine1) {
      showToast("Address is required", "error");
      return;
    }
    if (!values?.postalCode) {
      showToast("Postal Code is required", "error");
      return;
    }

    const form = event.currentTarget;

    if (form.checkValidity()) {
      let body = {
        state: state,
        city: city,
        pre: id ? "update" : "new",
        country: country,
        tiktok_followers: tiktokFollowers,
        tiktok_link: socialLinks?.tikTok,
        twitter_followers: twitterFollowers,
        twitter_link: socialLinks.twitter,
        vimeo_followers: vimeoFollowers,
        vimeo_link: socialLinks.vimeo,
        facebook_followers: facebookFollowers,
        facebook_link: socialLinks.facebook,
        instagram_followers: instagramFollowers,
        instagram_link: socialLinks.instagram,
        user_type: type === "client" ? "employers" : "partners",
        first_name: values?.firstName,
        last_name: values?.lastName,
        display_name: values?.displayName,
        phone_number: values?.phoneNo,
        mobile: values?.phoneNo,
        per_website: socialLinks?.website || "",
        email: values.email,
        password: values.password,
        postal_code: values.postalCode,
        address_line: values.addressLine1,
        address_line1: values.addressLine1,
        address_line2: values.addressLine2,
        tageline: values.tagline,
        title: values?.title,
        business_email: values?.businessEmail,
        business_website: values?.businessWebite,
        business_since: values?.businessYear,
        description: values?.briefDesc,
        organization: values?.organisation,
      };

      if (id) {
        body.user_id = id;
        body.post_id = editData?.user_data?.profile_id || "";
        body.profile_id = editData?.user_data?.profile_id || "";
      } else {
        body.password = values.password;
      }

      setLoading(true);
      try {
        let res = "";
        if (id) {
          if (type === "client") {
            res = await dispatch(updateClientByAdminApi(body));
          } else {
            res = await dispatch(updatePartnerByAdminApi(body));
          }
        } else {
          if (type === "client") {
            res = await dispatch(addClientByAdmin(body));
          } else {
            res = await dispatch(addPartnerByAdmin(body));
          }
        }
        setLoading(false);
        if (res?.status === 200) {
          setUserResponse(res?.results);
          if (id) {
            handleSubmit("update");
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleSubmit = async (val) => {
    let uploadData = new FormData();

    uploadData.append("upload_type", "next");
    uploadData.append(
      "user_id",
      userResponse?.user_id || editData?.user_data?.user_id
    );
    uploadData.append(
      "profile_id",
      userResponse?.profile_id || editData?.user_data?.profile_id
    );
    setLoading(true);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    setLoading(false);
    if (res?.status === 200) {
      if (val) {
        showToast("User Details Updated Successfully", "success");
      } else {
        showToast("User Added Successfully", "success");
      }
      handleNavigate();
    }
  };

  const getUserData = async () => {
    let body = {
      user_id: id,
    };
    // console.log(body, "klmmmm");
    let res = await dispatch(getUsersDataById(body));
    if (res?.status === 200) {
      setEditData(res?.results);
      let userData = res?.results?.user_data;
      let company_details = res?.results?.company_details;
      let post_meta_details = res?.results?.post_meta_details;
      let measurment_male = res?.results?.measurment_male;
      let social_followers = res?.results?.social_followers;
      let measurment = res?.results?.measurment;
      setValues({
        ...values,
        firstName: userData?.first_name || "",
        lastName: userData?.last_name || "",
        displayName: userData?.display_name || "",
        phoneNo: res?.results?.fw_option?.[0]?.user_phone_number || "",
        email: userData?.user_email || "",
        tagline: res?.results?.personal_details?.tag_line
          ? res?.results?.personal_details?.tag_line
          : post_meta_details?.tag_line,
        addressLine1:
          post_meta_details?.address_line || post_meta_details?.address || "",
        addressLine2: post_meta_details?.address_line_2 || "",
        postalCode: post_meta_details?.postal_code || "",
        tagline: company_details?.tag_line || "",
        organisation: company_details?.organization || "",
        title: company_details?.title || "",
        businessEmail: company_details?.bussiness_email_address || "",
        businessWebite: company_details?.bussiness_website || "",
        businessYear: company_details?.bussiness_since || "",
        briefDesc: company_details?.description || "",
      });
      setSocialLinks({
        ...socialLinks,
        facebook: social_followers?.facebook_profile_link,
        instagram: social_followers?.instagram_profile_link,
        tikTok: social_followers?.tiktok_profile_link
          ? social_followers?.tiktok_profile_link
          : social_followers?.ticktok_profile_link,
        youtube: social_followers?.youtube_profile_link,
        twitter: social_followers?.twitter_profile_link,
        vimeo: social_followers?.vimeo_profile_link,
        website:
          post_meta_details?.personal_website ||
          res?.results?.personal_details?.per_website,
      });
      setFacebookFollowers(social_followers?.facebook_follower);
      setInstagramFollowers(social_followers?.instagram_follower);
      setYoutubeFollowers(social_followers?.youtube_follower);
      setTwitterFollowers(social_followers?.twitter_follower);
      setTiktokFollowers(social_followers?.tiktok_follower);
      setVimeoFollowers(social_followers?.vimeo_follower);

      getAllCountryName(
        post_meta_details?.country_id,
        post_meta_details?.state_id
      );

      setCountry(post_meta_details?.country);
      setState(post_meta_details?.state);
      setCity(post_meta_details?.city);
      setProfileImage({
        ...profileImage,
        uri: res?.results?.profile_image?.[0]?.guid,
        attachment_id: res?.results?.profile_image?.[0]?.ID,
      });
      setVideoLink(post_meta_details?.video_link || "");
    }
  };

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      getAllCountryName();
    }
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div className="pd-30">
        <div className="verification_contain_form wt-email-cont">
          <div>
            <Form onSubmit={handleForm}>
              <div className="form-section">
                <div className="form-section-title">Client Basics</div>
                <ClientBasics
                  error={error}
                  values={values}
                  setValues={setValues}
                  id={id}
                  type={type}
                />
                <div className="form-section">
                  <div className="form-section-title">
                    Add Brief Description
                  </div>
                  <div class="form-group form-group-50">
                    <InputField
                      // required
                      title="Brief Description"
                      type="textArea"
                      placeholder="Add Description"
                      value={values.briefDesc}
                      onChangeText={(e) => {
                        setValues({
                          ...values,
                          briefDesc: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-section-title">Company Information</div>
                <CompanyInfo
                  error={error}
                  values={values}
                  setValues={setValues}
                  country={country}
                  state={state}
                  city={city}
                  setCountry={setCountry}
                  setCity={setCity}
                  setState={setState}
                  setLocationList={setLocationList}
                  locationList={locationList}
                  handleSelectCity={handleSelectCity}
                  handleSelectCountry={handleSelectCountry}
                  handleSelectState={handleSelectState}
                />
                <SocialMediaHandle
                  socialLinks={socialLinks}
                  setSocialLinks={setSocialLinks}
                  talent={"client"}
                  setFacebookFollowers={setFacebookFollowers}
                  facebookFollowers={facebookFollowers}
                  setVideoLink={setVideoLink}
                  videoLink={videoLink}
                  setInstagramFollowers={setInstagramFollowers}
                  instagramFollowers={instagramFollowers}
                  setTiktokFollowers={setTiktokFollowers}
                  tiktokFollowers={tiktokFollowers}
                  vimeoFollowers={vimeoFollowers}
                  setVimeoFollowers={setVimeoFollowers}
                  twitterFollowers={twitterFollowers}
                  setTwitterFollowers={setTwitterFollowers}
                  youtubeFollowers={youtubeFollowers}
                  setYoutubeFollowers={setYoutubeFollowers}
                  reelLink={reelLink}
                  setReelLink={setReelLink}
                />
              </div>
              {id || userResponse ? undefined : (
                <button
                  onClick={handleForm}
                  class="wt-btn hide_org"
                  type="submit"
                >
                  Save
                </button>
              )}
            </Form>
            {userResponse || editData ? (
              <>
                <div class="form-section">
                  <div className="form-section-title">Profile Photo</div>
                  <div class="wt-bannerphoto wt-tabsinfo">
                    <div class="wt-profilephotocontent">
                      <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                        <fieldset>
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="filep">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filep"
                                  onChange={handleChooseProfile}
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {selectedFile && (
                            <ImageCropper
                              selectedFile={selectedFile}
                              setSelectedFile={setSelectedFile}
                              imageSrc={selectedFile?.uri}
                              type={"profile"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={profileImage}
                              setProfileImage={setProfileImage}
                              onCrop={uploadProfilePhoto}
                            />
                          )}
                          {profileImage && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={
                                            profileImage?.url
                                              ? profileImage?.url
                                              : profileImage?.uri
                                          }
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{profileImage?.name}</span>
                                      <em>
                                        {profileImage?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(profileImage?.size)}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            removeGalleryImage(
                                              profileImage,
                                              "profile"
                                            )
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
                <button
                  onClick={(e) => {
                    if (!profileImage) {
                      showToast("Profile Image is compulsory");
                      return;
                    }
                    if (id) {
                      handleForm(e);
                    } else {
                      handleSubmit();
                    }
                  }}
                  class="wt-btn hide_org"
                  type="submit"
                >
                  Submit
                </button>
              </>
            ) : undefined}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default AddEditClient;
