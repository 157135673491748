import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, { useState } from "react";
  import { Table } from "react-bootstrap";
  import Button from "react-bootstrap/Button";
  import Modal from "react-bootstrap/Modal";
  
  export const EmailListPartner = (props) => {
    const { show, setShow, listData, handleSubmitList } = props;
  
    const data = [];
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Submitted Emails List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableContainer className="table_container">
            <div className="heading"></div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Emails</TableCell>
                  <TableCell align="center">Phone</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              {listData?.length ? (
                listData?.map((item, i) => {
                  return (
                    <TableBody>
                      <TableRow>
                      <TableCell align="center">{i+1}</TableCell>
                      <TableCell align="center">{item?.user_name || ""}</TableCell>
                      <TableCell align="center">{item?.user_email || ""}</TableCell>
                      <TableCell align="center">{item?.user_phone || ""}</TableCell>
                      <TableCell align="center">{item?.status !== "false" ? "Already Registered" : "Not Exist"}</TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={10} sx={{ color: "black" }}>
                    No Emails Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Modal.Body>
        <Modal.Footer className="footer-center">
        
        <Button
          onClick={() => {
            handleSubmitList();
            setShow(false);
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
      </Modal>
    );
  };
  