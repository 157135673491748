import {
  NAVIGATOR_STATUS,
  USER_LOGIN_ERROR,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  REGISTER_TOKEN_ERROR,
  REGISTER_TOKEN_LOADING,
  REGISTER_TOKEN_SUCCESS,
  USER_LOGOUT_ERROR,
  USER_LOGOUT_LOADING,
  USER_LOGOUT_SUCCESS,
  USER_REGISTER_ERROR,
  USER_REGISTER_LOADING,
  USER_REGISTER_SUCCESS,
  PHOTOGRAPHER_REGISTER_LOADING,
  PHOTOGRAPHER_REGISTER_SUCCESS,
  PHOTOGRAPHER_REGISTER_ERROR,
  UPDATE_PHOTOGRAPHER_PROFILE_LOADING,
  UPDATE_PHOTOGRAPHER_PROFILE_SUCCESS,
  UPDATE_PHOTOGRAPHER_PROFILE_ERROR,
  PROFILE_SETUP_LOADING,
  PROFILE_SETUP_SUCCESS,
  PROFILE_SETUP_ERROR,
  GET_OPTIONS_DATA_LOADING,
  GET_OPTIONS_DATA_SUCCESS,
  GET_OPTIONS_DATA_ERROR,
  GET_USER_DETAIL_LOADING,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERROR,
  UPLOAD_GALLERY_PROFILE_LOADING,
  UPLOAD_GALLERY_PROFILE_SUCCESS,
  UPLOAD_GALLERY_PROFILE_ERROR,
  GET_PACKAGES_LOADING,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_ERROR,
  BUY_SUBSCRIPTION_LOADING,
  BUY_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION_ERROR,
  SEND_VARIFICATION_LOADING,
  SEND_VARIFICATION_SUCCESS,
  SEND_VARIFICATION_ERROR,
  SEND_VARIFICATION_EMAIL_LOADING,
  SEND_VARIFICATION_EMAIL_SUCCESS,
  SEND_VARIFICATION_EMAIL_ERROR,
  OTP_VERIFICATION_LOADING,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FORGET_PASSWORD_LOADING,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  IDENTITY_VERIFICATION_LOADING,
  IDENTITY_VERIFICATION_SUCCESS,
  IDENTITY_VERIFICATION_ERROR,
  REMOVE_IMAGE_LOADING,
  REMOVE_IMAGE_SUCCESS,
  REMOVE_IMAGE_ERROR,
  LOGIN_USER_DATA,
  LOGIN_STEP_DATA,
  REGISTER_DATA,
  LOGIN_REDIRECT_LOADING,
  LOGIN_REDIRECT_SUCCESS,
  LOGIN_REDIRECT_ERROR,
  GET_IDENTITY_VERIFICATION_LOADING,
  GET_IDENTITY_VERIFICATION_SUCCESS,
  GET_IDENTITY_VERIFICATION_ERROR,
  REJECT_IDENTITY_VERIFICATION_LOADING,
  REJECT_IDENTITY_VERIFICATION_SUCCESS,
  REJECT_IDENTITY_VERIFICATION_ERROR,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_LOADING,
} from "../Consts/AuthConsts";

export const navigatorStatus = (status, splash, success) => ({
  type: NAVIGATOR_STATUS,
  navigator: status,
  splash: splash,
  success: success,
});

export const userLoginLoading = (isLoading) => ({
  type: USER_LOGIN_LOADING,
  isLoading: isLoading,
});
export const userLoginSuccess = (isLoading) => ({
  type: USER_LOGIN_SUCCESS,
  isLoading: isLoading,
});
export const userLoginError = (isLoading) => ({
  type: USER_LOGIN_ERROR,
  isLoading: isLoading,
});

export const registerTokenLoading = (isLoading) => ({
  type: REGISTER_TOKEN_LOADING,
  isLoading: isLoading,
});
export const registerTokenSuccess = (isLoading) => ({
  type: REGISTER_TOKEN_SUCCESS,
  isLoading: isLoading,
});
export const registerTokenError = (isLoading) => ({
  type: REGISTER_TOKEN_ERROR,
  isLoading: isLoading,
});

export const userRegisterLoading = (isLoading) => ({
  type: USER_REGISTER_LOADING,
  isLoading: isLoading,
});
export const userRegisterSuccess = (isLoading, data) => ({
  type: USER_REGISTER_SUCCESS,
  isLoading: isLoading,
  registrationData: data,
});
export const userRegisterError = (isLoading) => ({
  type: USER_REGISTER_ERROR,
  isLoading: isLoading,
});

export const profileSetupLoading = (isLoading) => ({
  type: PROFILE_SETUP_LOADING,
  isLoading: isLoading,
});
export const profileSetupSuccess = (isLoading) => ({
  type: PROFILE_SETUP_SUCCESS,
  isLoading: isLoading,
});
export const profileSetupError = (isLoading) => ({
  type: PROFILE_SETUP_ERROR,
  isLoading: isLoading,
});

export const photographerRegisterLoading = (isLoading) => ({
  type: PHOTOGRAPHER_REGISTER_LOADING,
  isLoading: isLoading,
});
export const photographerRegisterSuccess = (isLoading, data) => ({
  type: PHOTOGRAPHER_REGISTER_SUCCESS,
  isLoading: isLoading,
  registrationData: data,
});
export const photographerRegisterError = (isLoading) => ({
  type: PHOTOGRAPHER_REGISTER_ERROR,
  isLoading: isLoading,
});

export const updatePhotographerProfileLoading = (isLoading) => ({
  type: UPDATE_PHOTOGRAPHER_PROFILE_LOADING,
  isLoading: isLoading,
});
export const updatePhotographerProfileSuccess = (isLoading) => ({
  type: UPDATE_PHOTOGRAPHER_PROFILE_SUCCESS,
  isLoading: isLoading,
});
export const updatePhotographerProfileError = (isLoading) => ({
  type: UPDATE_PHOTOGRAPHER_PROFILE_ERROR,
  isLoading: isLoading,
});

export const userLogoutLoading = (isLoading) => ({
  type: USER_LOGOUT_LOADING,
  isLoading: isLoading,
});
export const userLogoutSuccess = (isLoading) => ({
  type: USER_LOGOUT_SUCCESS,
  isLoading: isLoading,
});
export const userLogoutError = (isLoading) => ({
  type: USER_LOGOUT_ERROR,
  isLoading: isLoading,
});

export const getOptionsDataLoading = (isLoading) => ({
  type: GET_OPTIONS_DATA_LOADING,
  isLoading: isLoading,
});
export const getOptionsDataSuccess = (isLoading, data) => ({
  type: GET_OPTIONS_DATA_SUCCESS,
  isLoading: isLoading,
  allOptionData: data,
});
export const getOptionsDataError = (isLoading) => ({
  type: GET_OPTIONS_DATA_ERROR,
  isLoading: isLoading,
});

export const getUserDetailLoading = (isLoading) => ({
  type: GET_USER_DETAIL_LOADING,
  isLoading: isLoading,
});
export const getUserDetailSuccess = (isLoading, data) => ({
  type: GET_USER_DETAIL_SUCCESS,
  isLoading: isLoading,
  // allOptionData: data,
  userData: data,
});
export const getUserDetailError = (isLoading) => ({
  type: GET_USER_DETAIL_ERROR,
  isLoading: isLoading,
});

export const uploadGalleryProfileLoading = (isLoading) => ({
  type: UPLOAD_GALLERY_PROFILE_LOADING,
  isLoading: isLoading,
});
export const uploadGalleryProfileSuccess = (isLoading, data) => ({
  type: UPLOAD_GALLERY_PROFILE_SUCCESS,
  isLoading: isLoading,
});
export const uploadGalleryProfileError = (isLoading) => ({
  type: UPLOAD_GALLERY_PROFILE_ERROR,
  isLoading: isLoading,
});

export const getPackagesLoading = (isLoading) => ({
  type: GET_PACKAGES_LOADING,
  isLoading: isLoading,
});
export const getPackagesSuccess = (isLoading, data) => ({
  type: GET_PACKAGES_SUCCESS,
  isLoading: isLoading,
});
export const getPackagesError = (isLoading) => ({
  type: GET_PACKAGES_ERROR,
  isLoading: isLoading,
});

export const buySubscriptionLoading = (isLoading) => ({
  type: BUY_SUBSCRIPTION_LOADING,
  isLoading: isLoading,
});
export const buySubscriptionSuccess = (isLoading, data) => ({
  type: BUY_SUBSCRIPTION_SUCCESS,
  isLoading: isLoading,
});
export const buySubscriptionError = (isLoading) => ({
  type: BUY_SUBSCRIPTION_ERROR,
  isLoading: isLoading,
});

export const sendVerficationLoading = (isLoading) => ({
  type: SEND_VARIFICATION_LOADING,
  isLoading: isLoading,
});
export const sendVerficationSuccess = (isLoading, data) => ({
  type: SEND_VARIFICATION_SUCCESS,
  isLoading: isLoading,
});
export const sendVerficationError = (isLoading) => ({
  type: SEND_VARIFICATION_ERROR,
  isLoading: isLoading,
});

export const sendVerficationEmailLoading = (isLoading) => ({
  type: SEND_VARIFICATION_EMAIL_LOADING,
  isLoading: isLoading,
});
export const sendVerficationEmailSuccess = (isLoading, data) => ({
  type: SEND_VARIFICATION_EMAIL_SUCCESS,
  isLoading: isLoading,
  verificationData: data,
});
export const sendVerficationEmailError = (isLoading) => ({
  type: SEND_VARIFICATION_EMAIL_ERROR,
  isLoading: isLoading,
});

export const otpVerifyLoading = (isLoading) => ({
  type: OTP_VERIFICATION_LOADING,
  isLoading: isLoading,
});
export const otpVerifySuccess = (isLoading, data) => ({
  type: OTP_VERIFICATION_SUCCESS,
  isLoading: isLoading,
});
export const otpVerifyError = (isLoading) => ({
  type: OTP_VERIFICATION_ERROR,
  isLoading: isLoading,
});

export const resetPasswordLoading = (isLoading) => ({
  type: RESET_PASSWORD_LOADING,
  isLoading: isLoading,
});
export const resetPasswordSuccess = (isLoading) => ({
  type: RESET_PASSWORD_SUCCESS,
  isLoading: isLoading,
});
export const resetPasswordError = (isLoading) => ({
  type: RESET_PASSWORD_ERROR,
  isLoading: isLoading,
});

export const forgetPasswordLoading = (isLoading) => ({
  type: FORGET_PASSWORD_LOADING,
  isLoading: isLoading,
});
export const forgetPasswordSuccess = (isLoading) => ({
  type: FORGET_PASSWORD_SUCCESS,
  isLoading: isLoading,
});
export const forgetPasswordError = (isLoading) => ({
  type: FORGET_PASSWORD_ERROR,
  isLoading: isLoading,
});

export const identityVerificationLoading = (isLoading) => ({
  type: IDENTITY_VERIFICATION_LOADING,
  isLoading: isLoading,
});
export const identityVerificationSuccess = (isLoading) => ({
  type: IDENTITY_VERIFICATION_SUCCESS,
  isLoading: isLoading,
});
export const identityVerificationError = (isLoading) => ({
  type: IDENTITY_VERIFICATION_ERROR,
  isLoading: isLoading,
});

export const getIdentityVerifyLoading = (isLoading) => ({
  type: GET_IDENTITY_VERIFICATION_LOADING,
  isLoading: isLoading,
});
export const getIdentityVerifySuccess = (isLoading) => ({
  type: GET_IDENTITY_VERIFICATION_SUCCESS,
  isLoading: isLoading,
});
export const getIdentityVerifyError = (isLoading) => ({
  type: GET_IDENTITY_VERIFICATION_ERROR,
  isLoading: isLoading,
});

export const rejectIdentityVerifyLoading = (isLoading) => ({
  type: REJECT_IDENTITY_VERIFICATION_LOADING,
  isLoading: isLoading,
});
export const rejectIdentityVerifySuccess = (isLoading) => ({
  type: REJECT_IDENTITY_VERIFICATION_SUCCESS,
  isLoading: isLoading,
});
export const rejectIdentityVerifyError = (isLoading) => ({
  type: REJECT_IDENTITY_VERIFICATION_ERROR,
  isLoading: isLoading,
});

export const removeImageLoading = (isLoading) => ({
  type: REMOVE_IMAGE_LOADING,
  isLoading: isLoading,
});
export const removeImageSuccess = (isLoading) => ({
  type: REMOVE_IMAGE_SUCCESS,
  isLoading: isLoading,
});
export const removeImageError = (isLoading) => ({
  type: REMOVE_IMAGE_ERROR,
  isLoading: isLoading,
});

export const loggedInUsedAction = (data) => ({
  type: LOGIN_USER_DATA,
  data
});

export const loginStepUserData = (data) => ({
  type: LOGIN_STEP_DATA,
  data
});

export const registerFieldsAction = (data) => ({
  type: REGISTER_DATA,
  data
});

export const loginRedirectLoading = (isLoading) => ({
  type: LOGIN_REDIRECT_LOADING,
  isLoading: isLoading,
});
export const loginRedirectSuccess = (isLoading) => ({
  type: LOGIN_REDIRECT_SUCCESS,
  isLoading: isLoading,
});
export const loginRedirectError = (isLoading) => ({
  type: LOGIN_REDIRECT_ERROR,
  isLoading: isLoading,
});

export const verifyOtpLoading = (isLoading) => ({
  type: VERIFY_OTP_LOADING,
  isLoading: isLoading,
});
export const verifyOtpSuccess = (isLoading) => ({
  type: VERIFY_OTP_SUCCESS,
  isLoading: isLoading,
});
export const verifyOtpError = (isLoading) => ({
  type: VERIFY_OTP_ERROR,
  isLoading: isLoading,
});
