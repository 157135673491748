import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import { Colors, Fonts, Images, Sizes } from "../Constants";
import { TextComponent } from "./TextComponent";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import moment from "moment";

export const InputField = (props) => {
  const {
    title,
    type,
    placeholder,
    required,
    value,
    onChangeText,
    isValid,
    tooltip,
    maxLength,
    keyboardType,
    icon,
    arrow,
    span,
    fontIcon,
    error,
    isEmpty,
    toolTipText,
    ref,
    disabled,
    talent,
    page,
  } = props;
  const today = moment();
  const newToday = moment();
  const currentDate = new Date();
  const pastDate = new Date(currentDate);
  const minDate = pastDate.setFullYear(currentDate.getFullYear() - 13);
  const maxDate = pastDate.setFullYear(currentDate.getFullYear() - 14);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const talentMinDate = newToday.subtract(13, "years");
  return (
    <>
      <Form.Group>
        {toolTipText ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${toolTipText}`}>{toolTipText}</Tooltip>
            }
          >
            {title && (
              <Form.Label>
                {required && <span className="astrik_req">*</span>}
                {title}
                <span className="tooltipOverlay">
                  <i class="fa fa-question-circle" aria-hidden="true"></i>
                </span>
              </Form.Label>
            )}
          </OverlayTrigger>
        ) : (
          <Form.Label>
            {required && title && <span className="astrik_req">*</span>} {title}
          </Form.Label>
        )}

        {type == "textarea" ? (
          <Form.Control
            {...props}
            as={"textarea"}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChangeText}
            isInvalid={value && isValid}
            isValid={value && !isValid}
            disabled={disabled}
            className={isValid ? "required_inpt" : ""}
          />
        ) : (
          <Form.Control
            {...props}
            type={
              type === "password"
                ? passwordVisible
                  ? "text"
                  : "password"
                : type
            }
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChangeText}
            isInvalid={value && isValid}
            isValid={value && !isValid}
            disabled={disabled}
            
            max={
              (type == "date" && talent == "Model") ||
              (type == "date" && talent == "model") ||
              (type == "date" && talent == "Actor") || 
              (type == "date" && talent == "Photographer")
                ? moment(new Date(maxDate)).format("YYYY-MM-DD")
                : (type == "date" && talent == "Model Kid") ||
                  (type == "date" && talent == "Child") ||
                  (type == "date" && talent == "Actor Kid")
                ? moment(new Date()).format("YYYY-MM-DD")
                : props?.max
                ? props?.max
                : null
            }
            min={
              (type == "date" && talent == "Model Kid") ||
              (type == "date" && talent == "kid") ||
              (type == "date" && talent == "Actor Kid")
                ? moment(new Date(minDate)).format("YYYY-MM-DD")
                : props?.min
                ? props?.min
                : null
            }
            className={isValid ? "required_inpt" : ""}
          />
        )}

        {isValid && (page !== 'auth') ? (
          <span
            // className="invalid-feedback"
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : undefined}

        {(page === 'auth') && isValid && required && value?.length == 0 ? (
          <Form.Control.Feedback type={"invalid"}>
            This field is required !
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback className={type === "password" ? "pass_input" : ""} type={"invalid"}>
            {type == "email"
              ? "Please enter a valid email"
              : type === "phone"
              ? "Please enter valid phone number."
              : type === "numeric"
              ? "Please enter valid input."
              : type === "password"
              ? `The password must contain at least 8 character categories: Uppercase characters (A-Z), Lowercase characters (a-z), Digits (0-9), special characters`
              : type === "confirmPassword"
              ? "Both password should match."
              : "Input not valid !"}
          </Form.Control.Feedback>
        )}

        {type === "password" && (
          <span
            className={page === "auth" ? "eye_icn" : "input_icon"}
            onClick={togglePasswordVisibility}
          >
            <i
              class={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}
              aria-hidden="true"
            ></i>
          </span>
        )}

        {icon && (
          <span className="input_icon">
            <i class={`fa fa-${icon}`} aria-hidden="true"></i>
          </span>
        )}
      </Form.Group>
    </>
  );
};
