import React from "react";
import {
  DropdownList,
  InputField,
  MultiSelectDropdown,
} from "../../Components";
import { expertises, languages } from "../../Global";
import { useSelector } from "react-redux";

const SingleFields = (props) => {
  const {
    setSelectedLang,
    selectedLang,
    error,
    setEngLang,
    engLang,
    values,
    setValues,
    setEthinicity,
    ethinicity,
    height,
    weight,
    setHeight,
    setWeight,
    hairColor,
    setHairColor,
    eyeColor,
    setEyeColor,
    experienceLevel,
    setExperienceLevel,
    talent,
    setTattoLoc,
    tattooLoc,
    memberOf,
    setMemberOf,
    theaterExp,
    setTheaterExp,
    awards,
    setAwards,
    commercialExp,
    setCommercialExp,
    educationTraining,
    setEducationTraining,
    setExpertise,
    expertise,
  } = props;

  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  return (
    <div>
      {talent !== "Photographer" ? (
        <>
          <div className="form-section">
            <div className="form-section-title">Languages you can speak</div>
            <div class="form-group form-group-50">
              <MultiSelectDropdown
                required
                title="Select Languages"
                options={
                  optionsType?.languages?.length
                    ? optionsType?.languages?.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))
                    : []
                }
                value={selectedLang}
                setValue={setSelectedLang}
                isValid={error && selectedLang?.length == 0}
              />
            </div>
          </div>
          <div className="form-section">
            <div className="form-section-title">Your English Level</div>
            <div className="form-group form-group-50">
              <DropdownList
                required
                title="Language Level"
                placeholder="Select Language Level"
                options={optionsType?.englishLevel}
                value={engLang}
                setValue={setEngLang}
                isValid={error && !engLang}
              />
            </div>
          </div>
        </>
      ) : undefined}
      <div className="form-section">
        <div className="form-section-title">Personal Details</div>
        <div class="form-group form-group-50">
          <InputField
            required
            title="Professional Bio"
            type="textarea"
            placeholder="Professional Bio"
            value={values?.professionalBio}
            onChangeText={(e) => {
              setValues({
                ...values,
                professionalBio: e?.target?.value,
              });
            }}
            isValid={error && !values?.professionalBio}
          />
          <InputField
            required
            title="Personal bio"
            type="textarea"
            placeholder="Personal bio"
            value={values?.personalBio}
            onChangeText={(e) => {
              setValues({
                ...values,
                personalBio: e?.target?.value,
              });
            }}
            isValid={error && !values?.personalBio}
          />

          {talent === "Photographer" ? (
            <>
              <MultiSelectDropdown
                required
                title="Select Expertise"
                options={expertises}
                value={expertise}
                setValue={setExpertise}
                isValid={error && !expertise?.length}
              />
              <InputField
                title="Custom Expertise"
                type="text"
                value={values?.customExpertise}
                placeholder="Eg. Baseball, Football etc"
                onChangeText={(e) => {
                  setValues({
                    ...values,
                    customExpertise: e?.target?.value,
                  });
                }}
              />
            </>
          ) : (
            <>
              <DropdownList
                required
                title="Height (Inches)"
                placeholder="Select Height"
                options={optionsType?.otherOptions?.height?.field_meta?.choices}
                value={height}
                setValue={setHeight}
                isValid={error && !height}
              />
              <DropdownList
                required
                title="Weight (Pounds)"
                placeholder="Select Weight"
                options={optionsType?.otherOptions?.weight?.field_meta?.choices}
                value={weight}
                setValue={setWeight}
                isValid={error && !weight}
              />
              <MultiSelectDropdown
                required
                title="Select Ethnicity"
                placeholder="Select Ethnicity"
                options={
                  optionsType?.otherOptions?.ethnicity?.field_meta?.choices
                }
                value={ethinicity}
                setValue={setEthinicity}
                isValid={error && ethinicity?.length == 0}
              />
              <DropdownList
                required
                title="Hair color"
                placeholder="Select Hair color"
                options={
                  optionsType?.otherOptions?.hair_colour?.field_meta?.choices
                }
                value={hairColor}
                setValue={setHairColor}
                isValid={error && !hairColor}
              />
              <DropdownList
                required
                title="Eye color"
                placeholder="Select Eye color"
                options={
                  optionsType?.otherOptions?.eye_color?.field_meta?.choices
                }
                value={eyeColor}
                setValue={setEyeColor}
                isValid={error && !eyeColor}
              />
            </>
          )}

          <DropdownList
            required
            title="Experience level"
            placeholder="Select Experience level"
            options={
              optionsType?.otherOptions?.experience_level?.field_meta?.choices
            }
            value={experienceLevel}
            setValue={setExperienceLevel}
            isValid={error && !experienceLevel}
          />

          {talent === "Actor" ? (
            <>
              <InputField
                // required
                title="Tattoo/Design/Location"
                type="text"
                placeholder="Add Tattoo/Design/Location."
                value={tattooLoc}
                onChangeText={(e) => {
                  setTattoLoc(e?.target?.value);
                }}
              />
            </>
          ) : undefined}

          {talent === "Actor" || talent === "Actor Kid" ? (
            <>
              <InputField
                // required
                title="Member of any union"
                type="text"
                placeholder="Add Member of any union eg. SAG/AFTRA"
                value={memberOf}
                onChangeText={(e) => {
                  setMemberOf(e?.target?.value);
                }}
              />

              <InputField
                title="Education or Training"
                type="textarea"
                placeholder="Education or Training"
                value={educationTraining}
                onChangeText={(e) => {
                  setEducationTraining(e?.target?.value);
                }}
              />

              <InputField
                title="Theater experience"
                type="textarea"
                placeholder="Theater experience"
                value={theaterExp}
                onChangeText={(e) => {
                  setTheaterExp(e?.target?.value);
                }}
              />
              <InputField
                title="Awards"
                type="textarea"
                placeholder="Awards"
                value={awards}
                onChangeText={(e) => {
                  setAwards(e?.target?.value);
                }}
              />

              <InputField
                title="Commercial experience"
                type="textarea"
                placeholder="Commercial experience"
                value={commercialExp}
                onChangeText={(e) => {
                  setCommercialExp(e?.target?.value);
                }}
              />
            </>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default SingleFields;
