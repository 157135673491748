/* eslint-disable react-hooks/exhaustive-deps */
import { Box, InputAdornment, TextField } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import { Dispatch, SetStateAction, useEffect } from "react";
// import ClearIcon from "@mui/icons-material/Clear";

const SearchBar = ({
  value,
  onChange,
  onCross,
  setDebouncedSearchTerm,
  searchTerm,
}) => {
  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);
  return (
    <Box className="search_bar">
      <TextField
        value={value}
        onChange={onChange}
        hiddenLabel
        placeholder="Search here"
        className="txt_inpt"
        fullWidth
        variant="outlined"
        style={{border: 'none'}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="search_icon">
            <i class="fa fa-search" aria-hidden="true"></i>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" className="cross_btn">
              {value ? <i class="fa fa-times" aria-hidden="true" onClick={onCross} /> : null}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
