import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getSubscribedPackageUser } from "../../../Redux/Services/AdminServices";
import SidebarLayout from "../../../layout/SidebarLayout";
import { Loader } from "../../../Components";
import { SitePagination } from "../../../Components/filtersComponents";
import {
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { OverlayTrigger } from "react-bootstrap";
import SearchBar from "../../../Components/SearchBar";
import { isValidInput } from "../../../Utility/validations";
import { routeName } from "../../../Utility";
import { Images } from "../../../Constants";

const PackageSubscribers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  const name = queryParams?.get("name");
  const price = queryParams?.get("price");

  const [subscribedUsers, setSubscribedUsers] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  let totalPages = Math.ceil(totalCount / 20);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const getAllSubscribers = async () => {
    setLoading(true);
    let body = {
      product_id: id,
      search: debouncedSearchTerm,
      page_number: page,
      per_page: "20",
    };
    let res = await dispatch(getSubscribedPackageUser(body));
    setLoading(false);
    if (res?.status == 200) {
      setSubscribedUsers(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setSubscribedUsers([]);
    }
  };

  useEffect(() => {
    getAllSubscribers();
  }, [debouncedSearchTerm, page]);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div>
        <Container>
          <div className="list_cnnt">
            <h3>{`Subscribed Users`}</h3>

            <a
              class="wt-btn"
              onClick={() =>
                navigate(routeName.MANGE_PACKAGE, { replace: true })
              }
            >
              {`Back`}
            </a>
          </div>
        </Container>
        <div className="dashboard_main">
          <div className="myJobs adminData">
            <div class="wt-managejobcontent">
              <div className="cards">
                <div className="cards_header">
                  <div />

                  <SearchBar
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </div>
                <TableContainer className="table_container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Profile</TableCell>
                        <TableCell>Registration Date</TableCell>
                        <TableCell>Membership Plan</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Membership Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {subscribedUsers?.length ? (
                      subscribedUsers?.map((item, i) => {
                        return (
                          <TableBody key={i}>
                            <TableRow>
                              <TableCell>
                                <div
                                  onClick={() => {
                                    const queryParams = new URLSearchParams({
                                      id: item?.post_meta_details?.user_id,
                                    });
                                    navigate(
                                      `${
                                        routeName.TALENT_PROFILE
                                      }?${queryParams.toString()}`
                                    );
                                  }}
                                  className="userImgName"
                                >
                                  <img
                                    alt="profile"
                                    src={item?.profile_image || Images.user}
                                  />
                                  {`${item?.profile?.post_title || "-"}` || ""}
                                </div>
                              </TableCell>
                              <TableCell>
                                {item?.profile?.post_date?.slice(0, 10) || "-"}
                              </TableCell>
                              <TableCell>{name || "-"}</TableCell>
                              <TableCell>
                                {item?.post_meta_details?.user_email || ""}
                              </TableCell>

                              <TableCell>
                                {item?.post_meta_details?.phone_number || ""}
                              </TableCell>
                              <TableCell>{price || "$0"}</TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    ) : (
                      <TableBody>
                        <TableCell
                          align="center"
                          colSpan={10}
                          sx={{ color: "black" }}
                        >
                          No Users Found
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div className="pg_vew">
                  {totalPages > 1 ? (
                    <SitePagination
                      module={subscribedUsers}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      setPage={setPage}
                    />
                  ) : undefined}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default PackageSubscribers;
