import React from "react";
import Select, { components } from "react-select";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const MultiSelectDropdown = (props) => {
  const {
    options,
    setValue,
    value,
    tooltip,
    required,
    title,
    disabled,
    isValid,
    property
  } = props;

  const handleChange = (selectedValues) => {
    if (property) {
      setValue((prevState) => ({
        ...prevState,
        [property]: selectedValues,
      }));
    } else {

      setValue(selectedValues);
    }
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div>
          {props.data.name}
        </div>
      </components.Option>
    );
  };
  return (
    <Form.Group>
      {tooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">This is a tooltip</Tooltip>}
        >
          <Form.Label className="ontop">
            {required && <span className="astrik_req">*</span>}
            {title}
            <span className="tooltipOverlay">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </span>
          </Form.Label>
        </OverlayTrigger>
      ) : property ? undefined : (
        <Form.Label className="ontop">
          {required && <span className="astrik_req">*</span>} {title}
        </Form.Label>
      )}

      <Select
        isMulti
        options={options}
        value={value}
        onChange={handleChange}
        isDisabled={disabled}
        required={required}
        className={isValid ? "border_red" : "border_grey"}

      />
      {isValid ? (
        <span
          style={{
            color: "#dc3545",
            fontSize: 11,
            position: "absolute",
            bottom: -20,
          }}
        >
          This field is required !
        </span>
      ) : null}
    </Form.Group>
  );
};
