import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName, showToast } from "../../../Utility";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../../layout/SidebarLayout";
import {
  AdminTable,
  DeletePost,
  Loader,
  ReportedPosts,
} from "../../../Components";
import {
  AdminResendPassword,
  DeleteReason,
  IdentityRequest,
  RejectionReason,
  ReportedUsersModal,
  ViewIdentity,
  ViewProjects,
} from "../../../Components/Popups";
import {
  acceptUserIdApi,
  adminResetPasswordApi,
  hideShowReportedApi,
  profileApproveDeclineApi,
  rejectUserIdApi,
  reportedContentApi,
} from "../../../Redux/Services/AdminServices";
import {
  deleteJob,
  deletePost,
  getAccountSettingDetails,
} from "../../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../../Utility/Storage";

const ReportedUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get("type");
  const tabs = queryParams?.get("tab");

  const [tab, setTab] = useState(tabs || 1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [isProjectModal, setIsProjectModal] = useState(false);
  const [isIdentityModal, setIsIdentityModal] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isResendModal, setIsResendModal] = useState(false);
  const [isIdentityViewModal, setIsIdentityViewModal] = useState(false);
  const [password, setPassword] = useState("");
  const [isRejectionProfile, setIsRejectionProfile] = useState(false);
  const [isRejectionIdentity, setIsRejectionIdentity] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [isDeleteReason, setIsDeleteReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [isReportModals, setIsReportModals] = useState(false);
  const [reportedData, setReportedData] = useState([]);
  const [hideShowModal, setHideShowModal] = useState(false);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  let totalPages = Math.ceil(totalCount / 20);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const options = [
    {
      name: "Reported Users",
      type: 1,
    },
    {
      name: "Reported Portfolio",
      type: 2,
    },
    {
      name: "Reported Social Post",
      type: 3,
    },
    {
      name: "Reported Jobs",
      type: 4,
    },
  ];

  const getAllModelsList = async (pages, type) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
      report_type:
        Number(type) === 1
          ? "freelancer"
          : Number(type) === 2
          ? "portfolio"
          : Number(type) === 3
          ? "social_post"
          : "project",
    };
    let res = await dispatch(reportedContentApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.list);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const handleGetFunctions = (type, pages) => {
    getAllModelsList(pages, type);
  };

  const handleDeleteModel = async () => {
    let body = {
      user_id:
        Number(tab) === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      profile_id: selectedId?.profile?.ID,
      reason: selectedReason,
      description: rejectionReason,
      action: "delete",
    };
    setLoading(true);

    let res = await dispatch(getAccountSettingDetails(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsDeleteModal(false);
      setIsDeleteReason(false);
      setRejectionReason("");
      setSelectedReason("");
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleGeneratePassowrd = () => {
    const specialSymbols = "!@#$%()";
    const capitalLetters = "ABCDEFGHJKPQRSTUXYZ";
    const smallLetters = "abcdefghjkopqrstuxyz";
    const numbers = "23456789";

    let password2 = "";

    password2 += specialSymbols.charAt(
      Math.floor(Math.random() * specialSymbols.length)
    );
    password2 += capitalLetters.charAt(
      Math.floor(Math.random() * capitalLetters.length)
    );
    password2 += smallLetters.charAt(
      Math.floor(Math.random() * smallLetters.length)
    );
    password2 += numbers.charAt(Math.floor(Math.random() * numbers.length));

    const allCharacters =
      specialSymbols + capitalLetters + smallLetters + numbers;
    for (let i = 0; i < 4; i++) {
      password2 += allCharacters.charAt(
        Math.floor(Math.random() * allCharacters.length)
      );
    }

    password2 = password2
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    console.log(password2);
    setPassword(password2);
    // return password;
  };

  const handleResendPassword = async () => {
    if (!password) {
      showToast("Please generate password first", "error");
      return;
    }
    setLoading(true);
    let body = {
      user_id:
        Number(tab) === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      password,
    };
    let res = await dispatch(adminResetPasswordApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsResendModal(false);
    } else {
    }
  };

  const handleAcceptVerification = async () => {
    let body = {
      user_id:
        Number(tab) === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      identity_id: selectedId?.identity?.attached_id?.[0] || "",
    };

    setLoading(true);

    let res = await dispatch(acceptUserIdApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsIdentityModal(false);
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleRejectVerification = async () => {
    let body = {
      user_id:
        Number(tab) === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      identity_id: selectedId?.identity?.attached_id?.[0] || "",
      action: "reupload",
      reason: rejectionReason,
    };
    setLoading(true);

    let res = await dispatch(rejectUserIdApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setRejectionReason("");
      setIsRejectionIdentity(false);
      setIsIdentityModal(false);
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleAcceptRejectProfile = async (type) => {
    const userId = await getData(storageKey.USER_ID);
    console.log(userId);
    let body = {
      user_id:
        Number(tab) === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      super_id: userId,
      action: type,
    };
    if (type === "decline") {
      body.reason = rejectionReason;
    }
    setLoading(true);

    let res = await dispatch(profileApproveDeclineApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setRejectionReason("");
      setIsRejectionProfile(false);
      setIsIdentityModal(false);
      // if (type === "decline") {
      //   setTab(6)
      //   getRejectedUsers(1);
      // } else {
      // }
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleDeletePost = async () => {
    try {
      let body = {
        user_id: loggedInUser?.user_data?.user_id,
        action: "super_post_del",
        post_id: selectedId?.post_details?.ID,
      };
      setLoading(true);
      let res = await dispatch(deletePost(body));
      setLoading(false);
      if (res?.status === 200) {
        setIsDeleteModal(false);
        handleGetFunctions(tab, page);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleHideShow = async () => {
    try {
      let body = {
        super_id: loggedInUser?.user_data?.user_id,
        action:
          selectedId?.profile?.hide_status === "show" ||
          selectedId?.post_details?.hide_status === "show"
            ? "hide"
            : "show",
        post_id: selectedId?.post_details?.ID || selectedId?.profile?.ID,
        action_to:
          Number(tab) === 2
            ? "portfolio"
            : Number(tab) === 3
            ? "social_post"
            : "project",
      };
      setLoading(true);
      let res = await dispatch(hideShowReportedApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setHideShowModal(false);
        handleGetFunctions(tab, page);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteJob = async () => {
    // console.log(selectedId?.profile?.ID, "selectedIdselectedId");
    let body = {
      user_id: loggedInUser?.user_data?.user_id,
      post_id: selectedId?.profile?.ID,
      action: "delete",
    };
    setLoading(true);
    let res = await dispatch(deleteJob(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsDeleteModal(false);
      handleGetFunctions(tab, page);
    } else {
    }
  };

  useEffect(() => {
    if (loggedInUser) {
      handleGetFunctions(tab, "1");
    }
  }, [debouncedSearchTerm, loggedInUser]);

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      {Number(tab) === 1 ? (
        <AdminTable
          from="report"
          options={options}
          list={list}
          setList={setList}
          setTab={setTab}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          noDataFound={"No Models Found"}
          totalPages={totalPages}
          page={page}
          handleGetFunctions={handleGetFunctions}
          onPageChange={onPageChange}
          setPage={setPage}
          onBtnPress={() => {
            const queryParams = new URLSearchParams({
              type: "model",
            });
            navigate(`${routeName.ADD_USER}?${queryParams.toString()}`);
          }}
          tab={tab}
          Heading="Manage Reported Users"
          btnText="Add New Users"
          setSelectedId={setSelectedId}
          setIsDeleteModal={setIsDeleteModal}
          setIsIdentityModal={setIsIdentityModal}
          setIsResendModal={setIsResendModal}
          setIsIdentityViewModal={setIsIdentityViewModal}
          setIsProfileModal={setIsProfileModal}
          onEditClick={(id) => {
            const queryParams = new URLSearchParams({
              type: "model",
              id,
            });
            navigate(`${routeName.ADD_USER}?${queryParams.toString()}`);
          }}
          setIsReportModals={setIsReportModals}
          setReportedData={setReportedData}
        />
      ) : (
        <ReportedPosts
          totalPages={totalPages}
          page={page}
          handleGetFunctions={handleGetFunctions}
          onPageChange={onPageChange}
          setPage={setPage}
          noDataFound={"No Report found"}
          tab={tab}
          Heading={
            Number(tab) === 2
              ? "Reported Portfolio"
              : Number(tab) === 3
              ? "Reported Social Posts"
              : "Reported Jobs"
          }
          options={options}
          list={list}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          setTab={setTab}
          setSelectedId={setSelectedId}
          setIsDeleteModal={setIsDeleteModal}
          setList={setList}
          setIsReportModals={setIsReportModals}
          setReportedData={setReportedData}
          setHideShowModal={setHideShowModal}
        />
      )}

      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={() => {
            if (Number(tab) === 1) {
              setIsDeleteModal(false);
              setIsDeleteReason(true);
            } else if (Number(tab) === 4) {
              handleDeleteJob();
            } else {
              handleDeletePost();
            }
          }}
          text={`Are you sure you want to delete this ${
            Number(tab) === 1
              ? "model"
              : Number(tab) === 2
              ? "Portfolio"
              : Number(tab) === 3
              ? "Social Post"
              : "Job"
          }?`}
        />
      ) : undefined}

      {hideShowModal ? (
        <DeletePost
          setShow={setHideShowModal}
          show={hideShowModal}
          title="Alert"
          handleConfirm={() => {
            handleHideShow();
          }}
          text={`Are you sure you want to ${
            selectedId?.profile?.hide_status === "show" ||
            selectedId?.post_details?.hide_status === "show"
              ? "hide"
              : "show"
          } this ${
            Number(tab) === 1
              ? "model"
              : Number(tab) === 2
              ? "Portfolio"
              : Number(tab) === 3
              ? "Social Post"
              : "Job"
          }?`}
        />
      ) : undefined}

      {isDeleteReason ? (
        <DeleteReason
          setVisible={setIsDeleteReason}
          visible={isDeleteReason}
          title="Deleted reason"
          handleSubmit={() => {
            if (Number(tab) === 1) {
              handleDeleteModel();
            } else {
              handleDeletePost();
            }
          }}
          text={`Please add reason why you want to delete user profile ?`}
          reason={selectedReason}
          setReason={setSelectedReason}
          value={rejectionReason}
          setValue={setRejectionReason}
        />
      ) : undefined}

      {isIdentityViewModal ? (
        <ViewIdentity
          setShow={setIsIdentityViewModal}
          show={isIdentityViewModal}
          data={selectedId}
        />
      ) : undefined}

      {isResendModal ? (
        <AdminResendPassword
          setVisible={setIsResendModal}
          visible={isResendModal}
          handleClick={handleResendPassword}
          handleGeneratePassowrd={handleGeneratePassowrd}
          password={password}
          setPassword={setPassword}
        />
      ) : undefined}

      {isIdentityModal ? (
        <IdentityRequest
          setShow={setIsIdentityModal}
          show={isIdentityModal}
          title="Identity Verification"
          onAccept={handleAcceptVerification}
          onReject={() => {
            setIsIdentityModal(false);
            setIsRejectionIdentity(true);
          }}
          text={`Are you sure, you want to verify identity of this user?`}
        />
      ) : undefined}

      {isRejectionIdentity ? (
        <RejectionReason
          handleSubmit={handleRejectVerification}
          setVisible={setIsRejectionIdentity}
          visible={isRejectionIdentity}
          value={rejectionReason}
          setValue={setRejectionReason}
          title="Rejection reason"
          text="Please add reason why you want to reject identity application of this user ?"
        />
      ) : undefined}

      {isProfileModal ? (
        <IdentityRequest
          setShow={setIsProfileModal}
          show={isProfileModal}
          btn1Text="Accept"
          btn2Text="Reject"
          title="Account Verification"
          onAccept={() => handleAcceptRejectProfile("approved")}
          onReject={() => {
            setIsProfileModal(false);
            setIsRejectionProfile(true);
          }}
          text={`Are you sure, you want to verify Account of this user?`}
        />
      ) : undefined}

      {isRejectionProfile ? (
        <RejectionReason
          handleSubmit={() => handleAcceptRejectProfile("decline")}
          setVisible={setIsRejectionProfile}
          visible={isRejectionProfile}
          value={rejectionReason}
          setValue={setRejectionReason}
          title="Rejection reason"
          text="Please add reason why you want to reject user application ?"
        />
      ) : undefined}

      {isReportModals ? (
        <ReportedUsersModal
          show={isReportModals}
          setShow={setIsReportModals}
          reportedData={reportedData}
        />
      ) : undefined}

      <ViewProjects show={isProjectModal} setShow={setIsProjectModal} />
    </SidebarLayout>
  );
};

export default ReportedUsers;
