import React, { useEffect, useState } from "react";
import { Images } from "../../Constants";
import { Header, Footer, Loader } from "../../Components";
import { getData, storageKey } from "../../Utility/Storage";
import {
  getNotificationBadge,
  getNotificationList,
  markedNotifStatusApi,
  updateNotifyStatus,
} from "../../Redux/Services/OtherServices";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { SitePagination } from "../../Components/filtersComponents";
import { NotifPopup } from "../../Components/Popups/NotifPopUp";
import { GET_NOTIFICATION_BADGE } from "../../API Services/Url";
import Sidebar from "./Sidebar";
import SidebarLayout from "../../layout/SidebarLayout";

export const Notifications = () => {
  const dispatch = useDispatch();
  const badgeCount = useSelector((state) => state.otherReducer.badgeCount);

  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [count, setCount] = useState({
    notification: "0",
    chat: "0",
    badge: false,
  });

  const resultsPerPage = 20;
  let totalPages = Math.ceil(totalCount / resultsPerPage);

  const getNotificationHistory = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      setLoading(true);
      let body = {
        user_id: userID ? JSON.parse(userID) : "",
      };
      let res = await dispatch(getNotificationList(body));
      setLoading(false);
      if (res?.status === 200) {
        setNotificationData(res?.results);
      }
    }
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const getNotifCount = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let response = await dispatch(
        getNotificationBadge(body, GET_NOTIFICATION_BADGE)
      );
      // let resp = await dispatch(getNotificationBadge(body, GET_CHAT_BADGE));
      if (response?.status == 200) {
        setCount({
          ...count,
          // badge: response?.results.count_status,
          notification: response?.results?.count,
          // chat: resp?.results?.unread_count,
        });
      }
    }
  };

  const handleMarkedAll = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      setLoading(true);
      let body = {
        user_id: userID ? JSON.parse(userID) : "",
        status: "update",
      };
      let res = await dispatch(markedNotifStatusApi(body));
      setLoading(false);
      if (res?.status === 200) {
        getNotificationHistory();
        getNotifCount();
        // setNotificationData(res?.results);
      }
    }
  };

  const handleViewAction = async (notifId, msg, status, item) => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      if (status === "true") {
        setModalData(msg);
        setIsModalOpen(true);
        setSelectedItem(item)
      } else {
        setLoading(true);
        let body = {
          notification_id: notifId,
          user_id: userID ? JSON.parse(userID) : "",
          status: "update",
        };
        let res = await dispatch(updateNotifyStatus(body));
        setLoading(false);
        if (res?.status === 200) {
          getNotificationHistory();
          setModalData(msg);
          setIsModalOpen(true);
          setSelectedItem(item);
          // setNotificationData(res?.results);
        }
      }
    }
  };

  function getTimeDifference(date, time) {
    const dateTimeString = `${date} ${time}`;
    const targetDateTime = moment(dateTimeString).fromNow();
    return targetDateTime;
  }
  
  useEffect(() => {
    getNotificationHistory();
  }, []);

  return (
    <SidebarLayout>
       <Loader loading={loading} />
        <main>
          <div class="wt-dashboardbox wt-dashboardinvocies disputes-header">
            <div class="wt-dashboardboxtitle wt-addnew">
              <h2>Notification</h2>
              {notificationData?.length && badgeCount != 0 ? (
                <a
                  href="javascript:void(0);"
                  onClick={handleMarkedAll}
                  class=" mark_as_read"
                  id="read_all_notification_marked"
                  data-user_id="818"
                >
                  Mark all read
                </a>
              ) : undefined}
            </div>

            <div class="wt-notify-listwarap wt-categoriescontentholder">
              <table class="wt-tablecategories wt-tableservice">
                <thead>
                  <tr>
                    <th>Notification</th>
                    <th>Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationData?.length ? (
                    notificationData?.map((item, index) => (
                      <tr
                        className={
                          item?.notify_status !== "true"
                            ? "notif_act_1"
                            : "notif_non_act_1"
                        }
                        key={index}
                      >
                        <td data-th="Notification">
                          <span
                            class={
                              item?.notify_status !== "true"
                                ? "bt-content bt-content_active"
                                : "bt-content"
                            }
                          >
                            <i class="fa fa-envelope-open"></i>
                          </span>
                          <span
                            class={
                              item?.notify_status !== "true"
                                ? "bt-content bt-content_active"
                                : "bt-content"
                            }
                          >
                            {item?.notify_msg?.length > 80
                              ? item?.notify_msg?.slice(0, 80) + "..."
                              : item?.notify_msg}
                          </span>
                        </td>
                        <td data-th="Time">
                          <span
                            class={
                              item?.notify_status !== "true"
                                ? "bt-content bt-content_active"
                                : "bt-content"
                            }
                          >
                            {getTimeDifference(item?.date, item?.time)}
                          </span>
                        </td>
                        <td data-th="Action">
                          <span
                            class={
                              item?.notify_status !== "true"
                                ? "bt-content bt-content_active"
                                : "bt-content"
                            }
                          >
                            <div class="wt-actionbtn">
                              <a
                                onclick="event_preventDefault(event);"
                                class="wt-viewinfo viewinfo-notification"
                                data-id="70165"
                                onClick={() =>
                                  handleViewAction(
                                    item?.notify_id,
                                    item?.notify_msg,
                                    item?.notify_status,
                                    item
                                  )
                                }
                              >
                                <i class="lnr lnr-eye"></i>
                              </a>
                            </div>
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : loading ? undefined : (
                    <div className="noDataFound">
                      No Notification History Found
                    </div>
                  )}
                </tbody>
              </table>
              {totalPages > 1 ? (
                <SitePagination
                  module={notificationData}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </div>
        </main>
        <NotifPopup
          show={isModalOpen}
          setShow={setIsModalOpen}
          text={modalData}
          selectedItem={selectedItem}
        />
    </SidebarLayout>
  );
};
