import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import {
  cancelIdentityVerifyApi,
  getIdentityVerifyApi,
  getUserDetail,
} from "../../Redux/Services/AuthServices";

const SecondStep = ({ setStep }) => {
  const dispatch = useDispatch();

  const [identityId, setIdentityId] = useState("");

  const getUserIdentity = async () => {
    try {
      let userId = await getData(storageKey?.USER_ID);
      let body = {
        user_id: JSON.parse(userId),
      };

      const res = await dispatch(getIdentityVerifyApi(body));
      if (res?.status === 200) {
        setIdentityId(res?.results?.[0]?.identity_id);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status === 200) {
        //  setStep(2)
      }
    } else {
    }
  };

  const rejectIdentityStatus = async () => {
    if (identityId) {
      try {
        let userId = await getData(storageKey?.USER_ID);
        let body = {
          user_id: JSON.parse(userId),
          identity_id: Number(identityId),
          action: "reuplaod",
        };

        const res = await dispatch(cancelIdentityVerifyApi(body));
        if (res?.status === 200) {
          setStep(1);
          getUserData()
        }
      } catch (error) {
        console.error("API request failed:", error);
      }
    }
  };

  useEffect(() => {
    getUserIdentity();
  }, []);

  return (
    <div className=" idVerification">
      <div class="col-12">
        <div class="tk-request-section">
          <div class="tk-verification-content">
            <h5>Woohoo!</h5>{" "}
            <p>
              Please allow one to two weeks for our team to review your
              application. We will notify you soon. Thanks for your patience and
              understanding.
            </p>{" "}
          </div>
          
        </div>
      </div>
      <div class="col-md-7">
        <form class="post-identity-form wt-haslayout wt-attachmentsholder">
          <div class="wt-dashboardbox">
            <div class="wt-dashboardboxtitle wt-titlewithsearch">
              <h2>Upload Identity Information</h2>
            </div>
            <div class="wt-dashboardboxcontent">
              <div class="wt-helpsupportcontents">
                <div class="wt-tabscontenttitle">
                  <h2>Identity Verification inprogress</h2>
                </div>
                <div class="wt-description">
                  <p>
                    Thank you so much for submitting your identity documents, we
                    will review and send you an email very soon.
                  </p>
                </div>
                <a
                  class="wt-btn wt-cancel-identity"
                  //   href="#"
                  onClick={rejectIdentityStatus}
                >
                  Cancel & Re-Upload
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SecondStep;

// {
//     "user_id": "1629",
//     "profile_id": "71190",
//     "first_name": "sadsada",
//     "last_name": "asdadas",
//     "user_role": 11,
//     "gender": "female",
//     "full_name": "sadsada asdadas",
//     "user_email": "model12@yopmail.com",
//     "display_name": "Sadsada asdadas",
//     "post_date": "2024-03-15 14:58:34",
//     "web_profile_link": "https://booksculp.com//freelancer/sadsada-25690",
//     "verified_status": "yes",
//     "profile_approval": true,
//     "identity_status": 1,
//     "pause_status": 0,
// }
