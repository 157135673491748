import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { CDBTable, CDBTableHeader, CDBTableBody, CDBContainer } from "cdbreact";
import { useDispatch } from "react-redux";
import { productCheckout } from "../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../Components";
export const Checkout = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [checkoutDetails, setCheckoutDetails] = useState("");
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(location?.search);
  const projectData = {
    proposalId: queryParams?.get("proposalId"),
    projectId: queryParams?.get("projectId"),
  };

  useEffect(() => {
    getProjectCheckoutDetails();
  }, []);

  console.log("locationlocation------", location);

  const getProjectCheckoutDetails = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let body = {
      //   action: "hire",
      action: "web_react_hire",
      user_id: userId,
      project_id: projectData?.projectId,
      proposal_id: projectData?.proposalId,
    };
    setLoading(true);
    let res = await dispatch(productCheckout(body));
    setLoading(false);
    console.log("productCheckoutbodybody----", res);

    if (res?.status == 200) {
      setCheckoutDetails(res.results);
    }
  };
  //   const handlePlaceOrder = async () => {
  //     if (checkoutDetails?.url) {
  //       navigation?.navigate(routeName?.PACKAGE_PAYMENT, {
  //         stripe_URL: checkoutDetails?.url,
  //         onSuccessURL: "https://api.booksculp.com/success.php?success=true",
  //       });
  //     }
  //   };
  console.log(
    "checkoutDetailscheckoutDetails-----",
    JSON?.stringify(checkoutDetails)
  );
  const projectDetails = [
    {
      title: "Project title",
      value: checkoutDetails
        ? checkoutDetails?.prop_detail?.proposal_data?.basic?.post_title
        : null,
    },
    {
      title: "Talent",
      value: checkoutDetails
        ? checkoutDetails?.prop_detail?.proposal_data?.proposal_details
            ?.full_name
        : null,
    },
    {
      title: "Project Cost & Usage Fee",
      value: `$${
        checkoutDetails
          ? checkoutDetails?.prop_detail?.checkout_amt?.sub_total
          : 0
      }`,
    },
    {
      title: "Total per diem provided",
      value: `$${
        checkoutDetails
          ? checkoutDetails?.prop_detail?.checkout_amt?.amount_of_per_diem
          : 0
      }`,
    },
    {
      title: "Service fee",
      value: `$${
        checkoutDetails
          ? checkoutDetails?.prop_detail?.checkout_amt?.admin_amount
          : 0
      }`,
    },
    {
      title: "Grand Total",
      value: `$${
        checkoutDetails
          ? checkoutDetails?.prop_detail?.checkout_amt?.total_amount
          : 0
      }`,
    },
  ];
  const [currentUrl, setCurrentUrl] = useState("");

  const successUrl = "https://api.booksculp.com/success.php?success=true";
  const cancelUrl = "https://api.booksculp.com/cancel.php?canceled=true";
  //   https://api.booksculp.com/success.php?success=true&userID=1069&session_id=cs_test_a1dFyMq2tfW8Zuoy0CuGW4VgtSyPzPMy6RIwZBKiSiOPBnIa2EMADNODWn
  // https://booksculp.com/API/success.php?success=true&session_id=cs_test_a18PeMjbkielSCqYb0wLZ89lGiz7MCmnkKFn1t5QEWsWow9azX9RzDbPKR

  //https://api.booksculp.com/success.php?success=true&userID=1069&session_id=cs_live_a19c3HhmAK9actvrCXt7XxxSKgarQiPfin0TY2iEe2DABG0UJnUGIwDpRy
  //   useEffect(() => {
  //     if (location.state && location.state.redirectUrl) {
  //         console.log('lobvhvhgr', location.state && location.state.redirectUrl)
  //       if (location.state && location.state.redirectUrl.includes(successUrl)) {
  //         // let sessionId = location.state && location.state.redirectUrl.slice(
  //         //   successUrl?.length,
  //         //   location.state && location.state.redirectUrl?.length
  //         // );
  //         const match = location.state && location.state.redirectUrl.match(/[?&]session_id=([^&]*)/);
  //         const sessionId = match ? match[1] : null;
  //         alert(sessionId);
  //         //   handleCheckout(sessionId);
  //       } else if (location.state && location.state.redirectUrl.includes(successUrl)) {
  //         const match = location.state && location.state.redirectUrl.match(/[?&]session_id=([^&]*)/);
  //         const sessionId = match ? match[1] : null;
  //         //   handleCheckout(sessionId, "hire");
  //         alert(sessionId, "hire");
  //       } else if (location.state && location.state.redirectUrl.includes(cancelUrl)) {
  //         navigation(routeName?.PROPOSALS);
  //       }
  //     }
  //   }, [navigation]);

  useEffect(() => {
    console.log("location.statelocation.state-------", location.state);
    // if (location.state && location.state.redirectUrl) {
    //   const url = location.state.redirectUrl;
    //   if (window.location.href.includes(successUrl)) {
    //     alert("hiuhiihi");
    //     // hitAPI(url);
    //   }
    // }
  }, [location]);

  const handleCheckout = async (sessionId, type) => {
    let userId = await getData(storageKey?.USER_ID);
    var body = {
      //   action: "session",
      action: "session_react",
      sessions_id: sessionId,
      user_id: userId,
    };
    console.log("sessionId body-----", body);
    let res = await dispatch(productCheckout(body));
    if (res?.status == 200) {
      if (type == "hire") {
        navigation(routeName?.CLIENT_PROJECTS);
      } else {
        navigation(routeName?.DASHBOARD);
      }
    }
  };
  console.log(
    "checkoutDetails?.prop_detail?.proposal_details?.status----",
    checkoutDetails?.prop_detail?.proposal_data?.proposal_details
  );
  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div class="searchtalentList dashboard_main">
        <div className="car-page">
          <div className="col-md-7">
            <h3>Project Details</h3>
            <div className="table-responsive">
              <table className="table">
                {projectDetails?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.title}</td>
                      <td>{item?.value}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            {checkoutDetails?.prop_detail?.proposal_data?.proposal_details
              ?.status == "hired" ||
            checkoutDetails?.prop_detail?.proposal_data?.proposal_details
              ?.status == "complete" ? undefined : (
              <>
                <p>
                  {checkoutDetails?.url
                    ? `Note : You will be redirected to the payment page on Stripe to complete the booking process. The full amount will be held until you mark the job as completed. At that time, the funds will be released to the talent.`
                    : `Hire limit must be between $1 to $1M.`}
                </p>

                {loading ? undefined : (
                  <button
                    className="wt-btn"
                    onClick={
                      () => (window.location.href = checkoutDetails?.url)
                      //  navigation(routeName?.SUCCESS)
                      // navigation(checkoutDetails?.url, { replace: true })
                      // navigation.location(checkoutDetails?.url)
                    }
                  >
                    Hire Now
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};
