import React from "react";
import { InputField } from "../../Components";
import { Form } from "react-bootstrap";

const CompanyInfo = (props) => {
  const {
    error,
    values,
    setValues,
    country,
    setCountry,
    city,
    setCity,
    state,
    setState,
    locationList,
    handleSelectCity,
    handleSelectCountry,
    handleSelectState,
  } = props;
  return (
    <div class="form-group form-group-50">
      <InputField
        required
        title="Organisation"
        type="text"
        placeholder="Organisation"
        value={values?.organisation}
        onChangeText={(e) => {
          setValues({
            ...values,
            organisation: e?.target?.value,
          });
        }}
        isValid={error && !values?.organisation}
      />
      <InputField
        title="Title"
        type="text"
        placeholder="Title"
        value={values?.title}
        onChangeText={(e) => {
          setValues({
            ...values,
            title: e?.target?.value,
          });
        }}
        toolTipText="Add your Business Title Here"
      />
      <InputField
        required
        title="Business Email"
        type="Email"
        placeholder="Business Email"
        value={values?.businessEmail}
        onChangeText={(e) => {
          setValues({
            ...values,
            businessEmail: e?.target?.value,
          });
        }}
        isValid={error && !values?.businessEmail}
      />

      <InputField
        title="Business Website"
        type="Email"
        placeholder="Business Website"
        value={values?.businessWebite}
        onChangeText={(e) => {
          setValues({
            ...values,
            businessWebite: e?.target?.value,
          });
        }}
      />

      <InputField
        title="We have been in business since"
        type="number"
        placeholder="We have been in business since"
        value={values?.businessYear}
        onChangeText={(e) => {
          setValues({
            ...values,
            businessYear: e?.target?.value,
          });
        }}
      />

      <Form.Group>
        <Form.Label>
          <span className="astrik_req">*</span>Select Country
        </Form.Label>
        <Form.Select
          className={
            error && !country ? "required_inpt form-control" : "form-control"
          }
          onChange={(e) => {
            handleSelectCountry(e);
          }}
          value={country}
          required={true}
          isInvalid={country && error && !country}
          isValid={country && !(error && !country)}
        >
          <option value="">Select Country</option>
          {locationList?.countryList?.length
            ? locationList?.countryList?.map((item, index) => (
                <option value={item?.name} label={item?.name}>
                  {item?.name}
                </option>
              ))
            : undefined}
        </Form.Select>
        {error && !country ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {locationList?.stateList?.length ? (
            <span className="astrik_req">*</span>
          ) : undefined}
          Select State
        </Form.Label>
        <Form.Select
          className={
            error && !state && locationList?.stateList?.length
              ? "required_inpt form-control"
              : "form-control"
          }
          onChange={(e) => {
            handleSelectState(e);
          }}
          value={state}
          required={true}
          isInvalid={state && error && !state}
          isValid={state && !(error && !state)}
        >
          <option value="">Select State</option>
          {locationList?.stateList?.length &&
            locationList?.stateList?.map((item, index) => {
              return (
                <option value={item?.name} label={item?.name}>
                  {item?.name}
                </option>
              );
            })}
        </Form.Select>
        {error && !state && locationList?.stateList?.length ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {locationList?.cityList?.length ? (
            <span className="astrik_req">*</span>
          ) : undefined}
          Select City
        </Form.Label>
        <Form.Select
          className={
            error && !city && locationList?.cityList?.length
              ? "required_inpt form-control"
              : "form-control"
          }
          onChange={(e) => {
            handleSelectCity(e);
          }}
          value={city}
          required={true}
          isInvalid={city && error && !city}
          isValid={city && !(error && !city)}
        >
          <option value="">Select City</option>
          {locationList?.cityList?.length &&
            locationList?.cityList?.map((item, index) => {
              return (
                <option value={item?.name} label={item?.name}>
                  {item?.name}
                </option>
              );
            })}
        </Form.Select>
        {error && !city && locationList?.cityList?.length ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}
      </Form.Group>
      <InputField
        required
        title="Address line"
        type="text"
        placeholder="Enter Address line"
        value={values?.addressLine1}
        onChangeText={(e) => {
          setValues({
            ...values,
            addressLine1: e?.target?.value,
          });
        }}
        isValid={error && !values?.addressLine1}
      />
      <InputField
        title="Address line 2"
        type="text"
        placeholder="Enter Address line 2"
        value={values?.addressLine2}
        onChangeText={(e) => {
          setValues({
            ...values,
            addressLine2: e?.target?.value,
          });
        }}
      />
      <InputField
        required
        title="Postal Code"
        type="text"
        placeholder="Enter Postal Code"
        value={values?.postalCode}
        onChangeText={(e) => {
          setValues({
            ...values,
            postalCode: e?.target?.value,
          });
        }}
        isValid={error && !values?.postalCode}
      />
    </div>
  );
};

export default CompanyInfo;
