import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  DropdownList,
  InputField,
  MultiSelectDropdown,
} from "../../Components";
import { languages } from "../../Global";

const OtherDetails = ({
  talent,
  clientDetails,
  setClientDetails,
  basicDetails,
  setBasicDetails,
  setAddress,
  address,
  editSecStep,
  country,
  setCountry,
  getAllCountryName,
  setState,
  state,
  city,
  setCity,
  cityOptions,
  countryOptions,
  stateOptions,
  setCityOptions,
  error,
}) => {
  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  return (
    <>
      <div class="form-section">
        {editSecStep ? null : (
          <div className="form-section-title">
            {talent == "Client" || talent === "client" || talent == "Partners"
              ? "Company Info"
              : talent == "Photographer"
              ? "Photographer Details"
              : talent == "Model Kid"
              ? "Model Kid Details"
              : talent == "Actor"
              ? "Actor Details"
              : talent == "Actor Kid"
              ? "Actor Kid Details"
              : "Model Details"}
          </div>
        )}

        <div class="form-group form-group-50">
          {talent == "Client" || talent === "client" || talent == "Partners" ? (
            <>
              <InputField
                required
                title="Organization"
                type="text"
                value={clientDetails?.organization}
                placeholder="Organization"
                onChangeText={(e) => {
                  setClientDetails({
                    ...clientDetails,
                    organization: e?.target?.value,
                  });
                }}
                isValid={error && !clientDetails?.organization}
              />
              <InputField
                required
                title={talent == "Partners" ? "Job Title" : "Title"}
                type="text"
                value={clientDetails?.title}
                placeholder={talent == "Partners" ? "Job Title" : "Title"}
                onChangeText={(e) => {
                  setClientDetails({
                    ...clientDetails,
                    title: e?.target?.value,
                  });
                }}
                toolTipText={
                  talent === "Partners"
                    ? "Add your Business Title Here."
                    : "This is your job title for the company."
                }
                isValid={error && !clientDetails?.title}
              />
              <InputField
                required
                title="Business Email Address"
                type="email"
                value={clientDetails?.businessEmail}
                placeholder="Business Email Address"
                onChangeText={(e) => {
                  setClientDetails({
                    ...clientDetails,
                    businessEmail: e?.target?.value,
                  });
                }}
                isValid={error && !clientDetails?.businessEmail}
              />
              <InputField
                required
                title="Business website"
                type="text"
                value={clientDetails?.businessWebsite}
                placeholder="Business website"
                onChangeText={(e) => {
                  setClientDetails({
                    ...clientDetails,
                    businessWebsite: e?.target?.value,
                  });
                }}
                isValid={error && !clientDetails?.businessEmail}
              />
              <InputField
                title="We have been in business since"
                type="number"
                value={clientDetails?.businessSince}
                placeholder="We have been in business since"
                onChangeText={(e) => {
                  setClientDetails({
                    ...clientDetails,
                    businessSince: e?.target?.value,
                  });
                }}
              />
              <div className="fullCol">
                <InputField
                  required
                  title="Brief description"
                  type="textarea"
                  value={clientDetails?.description}
                  placeholder="Brief description"
                  onChangeText={(e) => {
                    setClientDetails({
                      ...clientDetails,
                      description: e?.target?.value,
                    });
                  }}
                  isValid={error && !clientDetails?.description}
                />
              </div>
            </>
          ) : talent == "Photographer" ? (
            <>
              <InputField
                required
                type="number"
                value={basicDetails?.hourlyRate}
                title="Hourly Rate"
                placeholder="Hourly Rate"
                onChangeText={(e) =>
                  setBasicDetails({
                    ...basicDetails,
                    hourlyRate: e?.target?.value,
                  })
                }
                toolTipText={
                  talent === "Actor Kid" || talent === "Model Kid"
                    ? "Add your per hour rate suggested rate is $75"
                    : "Add your per hour rate suggested rate is $150"
                }
                isValid={error && !basicDetails?.hourlyRate}
              />
              <InputField
                required
                type="number"
                value={basicDetails?.halfRate}
                title="Half Day Rate"
                placeholder="Half Day Rate"
                onChangeText={(e) =>
                  setBasicDetails({
                    ...basicDetails,
                    halfRate: e?.target?.value,
                  })
                }
                isValid={error && !basicDetails?.halfRate}
              />
              <InputField
                required
                type="number"
                value={basicDetails?.fullRate}
                title="Full Day Rate"
                placeholder="Full Day Rate"
                onChangeText={(e) =>
                  setBasicDetails({
                    ...basicDetails,
                    fullRate: e?.target?.value,
                  })
                }
                isValid={error && !basicDetails?.fullRate}
              />
            </>
          ) : (
            <>
              <InputField
                required
                type="number"
                value={basicDetails?.hourlyRate}
                title="Hourly Rate"
                placeholder="Hourly Rate"
                onChangeText={(e) =>
                  setBasicDetails({
                    ...basicDetails,
                    hourlyRate: e?.target?.value,
                  })
                }
                isValid={error && !basicDetails?.hourlyRate}
                toolTipText={
                  talent === "Actor Kid" || talent === "Model Kid"
                    ? "Add your per hour rate suggested rate is $75"
                    : "Add your per hour rate suggested rate is $150"
                }
              />

              {/* {talent === "Actor" ? undefined : ( */}
              <InputField
                required
                type="text"
                value={basicDetails?.age}
                title={
                  talent === "Actor" || talent === "Actor Kid"
                    ? "Age"
                    : "Model Age"
                }
                placeholder="Age"
                onChangeText={(e) =>
                  setBasicDetails({
                    ...basicDetails,
                    age: e?.target?.value,
                  })
                }
                isValid={error && !basicDetails?.age}
                disabled={true}
              />
              {/* )} */}

              <InputField
                title="Tagline"
                type="text"
                placeholder="Tagline"
                value={basicDetails?.tagLine}
                onChangeText={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    tagLine: e?.target?.value,
                  });
                }}
                toolTipText={
                  "This is a quick line to help promote you. It will be shown on your profile page. Ex. Outgoing and Friendly"
                }
              />
            </>
          )}

          <DropdownList
            required
            title="Country"
            placeholder="Select Country"
            options={countryOptions}
            value={country}
            setValue={setCountry}
            onSelect={() => {
              // getAllCountryName("state");
              setState("");
              setCity("");
            }}
            type="country"
            isValid={error && !country}
          />
          <DropdownList
            required={stateOptions?.length ? true : false}
            title="State"
            placeholder={
              stateOptions?.length ? "Select State" : "State not found !"
            }
            options={stateOptions}
            value={state}
            setValue={setState}
            disabled={country ? false : true}
            onSelect={() => {
              getAllCountryName();
              setCity("");
              setCityOptions([]);
            }}
            type="state"
            isValid={error && !state && stateOptions?.length}
          />
          <DropdownList
            required={cityOptions?.length ? true : false}
            title="City"
            options={cityOptions}
            value={city}
            setValue={setCity}
            placeholder={
              stateOptions?.length || cityOptions?.length
                ? "Select City"
                : "City not found !"
            }
            disabled={country && state ? false : true}
            isValid={error && !city && cityOptions?.length}
          />
          <InputField
            required
            title="Address line"
            type="text"
            placeholder="Enter Address line"
            value={address?.addressLine}
            onChangeText={(e) => {
              setAddress({
                ...address,
                addressLine: e?.target?.value,
              });
            }}
            isValid={error && !address?.addressLine}
          />

          {talent === "Photographer" ? undefined : (
            <InputField
              title="Address line 2"
              type="text"
              placeholder="Enter Address line 2"
              value={address?.addressLine2}
              onChangeText={(e) => {
                setAddress({
                  ...address,
                  addressLine2: e?.target?.value,
                });
              }}
            />
          )}
          <InputField
            required
            title="Postal Code"
            type="text"
            placeholder="Enter Postal Code"
            value={address?.postalCode}
            onChangeText={(e) => {
              setAddress({
                ...address,
                postalCode: e?.target?.value,
              });
            }}
            isValid={error && !address?.postalCode}
          />
        </div>
      </div>
    </>
  );
};

export default OtherDetails;
