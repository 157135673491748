import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const AdminResendPassword = (props) => {
  const {
    setVisible,
    visible,
    handleGeneratePassowrd,
    password,
    setPassword,
    handleClick,
  } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group reset-password">
          
          <input
            type="text"
            name="password"
            // disabled
            class="form-control gen_pass"
            placeholder="Add password"
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                setPassword(val.target.value);
              }
            }}
            value={password}
          />
          <Button
            onClick={() => {
              handleGeneratePassowrd();
            }}
          >
            Generate
          </Button>
          
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            handleClick();
          }}
        >
          Reset Password
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};
