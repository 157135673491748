import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey, storeData } from "../Utility/Storage";
import { getCountryList } from "../Redux/Services/OtherServices";
import { DropdownList } from "./DropdownList";
import {
  hourlyRate,
  jobDurations,
  payTypes,
  projectLocations,
} from "../Global";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import Slider from "@mui/material/Slider";

export const Filter = ({
  showFilter,
  setShowFilter,
  selectedFilters,
  setSelectedFilters,
  selectedSizes,
  setSelectedSizes,
  setCity,
  city,
  country,
  setCountry,
  state,
  setState,
  setPage,
  handleApplyFilter,
  mileRange,
  setMileRange,
  setIsSubmit,
  type,
  setMinPrice,
  minPrice,
  maxPrice,
  setMaxPrice,
  selectedAge,
  setSelectedAge,
  selectedGender,
  setSelectedGender,
  selectedkeyword,
  setSelectedkeyword,
  setSelectedTalent,
  selectedTalent,
  setMyUsers,
  myUsers,
}) => {
  const dispatch = useDispatch();
  const { allOptionData } = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [location, setLocation] = useState({
    countryID: "",
    countryList: "",
    stateList: "",
    cityList: "",
  });

  const handleTalentTypeChange = (event) => {
    setSelectedAge("");
    setSelectedTalent(event.target.value);

    // setSelectedFilters({
    //   ...selectedFilters,
    //   talentType: event.target.value,
    //   age: "",
    // });
    setSelectedSizes({
      ...selectedSizes,
      shirtSize: [],
      pantSize: [],
      shoeSize: [],
      dressShirtSize: [],
      braCup: [],
      braSize: [],
      dressSize: [],
      pantSizeL: [],
      pantSizeW: [],
      dressShirtSleeve: [],
      neckSize: [],
      chestSize: [],
      jacket: [],
      dressSizeFemale: [],
      shirtSizeFemail: [],
      pantSizeFemale: [],
      shoeSizeFemale: [],
    });
  };

  const handleProjectTypeChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      projectType: event.target.value,
    });
  };

  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
    // setSelectedFilters({
    //   ...selectedFilters,
    //   age: event.target.value,
    // });
    setSelectedSizes({
      ...selectedSizes,
      shirtSize: [],
      pantSize: [],
      shoeSize: [],
      dressShirtSize: [],
      braCup: [],
      braSize: [],
      dressSize: [],
      pantSizeL: [],
      pantSizeW: [],
      dressShirtSleeve: [],
      neckSize: [],
      chestSize: [],
      jacket: [],
      dressSizeFemale: [],
      shirtSizeFemail: [],
      pantSizeFemale: [],
      shoeSizeFemale: [],
    });
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
    // setSelectedFilters({
    //   ...selectedFilters,
    //   gender: event.target.value,
    // });
  };

  const getAllCountryName = async (type, step) => {
    let countryID = await getData(storageKey?.COUNTRY_ID);
    let stateID = await getData(storageKey?.STATE_ID);
    var body = {
      country: type == "country" ? "" : JSON?.parse(countryID),
      state: type == "city" ? JSON?.parse(stateID) : "",
      city: "",
    };
    let res = await dispatch(getCountryList(body));
    if (res?.status == 200) {
      let data;
      if (type == "country") {
        data = res?.results;
        setLocation({
          ...location,
          countryList: data,
        });
      } else if (type == "state") {
        if (res?.results?.length != 0) {
          data = JSON?.parse(res?.results);
          setLocation({
            ...location,
            stateList: data,
          });
        } else {
          setLocation({
            ...location,
            stateList: [],
          });
        }
      } else if (type == "city") {
        if (res?.results?.length != 0) {
          data = JSON?.parse(res?.results);
          setLocation({
            ...location,
            cityList: data,
          });
        } else {
          setLocation({
            ...location,
            cityList: [],
          });
        }
      }
    }
  };

  useEffect(() => {
    getAllCountryName("country");
  }, []);

  // useEffect(() => {
  //   // getAllCountryName('country')
  //   if (location?.countryList?.length != 0) {
  //     getStateList("country", 1);
  //   }
  //   if (location?.stateList?.length != 0) {
  //     getAllCountryName("city");
  //   }
  // }, [location?.countryList]);

  const getStateList = async (type) => {
    setCountry("United States");
    storeData(storageKey?.COUNTRY_ID, "233");
    getAllCountryName("state", 1);
  };

  const renderAgeOptions = () => {
    if (selectedTalent === "actorKid" || selectedTalent === "modelKid") {
      return allOptionData?.filterOption?.child_age_req?.field_meta?.choices?.map(
        (item, index) => (
          <option key={`month-${index}`} value={`${item?.label}`}>
            {`${item?.value}`}
          </option>
        )
      );
    } else if (selectedTalent === "model" || selectedTalent === "actor") {
      return allOptionData?.filterOption?.age_req?.field_meta?.choices?.map(
        (item, index) => (
          <option key={`month-${index}`} value={`${item?.label}`}>
            {`${item?.value}`}
          </option>
        )
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {showFilter && (
        <div className="fixedMob">
          <i
            aria-hidden="true"
            onClick={() => setShowFilter(!showFilter)}
            class="fa fa-close"
            style={{ fontSize: 20, color: "red" }}
          ></i>
          <aside id="wt-sidebar" className="wt-sidebar wt-usersidebar">
            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Search By keyword</h2>
              </div>
              <div className="wt-widgetcontent">
                <form className="wt-formtheme wt-formsearch">
                  <fieldset>
                    <div className="">
                      <input
                        type="text"
                        name="Search"
                        className="form-control"
                        placeholder="Type Keyword"
                        value={selectedkeyword}
                        onChange={(e) => {
                          setSelectedkeyword(e.target.value);
                          // setSelectedFilters({
                          //   ...selectedFilters,
                          //   keyword: e.target.value,
                          // });
                        }}
                      />
                      <a
                        onClick={() => {
                          // if (selectedFilters?.keyword?.length) {
                          setPage(1);
                          // setIsSubmit(true);
                          handleApplyFilter(1, "");
                          // }
                        }}
                        className="wt-searchgbtn"
                      >
                        <i className="lnr lnr-magnifier"></i>
                      </a>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Talent Type</h2>
              </div>
              <div className="wt-widgetcontent">
                <form className="wt-formtheme wt-formsearch">
                  <fieldset>
                    <div className="wt-checkboxholder wt-verticalscrollbar mCustomScrollbar _mCS_1">
                      <div
                        id="mCSB_1"
                        className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        tabindex="0"
                      >
                        <div
                          id="mCSB_1_container"
                          className="mCSB_container"
                          dir="ltr"
                        >
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              id="model"
                              name="talentType"
                              value="model"
                              checked={selectedTalent === "model"}
                              onChange={handleTalentTypeChange}
                            />
                            <label for="model"> Model</label>
                          </span>
                          {/* {loggedInUser?.user_data ? ( */}
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              id="modelKid"
                              name="talentType"
                              value="modelKid"
                              checked={selectedTalent === "modelKid"}
                              onChange={handleTalentTypeChange}
                            />
                            <label for="modelKid"> Model Kid</label>
                          </span>
                          {/* ) : undefined} */}
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              id="actor"
                              name="talentType"
                              value="actor"
                              checked={selectedTalent === "actor"}
                              onChange={handleTalentTypeChange}
                            />
                            <label for="actor"> Actor</label>
                          </span>
                          {/* {loggedInUser?.user_data ? ( */}
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              id="actorKid"
                              name="talentType"
                              value="actorKid"
                              checked={selectedTalent === "actorKid"}
                              onChange={handleTalentTypeChange}
                            />
                            <label for="actorKid"> Actor Kid</label>
                          </span>
                          {/* ) : undefined} */}
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              id="photographer"
                              name="talentType"
                              value="photographer"
                              checked={selectedTalent === "photographer"}
                              onChange={handleTalentTypeChange}
                            />
                            <label for="photographer">Photographer</label>
                          </span>
                        </div>
                        <div
                          id="mCSB_1_scrollbar_vertical"
                          className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                        >
                          <div className="mCSB_draggerContainer">
                            <div
                              id="mCSB_1_dragger_vertical"
                              className="mCSB_dragger"
                            >
                              <div className="mCSB_dragger_bar"></div>
                            </div>
                            <div className="mCSB_draggerRail"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>

            {type === "job" ? undefined : Number(
                loggedInUser?.user_data?.user_role
              ) === 14 ? (
              <div className="wt-widget wt-effectiveholder">
                <div className="wt-widgettitle">
                  <h2>My Users</h2>
                </div>
                <div className="wt-widgetcontent">
                  <form className="wt-formtheme wt-formsearch">
                    <fieldset>
                      <div className="wt-checkboxholder wt-verticalscrollbar mCustomScrollbar _mCS_1">
                        <div
                          id="mCSB_1"
                          className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                          tabindex="0"
                        >
                          <div
                            id="mCSB_1_container"
                            className="mCSB_container"
                            dir="ltr"
                          >
                            {/* <span className="wt-checkbox">
                              <input
                                type="checkbox"
                                checked={myUsers}
                                onChange={(e) => setMyUsers(e.target.checked)}
                              // id="myUsers"
                              // name="talentType"
                              // value="myUsers"
                              />
                              <label> Only Search in my users</label>
                            </span> */}
                            <span className="wt-checkbox">
                              <input
                                type="checkbox"
                                id="My Users"
                                name="myUsers"
                                value="My Users"
                                checked={myUsers === "My Users"}
                                onChange={(e) => setMyUsers(e.target.value)}
                              />
                              <label for="My Users">
                                {" "}
                                Only Search in my users
                              </label>
                            </span>
                          </div>
                          <div
                            id="mCSB_1_scrollbar_vertical"
                            className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                          >
                            <div className="mCSB_draggerContainer">
                              <div
                                id="mCSB_1_dragger_vertical"
                                className="mCSB_dragger"
                              >
                                <div className="mCSB_dragger_bar"></div>
                              </div>
                              <div className="mCSB_draggerRail"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            ) : undefined}

            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Search by Geo Location</h2>
              </div>
              <div className="wt-widgetcontent">
                <form className="wt-formtheme wt-formsearch jiolocaation">
                  <div className="wt-select">
                    <DropdownList
                      placeholder="Select Country"
                      options={location?.countryList}
                      value={country}
                      setValue={setCountry}
                      onSelect={() => {
                        getAllCountryName("state");
                        setState("");
                        setCity("");
                      }}
                      type="country"
                    />
                  </div>
                  <div className="wt-select">
                    <DropdownList
                      placeholder={
                        location?.stateList?.length != 0
                          ? "Select State"
                          : "State not found !"
                      }
                      options={location?.stateList}
                      value={state}
                      setValue={setState}
                      disabled={country ? false : true}
                      onSelect={() => {
                        getAllCountryName("city");
                        setCity("");
                      }}
                      type="state"
                    />
                  </div>
                  <div className="wt-select">
                    <DropdownList
                      placeholder={
                        location?.stateList?.length != 0 ||
                        location?.cityList?.length != 0
                          ? "Select City"
                          : "City not found !"
                      }
                      options={location?.cityList}
                      value={city}
                      setValue={setCity}
                      disabled={country && state ? false : true}
                    />
                  </div>
                  {country && state && city && (
                    <div className="form-group">
                      <h6>
                        Distance in miles from selected city : {mileRange}{" "}
                        {mileRange == 0
                          ? ""
                          : mileRange == 1
                          ? "mile"
                          : "miles"}
                      </h6>
                      <Slider
                        aria-label="Default"
                        defaultValue={0}
                        valueLabelDisplay="auto"
                        min={0}
                        max={300}
                        onChange={(event, newValue) => {
                          setMileRange(newValue);
                        }}
                      />
                    </div>
                  )}
                </form>
              </div>
              <div
                id="mCSB_2_scrollbar_vertical"
                className="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
              >
                <div className="mCSB_draggerContainer">
                  <div id="mCSB_2_dragger_vertical" className="mCSB_dragger">
                    <div className="mCSB_dragger_bar"></div>
                  </div>
                  <div className="mCSB_draggerRail"></div>
                </div>
              </div>
            </div>
            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Gender</h2>
              </div>
              <div className="wt-widgetcontent">
                <form className="wt-formtheme wt-formsearch">
                  <fieldset>
                    <div className="wt-checkboxholder wt-verticalscrollbar mCustomScrollbar _mCS_3">
                      <div
                        id="mCSB_3"
                        className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        tabindex="0"
                      >
                        <div
                          id="mCSB_3_container"
                          className="mCSB_container"
                          dir="ltr"
                        >
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              id="male"
                              checked={selectedGender === "male"}
                              onChange={handleGenderChange}
                            />
                            <label for="male">Male</label>
                          </span>
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              id="female"
                              checked={selectedGender === "female"}
                              onChange={handleGenderChange}
                            />
                            <label for="female">Female</label>
                          </span>
                          <span className="wt-checkbox">
                            <input
                              type="radio"
                              name="gender"
                              id="Non Binary"
                              value="Non Binary"
                              checked={selectedGender === "Non Binary"}
                              onChange={handleGenderChange}
                            />
                            <label for="Non Binary">Non Binary</label>
                          </span>
                        </div>
                        <div
                          id="mCSB_3_scrollbar_vertical"
                          className="mCSB_scrollTools mCSB_3_scrollbar mCS-light mCSB_scrollTools_vertical"
                        >
                          <div className="mCSB_draggerContainer">
                            <div
                              id="mCSB_3_dragger_vertical"
                              className="mCSB_dragger"
                            >
                              <div className="mCSB_dragger_bar"></div>
                            </div>
                            <div className="mCSB_draggerRail"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>

            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Age</h2>
              </div>
              <div className="wt-widgetcontent">
                <div className="wt-select">
                  <select
                    name="age"
                    value={selectedAge}
                    onChange={handleAgeChange}
                  >
                    <option value="">Select Age</option>
                    {renderAgeOptions()}
                  </select>
                </div>
              </div>
            </div>

            {type === "job" ? undefined : (
              <>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Ethnicity</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.otherOptions?.ethnicity?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Ethnicity"
                          placeholder="Select Ethnicity"
                          options={
                            allOptionData?.otherOptions?.ethnicity?.field_meta
                              ?.choices
                          }
                          value={selectedFilters?.ethnicities}
                          setValue={setSelectedFilters}
                          property={"ethnicities"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Height</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.otherOptions?.height?.field_meta?.choices
                        ?.length ? (
                        <MultiSelectDropdown
                          title="Height"
                          placeholder="Select Height"
                          options={
                            allOptionData?.otherOptions?.height?.field_meta
                              ?.choices
                          }
                          value={selectedFilters?.height}
                          setValue={setSelectedFilters}
                          property={"height"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Hair Color</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.otherOptions?.hair_colour?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Hair Color"
                          placeholder="Select Hair Color"
                          options={
                            allOptionData?.otherOptions?.hair_colour?.field_meta
                              ?.choices
                          }
                          value={selectedFilters?.hair_colour}
                          setValue={setSelectedFilters}
                          property={"hair_colour"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Weight</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.otherOptions?.weight?.field_meta?.choices
                        ?.length ? (
                        <MultiSelectDropdown
                          title="Weight"
                          placeholder="Select Weight"
                          options={
                            allOptionData?.otherOptions?.weight?.field_meta
                              ?.choices
                          }
                          value={selectedFilters?.weight}
                          setValue={setSelectedFilters}
                          property={"weight"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Influencer</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.filterOption?.influencer_req?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Influencer"
                          placeholder="Select Influencer"
                          options={
                            allOptionData?.filterOption?.influencer_req
                              ?.field_meta?.choices
                          }
                          value={selectedFilters?.influencers}
                          setValue={setSelectedFilters}
                          property={"influencers"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Hourly Rate</h2>
                  </div>
                  <div className="wt-">
                    {hourlyRate?.length ? (
                      <MultiSelectDropdown
                        title="Hourly Rate"
                        placeholder="Select Hourly Rate"
                        options={hourlyRate}
                        value={selectedFilters?.hourlyRate}
                        setValue={setSelectedFilters}
                        property={"hourlyRate"}
                      />
                    ) : undefined}
                  </div>
                  <div className="wt-widgetcontent">
                    <form className="wt-formtheme wt-formsearch">
                      <fieldset>
                        {/* <div className="wt-checkboxholder">
                      {hourlyRate?.length
                        ? hourlyRate?.map((rate) => (
                            <span key={rate?.value} className="wt-checkbox">
                              <input
                                type="radio"
                                id={`rate-${rate?.value}`}
                                name="rate"
                                value={rate?.value}
                                checked={
                                  selectedFilters?.hourlyRate === rate?.value
                                }
                                onChange={handleRateChange}
                              />
                              <label htmlFor={`rate-${rate?.value}`}>
                                {rate?.value || ""}
                              </label>
                            </span>
                          ))
                        : undefined}
                    </div> */}
                      </fieldset>
                    </form>
                  </div>
                </div>
              </>
            )}

            {type === "job" ? (
              <>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Project Type</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <form className="wt-formtheme wt-formsearch">
                      <fieldset>
                        <div className="wt-checkboxholder">
                          {payTypes?.length
                            ? payTypes?.map((project) => (
                                <span
                                  key={project?.value}
                                  className="wt-checkbox"
                                >
                                  <input
                                    type="radio"
                                    id={`project-${project?.value}`}
                                    name="project"
                                    value={project?.value}
                                    checked={
                                      selectedFilters?.projectType ===
                                      project?.value
                                    }
                                    onChange={handleProjectTypeChange}
                                  />
                                  <label htmlFor={`project-${project?.value}`}>
                                    {project?.value || ""}
                                  </label>
                                </span>
                              ))
                            : undefined}
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2> Price</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="form-group">
                      <label>Min Price</label>
                      <input
                        type="number"
                        name="Search"
                        className="form-control"
                        placeholder="Min Price"
                        value={minPrice}
                        onChange={(e) => {
                          const value = Math.max(
                            0,
                            Math.min(10000, parseInt(e.target.value) || 0)
                          );
                          setMinPrice(value);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Max Price</label>
                      <input
                        type="number"
                        name="Search"
                        className="form-control"
                        placeholder="Max Price"
                        value={maxPrice}
                        onChange={(e) => {
                          const value = Math.max(
                            0,
                            Math.min(10000, parseInt(e.target.value) || 0)
                          );
                          setMaxPrice(value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Project Length</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {jobDurations?.length ? (
                        <MultiSelectDropdown
                          title="Project Length"
                          placeholder="Select Project Length"
                          options={jobDurations}
                          value={selectedFilters?.projectLength}
                          setValue={setSelectedFilters}
                          property={"projectLength"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Project Locations</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {projectLocations?.length ? (
                        <MultiSelectDropdown
                          title="Project Locations"
                          placeholder="Select Project Locations"
                          options={projectLocations}
                          value={selectedFilters?.projectLocation}
                          setValue={setSelectedFilters}
                          property={"projectLocation"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </>
            ) : undefined}

            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Skills</h2>
              </div>
              <div className="wt-widgetcontent">
                <div className="wt-">
                  {allOptionData?.otherOptions?.add_new_skill?.field_meta
                    ?.choices?.length ? (
                    <MultiSelectDropdown
                      title="Skills"
                      placeholder="Select Skills"
                      options={
                        allOptionData?.otherOptions?.add_new_skill?.field_meta
                          ?.choices
                      }
                      value={selectedFilters?.skills}
                      setValue={setSelectedFilters}
                      property={"skills"}
                    />
                  ) : undefined}
                </div>
              </div>
            </div>

            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Language Level</h2>
              </div>
              <div className="wt-widgetcontent">
                <div className="wt-">
                  {allOptionData?.englishLevel?.length ? (
                    <MultiSelectDropdown
                      title="Language Level"
                      placeholder="Select Language Level"
                      options={allOptionData?.englishLevel?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      value={selectedFilters?.english_level}
                      setValue={setSelectedFilters}
                      property={"english_level"}
                    />
                  ) : undefined}
                </div>
              </div>
            </div>

            <div className="wt-widget wt-effectiveholder">
              <div className="wt-widgettitle">
                <h2>Languages</h2>
              </div>
              <div className="wt-widgetcontent">
                <div className="wt-">
                  {allOptionData?.languages?.length ? (
                    <MultiSelectDropdown
                      title="Languages"
                      placeholder="Select Languages"
                      options={allOptionData?.languages?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      value={selectedFilters?.language}
                      setValue={setSelectedFilters}
                      property={"language"}
                    />
                  ) : undefined}
                </div>
              </div>
            </div>

            <>
              {selectedGender &&
              (selectedTalent === "modelKid" ||
                selectedTalent === "actorKid") ? (
                <>
                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Shirt Size</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <div className="wt-">
                        {allOptionData?.childOptions?.childer_shirt_size
                          ?.field_meta?.choices?.length ? (
                          <MultiSelectDropdown
                            title="Shirt Size"
                            placeholder="Select Shirt Size"
                            options={
                              allOptionData?.childOptions?.childer_shirt_size
                                ?.field_meta?.choices
                            }
                            value={selectedSizes?.shirtSize}
                            setValue={setSelectedSizes}
                            property={"shirtSize"}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </div>

                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Pant Size</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <div className="wt-">
                        {allOptionData?.childOptions?.childer_pant_size
                          ?.field_meta?.choices?.length ? (
                          <MultiSelectDropdown
                            title="Pant Size"
                            placeholder="Select Pant Size"
                            options={
                              allOptionData?.childOptions?.childer_pant_size
                                ?.field_meta?.choices
                            }
                            value={selectedSizes?.pantSize}
                            setValue={setSelectedSizes}
                            property={"pantSize"}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </div>

                  <div className="wt-widget wt-effectiveholder">
                    <div className="wt-widgettitle">
                      <h2>Shoe Size</h2>
                    </div>
                    <div className="wt-widgetcontent">
                      <div className="wt-">
                        {allOptionData?.childOptions?.childer_shoe_size
                          ?.field_meta?.choices?.length ? (
                          <MultiSelectDropdown
                            title="Shoe Size"
                            placeholder="Select Shoe Size"
                            options={
                              allOptionData?.childOptions?.childer_shoe_size
                                ?.field_meta?.choices
                            }
                            value={selectedSizes?.shoeSize}
                            setValue={setSelectedSizes}
                            property={"shoeSize"}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </div>

                  {selectedGender === "female" ? (
                    <div className="wt-widget wt-effectiveholder">
                      <div className="wt-widgettitle">
                        <h2>Dress Shirt Size</h2>
                      </div>
                      <div className="wt-widgetcontent">
                        <div className="wt-">
                          {allOptionData?.childOptions?.childer_dress_size
                            ?.field_meta?.choices?.length ? (
                            <MultiSelectDropdown
                              title="Dress Shirt Size"
                              placeholder="Select Dress Shirt Size"
                              options={
                                allOptionData?.childOptions?.childer_dress_size
                                  ?.field_meta?.choices
                              }
                              value={selectedSizes?.dressShirtSize}
                              setValue={setSelectedSizes}
                              property={"dressShirtSize"}
                            />
                          ) : undefined}
                        </div>
                      </div>
                    </div>
                  ) : undefined}
                </>
              ) : undefined}
            </>

            {selectedGender === "female" &&
            (selectedTalent === "model" || selectedTalent === "actor") ? (
              <>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shirt Size (F)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.shirt_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shirt Size (F)"
                          placeholder="Select Shirt Size (F)"
                          options={
                            allOptionData?.femaleOptions?.shirt_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.shirtSizeFemail}
                          setValue={setSelectedSizes}
                          property={"shirtSizeFemail"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Pant Size (F)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.pant_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Pant Size (F)"
                          placeholder="Select Pant Size (F)"
                          options={
                            allOptionData?.femaleOptions?.pant_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.pantSizeFemale}
                          setValue={setSelectedSizes}
                          property={"pantSizeFemale"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shoe Size (F)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.shoe_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shoe Size (F)"
                          placeholder="Select Shoe Size (F)"
                          options={
                            allOptionData?.femaleOptions?.shoe_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.shoeSizeFemale}
                          setValue={setSelectedSizes}
                          property={"shoeSizeFemale"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Dress Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.dress_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Dress Size"
                          placeholder="Select Dress Size"
                          options={
                            allOptionData?.femaleOptions?.dress_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.dressSizeFemale}
                          setValue={setSelectedSizes}
                          property={"dressSizeFemale"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Bra Cup</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.bra_cup?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Bra Cup"
                          placeholder="Select Bra Cup"
                          options={
                            allOptionData?.femaleOptions?.bra_cup?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.braCup}
                          setValue={setSelectedSizes}
                          property={"braCup"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Bra Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.bra_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Bra Size"
                          placeholder="Select Bra Size"
                          options={
                            allOptionData?.femaleOptions?.bra_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.braSize}
                          setValue={setSelectedSizes}
                          property={"braSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </>
            ) : undefined}

            {selectedGender === "male" &&
            (selectedTalent === "model" || selectedTalent === "actor") ? (
              <>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shirt Size (M)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.shirt_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shirt Size (M)"
                          placeholder="Select Shirt Size (M)"
                          options={
                            allOptionData?.maleOptions?.shirt_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.shirtSize}
                          setValue={setSelectedSizes}
                          property={"shirtSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Pant Size (L)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.pant_size_length?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Pant Size (L)"
                          placeholder="Select Pant Size (L)"
                          options={
                            allOptionData?.maleOptions?.pant_size_length
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.pantSizeL}
                          setValue={setSelectedSizes}
                          property={"pantSizeL"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shoe Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.shoe_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shoe Size"
                          placeholder="Select Shoe Size"
                          options={
                            allOptionData?.maleOptions?.shoe_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.shoeSize}
                          setValue={setSelectedSizes}
                          property={"shoeSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Pant Size (W)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.pant_size_waist?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Pant Size (W)"
                          placeholder="Select Pant Size (W)"
                          options={
                            allOptionData?.maleOptions?.pant_size_waist
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.shoeSize}
                          setValue={setSelectedSizes}
                          property={"shoeSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Neck Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.neck_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Neck Size"
                          placeholder="Select Neck Size"
                          options={
                            allOptionData?.maleOptions?.neck_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.neckSize}
                          setValue={setSelectedSizes}
                          property={"neckSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Chest Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.chest_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Chest Size"
                          placeholder="Select Chest Size"
                          options={
                            allOptionData?.maleOptions?.chest_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.chestSize}
                          setValue={setSelectedSizes}
                          property={"chestSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Dress Shirt Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.dress_shirt_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Dress Shirt Size"
                          placeholder="Select Dress Shirt Size"
                          options={
                            allOptionData?.maleOptions?.dress_shirt_size
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.dressShirtSize}
                          setValue={setSelectedSizes}
                          property={"dressShirtSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Dress Shirt Sleeves</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.dress_shirt_sleeve
                        ?.field_meta?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Dress Shirt Sleeves"
                          placeholder="Select Dress Shirt Sleeves"
                          options={
                            allOptionData?.maleOptions?.dress_shirt_sleeve
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.dressShirtSleeve}
                          setValue={setSelectedSizes}
                          property={"dressShirtSleeve"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Jacket</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.jacket?.field_meta?.choices
                        ?.length ? (
                        <MultiSelectDropdown
                          title="Jacket"
                          placeholder="Select Jacket"
                          options={
                            allOptionData?.maleOptions?.jacket?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.jacket}
                          setValue={setSelectedSizes}
                          property={"jacket"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </>
            ) : undefined}

            {selectedGender === "Non Binary" &&
            (selectedTalent === "model" || selectedTalent === "actor") ? (
              <>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shirt Size (M)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.shirt_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shirt Size (M)"
                          placeholder="Select Shirt Size (M)"
                          options={
                            allOptionData?.maleOptions?.shirt_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.shirtSize}
                          setValue={setSelectedSizes}
                          property={"shirtSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Pant Size (L)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.pant_size_length?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Pant Size (L)"
                          placeholder="Select Pant Size (L)"
                          options={
                            allOptionData?.maleOptions?.pant_size_length
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.pantSizeL}
                          setValue={setSelectedSizes}
                          property={"pantSizeL"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shoe Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.shoe_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shoe Size"
                          placeholder="Select Shoe Size"
                          options={
                            allOptionData?.maleOptions?.shoe_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.shoeSize}
                          setValue={setSelectedSizes}
                          property={"shoeSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Pant Size (W)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.pant_size_waist?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Pant Size (W)"
                          placeholder="Select Pant Size (W)"
                          options={
                            allOptionData?.maleOptions?.pant_size_waist
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.shoeSize}
                          setValue={setSelectedSizes}
                          property={"shoeSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Neck Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.neck_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Neck Size"
                          placeholder="Select Neck Size"
                          options={
                            allOptionData?.maleOptions?.neck_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.neckSize}
                          setValue={setSelectedSizes}
                          property={"neckSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Chest Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.chest_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Chest Size"
                          placeholder="Select Chest Size"
                          options={
                            allOptionData?.maleOptions?.chest_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.chestSize}
                          setValue={setSelectedSizes}
                          property={"chestSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Dress Shirt Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.dress_shirt_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Dress Shirt Size"
                          placeholder="Select Dress Shirt Size"
                          options={
                            allOptionData?.maleOptions?.dress_shirt_size
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.dressShirtSize}
                          setValue={setSelectedSizes}
                          property={"dressShirtSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Dress Shirt Sleeves</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.dress_shirt_sleeve
                        ?.field_meta?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Dress Shirt Sleeves"
                          placeholder="Select Dress Shirt Sleeves"
                          options={
                            allOptionData?.maleOptions?.dress_shirt_sleeve
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.dressShirtSleeve}
                          setValue={setSelectedSizes}
                          property={"dressShirtSleeve"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Jacket</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.maleOptions?.jacket?.field_meta?.choices
                        ?.length ? (
                        <MultiSelectDropdown
                          title="Jacket"
                          placeholder="Select Jacket"
                          options={
                            allOptionData?.maleOptions?.jacket?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.jacket}
                          setValue={setSelectedSizes}
                          property={"jacket"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shirt Size (F)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.shirt_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shirt Size (F)"
                          placeholder="Select Shirt Size (F)"
                          options={
                            allOptionData?.femaleOptions?.shirt_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.shirtSizeFemail}
                          setValue={setSelectedSizes}
                          property={"shirtSizeFemail"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Pant Size (F)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.pant_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Pant Size (F)"
                          placeholder="Select Pant Size (F)"
                          options={
                            allOptionData?.femaleOptions?.pant_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.pantSizeFemale}
                          setValue={setSelectedSizes}
                          property={"pantSizeFemale"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Shoe Size (F)</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.shoe_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Shoe Size (F)"
                          placeholder="Select Shoe Size (F)"
                          options={
                            allOptionData?.femaleOptions?.shoe_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.shoeSizeFemale}
                          setValue={setSelectedSizes}
                          property={"shoeSizeFemale"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Dress Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.dress_size_f?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Dress Size"
                          placeholder="Select Dress Size"
                          options={
                            allOptionData?.femaleOptions?.dress_size_f
                              ?.field_meta?.choices
                          }
                          value={selectedSizes?.dressSizeFemale}
                          setValue={setSelectedSizes}
                          property={"dressSizeFemale"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>

                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Bra Cup</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.bra_cup?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Bra Cup"
                          placeholder="Select Bra Cup"
                          options={
                            allOptionData?.femaleOptions?.bra_cup?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.braCup}
                          setValue={setSelectedSizes}
                          property={"braCup"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
                <div className="wt-widget wt-effectiveholder">
                  <div className="wt-widgettitle">
                    <h2>Bra Size</h2>
                  </div>
                  <div className="wt-widgetcontent">
                    <div className="wt-">
                      {allOptionData?.femaleOptions?.bra_size?.field_meta
                        ?.choices?.length ? (
                        <MultiSelectDropdown
                          title="Bra Size"
                          placeholder="Select Bra Size"
                          options={
                            allOptionData?.femaleOptions?.bra_size?.field_meta
                              ?.choices
                          }
                          value={selectedSizes?.braSize}
                          setValue={setSelectedSizes}
                          property={"braSize"}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </>
            ) : undefined}
          </aside>
          <div className="wt-widget wt-applyfilters-holder">
            <div className="wt-widgetcontent">
              <div className="wt-applyfilters">
                <span>
                  Click “Apply Filters” to apply latest
                  <br /> changes made by you.
                </span>
                <a
                  onClick={() => {
                    setPage(1);
                    setIsSubmit(true);
                    handleApplyFilter(1, selectedTalent);
                  }}
                  href="javascript:void(0);"
                  className="wt-btn"
                >
                  Apply Filters
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
