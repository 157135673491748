import React from 'react'
import { Footer, Header } from '../../../Components'

const ReportedLink = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Header />
      <Footer />
    </div>
  )
}

export default ReportedLink