import React from "react";
import { DropdownList, InputField } from "../../Components";
import { genderTypes, kidGenderTypes } from "../../Global";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const YourBasic = (props) => {
  const {
    error,
    values,
    setValues,
    submitted,
    setGender,
    gender,
    talent,
    setTalent,
    country,
    setCountry,
    city,
    setCity,
    state,
    setState,
    locationList,
    handleSelectCity,
    handleSelectCountry,
    handleSelectState,
    id,
    type,
    relationStatus,
    setRelationStatus,
  } = props;

  let talentData = [
    {
      label: type === "model" ? "Model" : "Actor",
      value: type === "model" ? "Model" : "Actor",
    },
    {
      label: type === "model" ? "Model Kid" : "Actor Kid",
      value: type === "model" ? "Model Kid" : "Actor Kid",
    },
  ];

  let photoData = [
    {
      label: "Photographer",
      value: "Photographer",
    },
  ];

  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  return (
    <div class="form-group form-group-50">
      <DropdownList
        required
        title="Talent type"
        placeholder="Select Talent type"
        options={type === "photo" ? photoData : talentData}
        value={talent}
        setValue={setTalent}
        isValid={error && !talent}
      />
      <InputField
        required
        title="Date of birth"
        type="date"
        value={values?.dob}
        placeholder="DD/MM/YYYY"
        onChangeText={(e) => {
          setValues({
            ...values,
            dob: e?.target?.value,
          });
        }}
        talent={talent}
        isValid={error && !values?.dob}
      />

      <DropdownList
        required
        title="Gender"
        placeholder="Select Gender"
        options={
          talent == "Model Kid" || talent == "Actor Kid" || talent == "child"
            ? kidGenderTypes
            : genderTypes
        }
        value={gender}
        setValue={setGender}
        isValid={error && !gender}
      />

      <InputField
        required
        title="First name"
        type="text"
        placeholder="First name"
        value={values?.firstName}
        onChangeText={(e) => {
          setValues({
            ...values,
            firstName: e?.target?.value,
          });
        }}
        isValid={error && !values?.firstName}
      />
      <InputField
        required
        title="Last name"
        type="text"
        placeholder="Last name"
        value={values?.lastName}
        onChangeText={(e) => {
          setValues({
            ...values,
            lastName: e?.target?.value,
          });
        }}
        isValid={error && !values?.lastName}
      />
      <InputField
        required
        title="Display name"
        type="text"
        placeholder="Display name"
        value={values?.displayName}
        onChangeText={(e) => {
          setValues({
            ...values,
            displayName: e?.target?.value,
          });
        }}
        isValid={error && !values?.displayName}
        toolTipText={"This will be your display name on the site"}
      />
      <InputField
        required
        title={
          talent === "Model Kid" || talent === "Actor Kid"
            ? "Guardian Phone Number"
            : "Phone number"
        }
        type="number"
        placeholder={
          talent === "Model Kid" || talent === "Actor Kid"
            ? "Guardian Phone Number"
            : "Phone number"
        }
        value={values?.phoneNo}
        onChangeText={(e) => {
          setValues({
            ...values,
            phoneNo: e?.target?.value,
          });
        }}
        isValid={error && !values?.phoneNo}
      />
      <InputField
        required
        title="Email"
        type="Email"
        placeholder="Email"
        value={values?.email}
        onChangeText={(e) => {
          setValues({
            ...values,
            email: e?.target?.value,
          });
        }}
        isValid={error && !values?.email}
      />
      {id ? undefined : (
        <InputField
          required
          title="Password"
          type="password"
          placeholder="Password"
          value={values?.password}
          onChangeText={(e) => {
            setValues({
              ...values,
              password: e?.target?.value,
            });
          }}
          isValid={error && !values?.password}
        />
      )}
      {talent === "Model Kid" || talent === "Actor Kid" ? (
        <InputField
          required
          title="Guardian Name"
          type="text"
          placeholder="Guardian Name"
          value={values?.guardianName}
          onChangeText={(e) => {
            setValues({
              ...values,
              guardianName: e?.target?.value,
            });
          }}
          isValid={error && !values?.guardianName}
        />
      ) : undefined}
      <InputField
        required
        type="number"
        value={values?.hourlyPrice}
        title="Hourly Rate"
        placeholder="Hourly Rate"
        onChangeText={(e) =>
          setValues({
            ...values,
            hourlyPrice: e?.target?.value,
          })
        }
        isValid={error && !values?.hourlyPrice}
        toolTipText={
          talent === "Actor Kid" || talent === "Model Kid"
            ? "Add your per hour rate suggested rate is $75"
            : "Add your per hour rate suggested rate is $150"
        }
      />
      {talent === "Actor" ? (
        <DropdownList
          title="Relationship Status"
          placeholder="Select Status"
          options={
            optionsType?.actorRelation?.relationship_status?.field_meta?.choices
          }
          value={relationStatus}
          setValue={setRelationStatus}
        />
      ) : undefined}
      <Form.Group>
        <Form.Label>
          <span className="astrik_req">*</span>Select Country
        </Form.Label>
        <Form.Select
          className={
            error && !country ? "required_inpt form-control" : "form-control"
          }
          onChange={(e) => {
            handleSelectCountry(e);
          }}
          value={country}
          required={true}
          isInvalid={country && error && !country}
          isValid={country && !(error && !country)}
        >
          <option value="">Select Country</option>
          {locationList?.countryList?.length
            ? locationList?.countryList?.map((item, index) => (
                <option value={item?.name} label={item?.name}>
                  {item?.name}
                </option>
              ))
            : undefined}
        </Form.Select>
        {error && !country ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {locationList?.stateList?.length ? (
            <span className="astrik_req">*</span>
          ) : undefined}
          Select State
        </Form.Label>
        <Form.Select
          className={
            error && !state && locationList?.stateList?.length
              ? "required_inpt form-control"
              : "form-control"
          }
          onChange={(e) => {
            handleSelectState(e);
          }}
          value={state}
          required={true}
          isInvalid={state && error && !state}
          isValid={state && !(error && !state)}
        >
          <option value="">Select State</option>
          {locationList?.stateList?.length &&
            locationList?.stateList?.map((item, index) => {
              return (
                <option value={item?.name} label={item?.name}>
                  {item?.name}
                </option>
              );
            })}
        </Form.Select>
        {error && !state && locationList?.stateList?.length ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {locationList?.cityList?.length ? (
            <span className="astrik_req">*</span>
          ) : undefined}
          Select City
        </Form.Label>
        <Form.Select
          className={
            error && !city && locationList?.cityList?.length
              ? "required_inpt form-control"
              : "form-control"
          }
          onChange={(e) => {
            handleSelectCity(e);
          }}
          value={city}
          required={true}
          isInvalid={city && error && !city}
          isValid={city && !(error && !city)}
        >
          <option value="">Select City</option>
          {locationList?.cityList?.length &&
            locationList?.cityList?.map((item, index) => {
              return (
                <option value={item?.name} label={item?.name}>
                  {item?.name}
                </option>
              );
            })}
        </Form.Select>
        {error && !city && locationList?.cityList?.length ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}
      </Form.Group>
      <InputField
        required
        title="Address line"
        type="text"
        placeholder="Enter Address line"
        value={values?.addressLine1}
        onChangeText={(e) => {
          setValues({
            ...values,
            addressLine1: e?.target?.value,
          });
        }}
        isValid={error && !values?.addressLine1}
      />
      <InputField
        title="Address line 2"
        type="text"
        placeholder="Enter Address line 2"
        value={values?.addressLine2}
        onChangeText={(e) => {
          setValues({
            ...values,
            addressLine2: e?.target?.value,
          });
        }}
      />
      <InputField
        required
        title="Postal Code"
        type="text"
        placeholder="Enter Postal Code"
        value={values?.postalCode}
        onChangeText={(e) => {
          setValues({
            ...values,
            postalCode: e?.target?.value,
          });
        }}
        isValid={error && !values?.postalCode}
      />

      {type === "photo" ? (
        <>
          <InputField
            title="Tagline"
            type="text"
            placeholder="Add Your Tagline Here"
            value={values?.tagline}
            onChangeText={(e) => {
              setValues({
                ...values,
                tagline: e?.target?.value,
              });
            }}
            maxLength={200}
            toolTipText={
              "This is a quick line to help promote you. It will be shown on your profile page. Ex. Outgoing and Friendly"
            }
          />
          <InputField
            required
            title="Years Of Experience"
            type="number"
            placeholder="Years Of Experience"
            value={values?.yearsOfExp}
            onChangeText={(e) => {
              setValues({
                ...values,
                yearsOfExp: e?.target?.value,
              });
            }}
            isValid={error && !values?.yearsOfExp}
          />
        </>
      ) : undefined}
    </div>
  );
};

export default YourBasic;
