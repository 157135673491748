import { showToast } from ".";

export const storageKey = {
  AUTH_TOKEN: "@AUTH_TOKEN",
  USER_DATA: "@USERDATA",
  USER_ID: "@USER_ID",
  USER_ROLE: "@USER_ROLE",
  TEMP_USER_ID: "@TEMP_USER_ID",
  TEMP_TOKEN: "@TEMP_TOKEN",
  ROOM_ID: "@ROOM_ID",
  CHANGE_NAVIGATOR: "@CHANGE_NAVIGATOR",
  COUNTRY_ID: "@COUNTRY_ID",
  STATE_ID: "@STATE_ID",
  CREDS: "@CREDS",
  FCM_TOKEN: "@FCM_TOKEN",
  APPROVAL_STATUS: "@APPROVAL_STATUS",
  USER_STATUS: "@USER_STATUS",
  PACKAGE_ID: "@PACKAGE_ID",
  CURRENT_ROUTE: "@CURRENT_ROUTE",
  SUBSCRIPTION_HIDE: "@SUBSCRIPTION_HIDE",
  APP_OPENED_KEY: "@APP_OPENED_KEY",
  PAYMENT_STATUS: "@PAYMENT_STATUS",
  PROFILE_ID : '@PROFILE_ID',
  PROFILE_IMG : '@PROFILE_IMG',
  USER_STEP_DATA: '@USER_STEP_DATA',
  SEND_PASS:"@SEND_PASS",
  REFFER_ID:"@REFFER_ID",
  SELECTED_JOB_TYPE:"@SELECTED_JOB_TYPE",
  ACCESS_BASED:'@ACCESS_BASED',
  ACCESS_TOKEN:'@ACCESS_TOKEN'
};

// export async function storeData(key, value) {
//   await localStorage.setItem(key, value);
// }

export const storeData = async (key, value) => {
  // console.log("key and value stored--", key, value);
  await localStorage.setItem(key, value);
  //   try {
  //     await localStorage.setItem(key, value);
  //     return {
  //       success: true,
  //     };
  //   } catch (error) {
  //     return {
  //       success: false,
  //       error,
  //     };
  //   }
};
// export async function getData(key) {
//   return await localStorage.getItem(key).then((value) => {
//     return value;
//   });
// }
export const getData = async (key) => {
  try {
    const value = await localStorage.getItem(key);
    // console.log("key and value in get data--", key, value);
    return value;
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};
export async function clearData() {
  localStorage.removeItem(storageKey.AUTH_TOKEN);
  localStorage.removeItem(storageKey.USER_DATA);
  localStorage.removeItem(storageKey.USER_ID);
  localStorage.removeItem(storageKey.USER_ROLE);
  localStorage.removeItem(storageKey.TEMP_USER_ID);
  localStorage.removeItem(storageKey.TEMP_TOKEN);
  localStorage.removeItem(storageKey.COUNTRY_ID);
  localStorage.removeItem(storageKey.STATE_ID);
  localStorage.removeItem(storageKey.APPROVAL_STATUS);
  localStorage.removeItem(storageKey.USER_STATUS);
  localStorage.removeItem(storageKey.PACKAGE_ID);
  localStorage.removeItem(storageKey.SUBSCRIPTION_HIDE);
  localStorage.removeItem(storageKey.APP_OPENED_KEY);
  localStorage.removeItem(storageKey.PAYMENT_STATUS);
  localStorage.removeItem(storageKey.PROFILE_ID);
  localStorage.removeItem(storageKey.REFFER_ID);
  localStorage.removeItem(storageKey.ACCESS_BASED);
  localStorage.removeItem(storageKey.ACCESS_TOKEN);
  //   const keys = [
  //     storageKey?.AUTH_TOKEN,
  //     storageKey?.USER_DATA,
  //     storageKey?.USER_ID,
  //     storageKey?.USER_ROLE,
  //     storageKey?.TEMP_USER_ID,
  //     storageKey?.TEMP_TOKEN,
  //     storageKey?.ROOM_ID,
  //     storageKey?.COUNTRY_ID,
  //     storageKey?.STATE_ID,
  //     storageKey?.APPROVAL_STATUS,
  //     storageKey?.USER_STATUS,
  //     storageKey?.PACKAGE_ID,
  //     storageKey?.SUBSCRIPTION_HIDE,
  //     storageKey?.APP_OPENED_KEY,
  //     storageKey?.PAYMENT_STATUS,
  //   ];
  //   try {
  //     await localStorage.multiRemove(keys);
  //   } catch (e) {
  //     // remove error
  //   }
  console.log("Cleared Data from localStorage");
}

export async function removeData(keys) {
  //const keys = [storageKey.AUTH_TOKEN];
  try {
    await localStorage.removeItem(keys);
  } catch (e) {
    showToast("Failed to remove the data to the storage", "error");
  }
  console.log("Cleared Data from localStorage");
}
