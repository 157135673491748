import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addExtraPayRequest } from "../../Redux/Services/OtherServices";
import { isNumber, isString } from "../../Utility/validations";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import { sendSinglePartnerMailApi } from "../../Redux/Services/PartnerServices";
import { showToast } from "../../Utility";

export const SendMailModal = (props) => {
  const dispatch = useDispatch();
  const {
    setVisible,
    visible,
    selectedItem,
    setTab,
    setLoading,
    selectedRows,
    setSelectedRows,
    partnerId
  } = props;

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  function getValidationSchema(values) {
    return Yup.object().shape({
      reason:
        values.type === "default"
          ? Yup.string()
          : Yup.string().required("Email Description is required"),
      amount:
        values.type === "default"
          ? Yup.string()
          : Yup.string().required("Email Subject is required"),
    });
  }

  const handleSendMailToMultiple = async (values) => {
    const selected = selectedRows?.map((item) => ({
      user_email: item.user_email,
      user_name: item.name,
    }));
    let body = {
      type: values.type,
      partner_id: partnerId || loggedInUser?.user_data?.profile_id || "",
      user_list: selected || [],
    };
    if (values?.type === "custom") {
      body.subject = values.amount;
      body.description = values.reason;
    }
    setLoading(true);

    let res = await dispatch(sendSinglePartnerMailApi(body));
    setLoading(false);
    if (res?.status == 200) {
      showToast("Email send successfully", "success");
      setSelectedRows([]);
      formik.setFieldValue("amount", "");
      formik.setFieldValue("type", "default");
      formik.setFieldValue("reason", "");
      setVisible(false);
    } else {
    }
  };

  const onSubmit = async (values) => {
    let body = {
      user_email: selectedItem?.user_email || "",
      type: values.type,
      partner_id: selectedItem?.partner_id || "",
      user_name: selectedItem?.name || "",
    };
    if (values?.type === "custom") {
      body.subject = values.amount;
      body.description = values.reason;
    }
    setLoading(true);

    let res = await dispatch(sendSinglePartnerMailApi(body));
    setLoading(false);
    if (res?.status == 200) {
      showToast("Email send successfully", "success");
      formik.setFieldValue("amount", "");
      formik.setFieldValue("type", "default");
      formik.setFieldValue("reason", "");
      setVisible(false);
    } else {
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      reason: "",
      type: "default",
    },
    validationSchema: () => getValidationSchema(formik.values),
    onSubmit: selectedRows?.length ? handleSendMailToMultiple : onSubmit,
  });

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Email Content
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="form-group">
            <select
              name="type"
              id="type"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.type}
            >
              <option value={"default"}>Introduction Email</option>
              <option value={"custom"}>Custom Email</option>
            </select>
            {formik.errors.type && formik.touched.type && (
              <div className="error">{formik.errors.type}</div>
            )}
          </div>
          {formik.values.type === "custom" ? (
            <>
              <div className="form-group">
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  class="form-control"
                  placeholder="Email Subject"
                  onChange={(val) => {
                    formik.handleChange(val);
                    // if (val.target.value === " " || val.target.value === ".") {
                    // } else if (isNumber(val.target.value)) {

                    // }
                  }}
                  value={formik.values.amount}
                />
                {formik.errors.amount && formik.touched.amount && (
                  <div className="error">{formik.errors.amount}</div>
                )}
              </div>
              <div className="form-group">
                <textarea
                  type="textarea"
                  name="reason"
                  id="reason"
                  class="form-control"
                  placeholder={"Email Description"}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  value={formik.values.reason}
                />
                {formik.errors.reason && formik.touched.reason && (
                  <div className="error">{formik.errors.reason}</div>
                )}
              </div>
            </>
          ) : undefined}
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <button
            type="submit"
            className="wt-btn wt-save-identity"
            // type="submit"
          >
            Send Mail
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
