import React, { useEffect, useState } from "react";
import { ButtonToolbar, Container } from "react-bootstrap";
import { Form, Button, ProgressBar } from "react-bootstrap";
import {
  Header,
  Footer,
  PlansCard,
  BlogCard,
  InputField,
  DropdownList,
  MultiSelectDropdown,
} from "../../Components";
import { languages } from "../../Global";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  formValidation,
  isValidEmail,
  routeName,
  showToast,
} from "../../Utility";
import { InfoAlert } from "../../Components/Popups";
import { sendVerficationEmail } from "../../Redux/Services/AuthServices";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getData, storageKey } from "../../Utility/Storage";

export const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.authReducer);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tab, setTab] = useState(0);
  const [talent, setTalent] = useState("");
  const [language, setLanguage] = useState([]);
  const [infoText, setInfoText] = useState("");
  const [show, setShow] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [refferID, setRefferID] = useState("");
  const emailValid = isValidEmail(email);
  useEffect(() => {
    getRefferID();
  }, []);

  const getRefferID = async () => {
    let id = await getData(storageKey?.REFFER_ID);
    setRefferID(JSON?.parse(id));
  };

  const handleTab = (type) => {
    setShow(true);
    setTab(type);
    if (type == 2) {
      setTalent("");
    }
  };
  const talentTypes = [
    { label: "Model", value: "Model" },
    { label: "Model_kid", value: "Model Kid" },
    { label: "Actor", value: "Actor" },
    { label: "Actor_kid", value: "Actor Kid" },
    { label: "Photographer", value: "Photographer" },
  ];
  const clientTypes = [
    { label: "Client", value: "Client" },
    {
      label: "Modeling agencies and Influencers",
      value: "Modeling agencies and Influencers",
    },
  ];
  const handleForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      handleVerify();
    }
  };

  const handleVerify = async () => {
    let user_type;
    if (tab == 1) {
      user_type = talent;
    } else if (tab == 2) {
      if (talent == "Client") {
        user_type = "Client";
      } else if (talent == "Modeling agencies and Influencers") {
        user_type = "Partners";
      }
    }
    let verifyLink = 
    // refferID
    //   ? `http://booksculp.com${routeName?.REGISTER}?email=${email}&type=${user_type}&reffer=${refferID}`
    //   : 
      `http://booksculp.com${routeName?.REGISTER}?email=${email}&type=${user_type}`;
    console.log("verifyLinkverifyLink------", verifyLink);
    var body = {
      email: email,
      verify_link: verifyLink,
      type: "send_verification_list",
      user_type: user_type,
    };
    let res = await dispatch(sendVerficationEmail(body));
  };
  return (
    <>
      <Header />
      <InfoAlert
        title={tab == 1 ? "Talent" : "Client"}
        text={
          tab == 1
            ? "If you are applying as a model, Actor, or Photographer select this option."
            : "If you are hiring a model, actor, or photographer select this option."
        }
        show={show}
        setShow={setShow}
        confirm={selectedTab}
        setConfirm={setSelectedTab}
      />

      <div className="pageTitle">
        <div className="wt-title">
          <h1>Sign Up</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <a>Home</a>
            </li>
            <li className="last-item">Sign Up</li>
          </ol>
        </div>
      </div>
      <Container>
        <div className="registerForm">
          <Form noValidate validated={validated} onSubmit={handleForm}>
            <div class="show_type_register">
              <h4>I want to start as: </h4>

              <div className="wt-checkbox-wrap">
                <span
                  className={
                    tab == 1
                      ? "talent_tab tab_active"
                      : "talent_tab tab_inactive"
                  }
                  onClick={() => handleTab(1)}
                >
                  Talent
                </span>
                <span
                  className={
                    tab == 2
                      ? "talent_tab tab_active"
                      : "talent_tab tab_inactive"
                  }
                  onClick={() => handleTab(2)}
                >
                  Client
                </span>
              </div>
            </div>
            {tab != 0 && selectedTab ? (
              <div className="verification_contain_form wt-email-cont">
                <div className="wt-popuptitletwo">
                  <h4>It's Free to Sign Up and Get Started.</h4>
                </div>
                <div className="first_step_email">
                  <h4>Verify your email </h4>

                  <div className="form-group">
                    <span className="cu-select hair_color">
                      <DropdownList
                        required
                        title="User Type"
                        placeholder="Select User Type"
                        options={tab == 1 ? talentTypes : clientTypes}
                        value={talent}
                        setValue={setTalent}
                        isValid={validated && !talent}
                        page="auth"
                      />
                    </span>
                  </div>

                  <div className="form-group">
                    <span className="cu-select hair_color">
                      <InputField
                        required
                        type="email"
                        title="Enter email"
                        placeholder="Enter email"
                        value={email}
                        onChangeText={(e) => {
                          setEmail(e?.target?.value);
                        }}
                        isValid={validated && emailValid}
                        page="auth"
                        // toolTipText='deded'
                      />
                    </span>
                  </div>
                  <Button
                    variant=" wt-btn"
                    type="submit"
                    // onClick={() => {
                    //   validated && handleVerify();
                    // }}
                  >
                    Verify
                  </Button>
                </div>
              </div>
            ) : null}
          </Form>
        </div>
      </Container>

      <Footer />
    </>
  );
};
