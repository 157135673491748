import React, { useCallback, useEffect, useState } from "react";
import { Footer, Header, Loader } from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { addStripeResponse } from "../../Redux/Services/OtherServices";

const StripeResponse = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const success = queryParams?.get("success");
  const code = queryParams?.get("code");
  const state = queryParams?.get("state");
  const act = queryParams?.get("act");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isHit, setIsHit] = useState(false);

  const sendCodeData = async () => {
    try {
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        setIsHit(true);
        let body = {
          state: state,
          // code: code,
        };
        if(code){
          body.code = code
        }
        if(act){
          body.act = act
        }
        
        const res = await dispatch(addStripeResponse(body));
        setLoading(false);
        if (res?.status === 200) {
         
          navigate(routeName.PAYOUT, { replace: true });
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // const memoizedHandlePayStatus = useCallback(() => {
  //   if (act && state  && !isHit) {
  //     setIsHit(true)
  //     sendCodeData();
  //   }
  // }, []);
  
  // useEffect(() => {
  //   memoizedHandlePayStatus();
  // }, [memoizedHandlePayStatus]);

  useEffect(() => {
    if(window){
      if (code && success && !isHit) {
        setTimeout(() => {
          setIsHit(true);
          sendCodeData();
        }, 2000);
      }
    }
  }, []);

  useEffect(() => {
    if (window && act ==='update' && state && !isHit) {
      setIsHit(true)
      sendCodeData();
    }
  }, [act]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Loader loading={loading} />
      <Header />
      {/* <h4 onClick={sendCodeData} >Hello</h4> */}
      <Footer />
    </div>
  );
};

export default StripeResponse;
