import { combineReducers, configureStore } from "@reduxjs/toolkit";

// import { firestoreReducer } from "redux-firestore";
import { otherReducer } from "../Reducers/OtherReducers";
import { authReducer } from "../Reducers/AuthReducers";
const reducer = combineReducers({
  authReducer: authReducer,
  otherReducer: otherReducer,
  // firestore: firestoreReducer,
});
const store = configureStore({ reducer });
export default store;
