import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { routeName } from "../../Utility";

export const DashboardPopUp = (props) => {
  const { setVisible, visible, text, title } = props;
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
      {title === "ID Verification Missing" ? (
          <Button
            onClick={() => {
              setVisible(false);
              navigate(routeName.IDENTITY_VERIFY);
            }}
          >
            Verify Identity
          </Button>
        ) : undefined}
        <Button
          onClick={() => {
            setVisible(false);
          }}
        >
          Close
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};
