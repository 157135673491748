import React from "react";
import { Header, Footer, Loader } from "../../Components";
import Accordion from "react-bootstrap/Accordion";
import { getFaqList } from "../../Redux/Services/OtherServices";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

export const Faq = () => {
  const dispatch = useDispatch();
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Model");

  const getFaq = async () => {
    let body = [];
    setLoading(true);
    let res = await dispatch(getFaqList(body));
    if (res?.status == 200) {
      setFaqList(res?.results);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getFaq();
  }, []);

  const options = [
    {
      name: "Model",
      type: "Model",
    },
    {
      name: "Actor",
      type: "Actor",
    },
    {
      name: "Photographer",
      type: "Photographer",
    },
    {
      name: "Client",
      type: "Client",
    },
    {
      name: "Partner",
      type: "Partner",
    },
  ];

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <div className="pageTitle">
        <div className="wt-title">
          <h1>Frequently Asked Question</h1>
        </div>
      </div>
      <div className="container">
        <div class="wt-userprofileholder contactForm">
          <div className="row">
            <div className="dashboard_main">
              <div className="myJobs">
                <Tabs
                  defaultActiveKey="security-settings"
                  activeKey={selectedTab}
                  onSelect={(key) => setSelectedTab(key)}
                  className="mb-3"
                >
                  {options?.map((item, index) => (
                    <Tab key={index} eventKey={item.type} title={item.name}>
                      <div className="col-md-12">
                        <Accordion defaultActiveKey="0">
                          {faqList?.map((item, index) => {
                            return (
                              <>
                                <Accordion.Item eventKey={index}>
                                  <Accordion.Header>
                                    {item?.faq_question}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {item?.faq_answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            );
                          })}
                        </Accordion>
                      </div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
