import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { routeName } from "../../Utility";

export const PostCard = (props) => {
  const {
    cardData,
    type,
    setIsDeleteModal,
    setSelectedId,
    handleLikeUnlike,
    page,
    handleSaveUnsave,
    route
  } = props;
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className={"col-md-4"}>
        <div className="p-relative">
          <Slider {...settings}>
            {Object?.values(cardData?.media)?.map((image, index) => {
              return (
                <div onClick={() => {}} key={index}>
                  <img src={image?.url} alt={`Image ${index}`} />
                </div>
              );
            })}
          </Slider>
          <div className="wt-articlecontents">
            <div className="wt-title">
              <div className="wt-titletags">
                <a className="wt-articleby" target="_blank">
                  {cardData?.post_details?.post_title?.length > 40
                    ? cardData?.post_details?.post_title?.slice(0, 40) + "..."
                    : cardData?.post_details?.post_title || ""}
                </a>
              </div>
              <h3>
                <a>
                  {cardData?.post_details?.post_content?.length > 80
                    ? cardData?.post_details?.post_content?.slice(0, 80) + "..."
                    : cardData?.post_details?.post_content || ""}
                </a>
              </h3>
            </div>
            <ul className="wt-moreoptions">
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  handleLikeUnlike(
                    Number(cardData?.extra?.like_status) === 1
                      ? "unlike"
                      : "like",
                    cardData?.post_details?.ID
                  );
                }}
              >
                <i
                  className={
                    Number(cardData?.extra?.like_status) === 1
                      ? "fa fa-heart"
                      : "fa fa-heart-o"
                  }
                ></i>{" "}
                {cardData?.extra?.likes_count}{" "}
                {cardData?.extra?.likes_count <= 1 ? "Like" : "Likes"}
              </li>
              <li
                onClick={() => {
                  const queryParams = new URLSearchParams({
                    id: cardData?.post_details?.ID,
                    type,
                  });
                  navigate(
                    `${routeName.SOCIAL_DETAIL}?${queryParams.toString()}`
                  );
                }}
              >
                <a>
                  <i className="fa fa-comment"> </i>{" "}
                  {cardData?.extra?.comments_count || 0}{" "}
                  {cardData?.extra?.comments_count <= 1
                    ? "Comment"
                    : "Comments"}
                </a>
              </li>
            </ul>
            <ul className="actBtns">
              {page === "manage" ? (
                <>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      const queryParams = new URLSearchParams({
                        id: cardData?.post_details?.ID,
                      });
                      if (type === "post") {
                        navigate(
                          `${routeName.ADD_POST}?${queryParams.toString()}`
                        );
                      } else {
                        navigate(
                          `${routeName.ADD_PORTFOLIO}?${queryParams.toString()}`
                        );
                      }
                    }}
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </li>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      if (setIsDeleteModal) {
                        setIsDeleteModal(true);
                      }
                      if (setSelectedId) {
                        setSelectedId(cardData?.post_details?.ID);
                      }
                    }}
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </li>
                </>
              ) : undefined}
              {page === "save" ? (
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSaveUnsave(cardData?.post_details?.ID);
                  }}
                >
                  <i class={"fa fa-bookmark"}></i>
                </li>
              ) : undefined}
              <li>
                <i
                  class="fa fa-eye"
                  onClick={() => {
                    const queryParams = new URLSearchParams({
                      id: cardData?.post_details?.ID,
                      type,
                    });
                    navigate(
                      `${routeName.SOCIAL_DETAIL}?${queryParams.toString()}`
                    );
                  }}
                  aria-hidden="true"
                ></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
