import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { InputField } from "../InputField";

export const CAncelModal = (props) => {
  const {
    setVisible,
    visible,
    handleDirectConfirm,
    isAskModel,
    description,
    setDescription,
    handleAskToClient,
    setIsAskModel,
    data,
  } = props;

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  console.log(data?.data?.status, "klkjjjj");

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
        setIsAskModel(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancellation Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="jobCancel">
        {loggedInUser?.user_data?.user_role === 12 ||
        loggedInUser?.user_data?.user_role === 14 ? (
          <div>
            <div className="form-group">
              <p className="rejReason">
              { data?.data?.status ?<> <span>
                Your request for job cancellation has reject by talent.</span>
                <span>
                  <b>Reason : </b> {data?.data?.reject_reason}
                </span>
                </>
                    :<span>
                *You may cancel the job by selecting, "Cancellation Request"
                  with the talent. If they agree to the cancellation, there will
                  be no penalties besides transaction fees.
                    </span>}
              
              </p>
              {!isAskModel ? (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      // if(!data?.data?.status){
                      setIsAskModel(true);
                      // }
                    }}
                    className={data?.data?.status ? "btn_disable" : ""}
                    disabled={data?.data?.status}
                  >
                    {data?.data?.status
                      ? "Request Declined"
                      : "Request to cancel"}
                  </Button>
                </div>
              ) : undefined}

              {isAskModel ? (
                <InputField
                  placeholder="Write your reason message to cancel this project"
                  type="textarea"
                  value={description}
                  onChangeText={(e) => {
                    setDescription(e?.target?.value);
                  }}
                />
              ) : undefined}

              {isAskModel ? (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      handleAskToClient();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              ) : undefined}

              {isAskModel ? undefined : <div className="sepratorDiv">Or</div>}

              {isAskModel ? undefined : (
                <div>
                  <p>
                    <span>
                      * If you cancel the job 24 hours within the start date,
                      the model will receive 100% payment as a compensation.
                    </span>
                  </p>
                  <p>
                    <span>
                      * If the job is canceled between 48 hours and 24 hours
                      before the start date, there will be a 50% refund and the
                      model will receive 50% as a compensation.
                    </span>
                  </p>
                  <p>
                    <span>
                      If the job is canceled more than 48 hours before the start
                      date, there will be no charges deducted.
                    </span>
                  </p>
                </div>
              )}

              {isAskModel ? undefined : (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      handleDirectConfirm();
                    }}
                  >
                    Cancel Now
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : (

            <div className="form-group">
               <p className="rejReason">
              { data?.data?.status ?<> <span>
                {`Your request for job cancellation has reject by client.`}</span>
                <span>
                  <b>Reason : </b> {data?.data?.reject_reason}
                </span>
                </>
                    :<span>
                *You may cancel the job by selecting, "Cancellation Request"
                  with the client. If they agree to the cancellation, there will
                  be no penalties besides transaction fees.
                    </span>}
              
              </p>
             
              {isAskModel ? undefined : (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      // if(!data?.data?.status){
                        setIsAskModel(true);
                      // }
                    }}
                    className={data?.data?.status ? "btn_disable" : ""}
                    disabled={data?.data?.status}
                  >
                    {data?.data?.status ? "Request Declined" : "Request to cancel"}
                  </Button>
                </div>
              )}

            {isAskModel ? (
              <InputField
                placeholder="Write your reason message to cancel this project"
                type="textarea"
                value={description}
                onChangeText={(e) => {
                  setDescription(e?.target?.value);
                }}
              />
            ) : undefined}

            {isAskModel ? (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    handleAskToClient();
                  }}
                >
                  Submit
                </Button>
              </div>
            ) : undefined}
            {isAskModel ? undefined : <div className="sepratorDiv">Or</div>}

              {isAskModel ? undefined : (
                <div>
                  <p>
                    <span>
                      * If you cancel the job within 24 hours of the start date,
                      your rating will be reduced to 1 and 50% of the amount of your next job on this platform will
                      be deducted as compensation or penalty.
                    </span>
                  </p>
                  <p>
                   
                  </p>
                </div>
              )}

            {isAskModel ? undefined : (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    handleDirectConfirm();
                  }}
                >
                  Cancel Now
                </Button>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      {/* <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setIsAskModel(false);
            setVisible(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
