import React from "react";
import { SitePagination } from "./filtersComponents";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { isValidInput } from "../Utility/validations";
import SearchBar from "./SearchBar";
import { Container, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { routeName, showToast } from "../Utility";
import { useNavigate } from "react-router-dom";
import { Images } from "../Constants";
import moment from "moment";
import { useDispatch } from "react-redux";
import { selectedTabAction } from "../Redux/Actions/AdminActions";

const AdminTable = (props) => {
  const {
    totalPages,
    page,
    setPage,
    onPageChange,
    noDataFound,
    tab,
    Heading,
    btnText,
    onBtnPress,
    options,
    list,
    searchTerm,
    setSearchTerm,
    setDebouncedSearchTerm,
    setTab,
    handleGetFunctions,
    setSelectedId,
    setIsDeleteModal,
    setIsIdentityModal,
    setIsResendModal,
    setIsIdentityViewModal,
    setList,
    setIsProfileModal,
    onEditClick,
    handleProfileView,
    from,
    setIsReportModals,
    setReportedData,
    role,
    deletePause,
    setIsResumeModal,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleUserClick = async (item) => {
    dispatch(selectedTabAction(tab))
    if (
      item?.post_meta_details?.user_type?.includes("freelancer") ||
      item?.post_meta_details?.freelancer_type?.includes("photographer") ||
      item?.post_meta_details?.freelancer_type === "actor" ||
      item?.post_meta_details?.freelancer_type?.includes("model")
    ) {
      const queryParams = new URLSearchParams({
        id: item?.post_meta_details?.user_id,
      });
      navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    } else {
      const queryParams = new URLSearchParams({
        id: item?.post_meta_details?.user_id,
      });
      navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
    }
  };

  return (
    <div>
      <Container>
        <div className="list_cnnt">
          <h3>{`${Heading}`}</h3>

          {from === "report" ? undefined : (
            <a class="wt-btn" onClick={onBtnPress}>
              {`${btnText}`}
            </a>
          )}
        </div>
      </Container>
      <div className="dashboard_main">
        <div className="myJobs adminData">
          <Tabs
            defaultActiveKey="posted"
            id="list"
            activeKey={tab}
            onSelect={(type) => {
              setTab(type);
              setList([]);
              setPage(1);
              handleGetFunctions(type, "1");
            }}
          >
            {options?.map((item, index) => (
              <Tab key={index} eventKey={item.type} title={item.name}>
                <div class="wt-managejobcontent">
                  <div className="cards">
                    <div className="cards_header">
                      <div />

                      <SearchBar
                        searchTerm={searchTerm}
                        setDebouncedSearchTerm={setDebouncedSearchTerm}
                        value={searchTerm}
                        onCross={() => setSearchTerm("")}
                        onChange={(val) => {
                          if (isValidInput(val.target.value)) {
                            setSearchTerm(val.target.value);
                          }
                        }}
                      />
                    </div>
                    <TableContainer className="table_container">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Profile</TableCell>
                            <TableCell>Registration Date</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            {from === "report" ? (
                              <TableCell>Reported By</TableCell>
                            ) : undefined}
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {list?.length ? (
                          list?.map((item, i) => {
                            return (
                              <TableBody key={i}>
                                <TableRow>
                                  <TableCell>
                                    <div
                                      onClick={() => handleUserClick(item)}
                                      className="userImgName"
                                    >
                                      <img
                                        alt="profile"
                                        src={item?.profile_image || Images.user}
                                      />
                                      {`${item?.profile?.post_title || "-"}` ||
                                        ""}
                                    </div>
                                    <div className="admAction">
                                      {Number(deletePause) === 1 ? (
                                        <span
                                          onClick={() => {
                                            setSelectedId(item);
                                            setIsDeleteModal(true);
                                          }}
                                        >
                                          Restore
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setSelectedId(item);
                                            setIsDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                        </span>
                                      )}
                                      <span
                                        onClick={() => {
                                          setSelectedId(item);
                                          setIsResendModal(true);
                                        }}
                                      >
                                        Reset Password
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {item?.profile?.post_date?.slice(0, 10) ||
                                      "-"}
                                  </TableCell>
                                  <TableCell>
                                    {item?.post_meta_details?.user_type
                                      ? item?.post_meta_details?.user_type ===
                                          "freelancer" ||
                                        item?.post_meta_details?.user_type ===
                                          "freelancers"
                                        ? "MODEL"
                                        : item?.post_meta_details?.user_type?.toUpperCase()
                                      : item?.profile?.post_type === "employers"
                                      ? "CLIENT"
                                      : item?.profile?.post_type ===
                                        "freelancers"
                                      ? "MODEL"
                                      : item?.profile?.post_type?.toUpperCase()}
                                  </TableCell>
                                  <TableCell>
                                    {item?.post_meta_details?.user_email || ""}
                                  </TableCell>

                                  <TableCell>
                                    {item?.post_meta_details?.phone_number ||
                                      ""}
                                  </TableCell>

                                  {from === "report" ? (
                                    <TableCell
                                      onClick={() => {
                                        setReportedData(item?.reported_users);
                                        setIsReportModals(true);
                                      }}
                                    >
                                      <span>
                                        {item?.reported_users?.length}
                                      </span>
                                      <i
                                        class="fa fa-user"
                                        aria-hidden="true"
                                      ></i>
                                    </TableCell>
                                  ) : undefined}

                                  <TableCell align="center">
                                    <div className="table_actions">
                                      {Number(tab) == 4 ? undefined : (
                                        <>
                                          <IconButton>
                                            <div>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-idenetity`}
                                                  >
                                                    Identity Verification
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  onClick={() => {
                                                    if (
                                                      item?.identity
                                                        ?.attached_id?.length
                                                    ) {
                                                      setSelectedId(item);
                                                      setIsIdentityModal(true);
                                                    } else {
                                                      showToast(
                                                        "User doesnot add identity verification details"
                                                      );
                                                    }
                                                  }}
                                                  className={
                                                    item?.identity?.attached_id
                                                      ?.length
                                                      ? ""
                                                      : "diabled_view"
                                                  }
                                                >
                                                  {item?.post_meta_details
                                                    ?.identity_verified ===
                                                    "yes" ||
                                                  item?.post_meta_details
                                                    ?.identity_verified ===
                                                    "1" ? (
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                      color="green"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fa fa-times"
                                                      aria-hidden="true"
                                                    ></i>
                                                  )}
                                                  <i
                                                    class="fa fa-address-book-o"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </OverlayTrigger>

                                              {item?.identity?.attached_id
                                                ?.length ? (
                                                <p
                                                  onClick={() => {
                                                    setSelectedId(item);
                                                    setIsIdentityViewModal(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  View
                                                </p>
                                              ) : (
                                                <p></p>
                                              )}
                                            </div>
                                          </IconButton>

                                          <IconButton>
                                            <div>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-Application`}
                                                  >
                                                    Application Verification
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  onClick={() => {
                                                    setSelectedId(item);
                                                    setIsProfileModal(true);
                                                  }}
                                                  className="actionicons"
                                                >
                                                  {item?.post_meta_details
                                                    ?.profile_approval ===
                                                  "yes" ? (
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                      color="green"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fa fa-times"
                                                      aria-hidden="true"
                                                    ></i>
                                                  )}
                                                  <i
                                                    class="fa fa-address-book-o"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </OverlayTrigger>
                                              <p
                                                onClick={() => {
                                                  dispatch(selectedTabAction(tab))
                                                  if (handleProfileView) {
                                                    handleProfileView(
                                                      item?.post_meta_details
                                                        ?.user_id
                                                    );
                                                  } else {
                                                    const queryParams =
                                                      new URLSearchParams({
                                                        id: item
                                                          ?.post_meta_details
                                                          ?.user_id,
                                                      });
                                                    navigate(
                                                      `${
                                                        routeName.TALENT_PROFILE
                                                      }?${queryParams.toString()}`
                                                    );
                                                  }
                                                }}
                                              >
                                                Profile
                                              </p>
                                            </div>
                                          </IconButton>
                                        </>
                                      )}
                                      {Number(deletePause) === 2 ? (
                                        <IconButton>
                                          <div
                                            onClick={() => {
                                              setSelectedId(item);
                                              setIsResumeModal(true);
                                            }}
                                          >
                                            <div>
                                              <i
                                                class="fa fa-play-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <p>Resume</p>
                                          </div>
                                        </IconButton>
                                      ) : undefined}
                                      {item?.profile?.post_type ===
                                      "partners" ? (
                                        <IconButton>
                                          <div
                                            onClick={() => {
                                              dispatch(selectedTabAction(tab))
                                              const queryParams =
                                                new URLSearchParams({
                                                  id: item?.post_meta_details
                                                    ?.user_id,
                                                });
                                              navigate(
                                                `${
                                                  routeName.PARTNER_DETAIL_VIEW
                                                }?${queryParams.toString()}`
                                              );
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-idenetity`}
                                                >
                                                  View Details
                                                </Tooltip>
                                              }
                                            >
                                              <div>
                                                <i
                                                  class="fa fa-file-text"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </OverlayTrigger>

                                            <p>Details</p>
                                          </div>
                                        </IconButton>
                                      ) : undefined}
                                      <IconButton>
                                        <div
                                          onClick={() => {
                                            onEditClick(
                                              item?.post_meta_details?.user_id
                                            );
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-profile`}>
                                                Edit Profile
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              class="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                            ></i>
                                          </OverlayTrigger>

                                          <p>Edit</p>
                                        </div>
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })
                        ) : (
                          <TableBody>
                            <TableCell
                              align="center"
                              colSpan={10}
                              sx={{ color: "black" }}
                            >
                              {noDataFound}
                            </TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <div className="pg_vew">
                      {totalPages > 1 ? (
                        <SitePagination
                          module={list}
                          page={page}
                          onPageChange={onPageChange}
                          totalPages={totalPages}
                          setPage={setPage}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default AdminTable;
