import React from "react";
import { Images } from "../../Constants";
import { Header, Footer,Loader } from "../../Components";

export const TermsConditions = () => {
  return (
    <div>
    <Header />
     <div className="pageTitle">
        <div className="wt-title">
          <h1>Terms & Conditions</h1>
        </div>
      </div> 
      <div className="container">
      <article class="privacyContent">
     
<h3>Introduction</h3>
<p>Welcome to www.booksculp.com. This website is owned and operated by www.booksculp.com. By visiting our website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as ‘User Agreement’).</p>
<p>This agreement is in effect as of Feb 22, 2018.</p>
<p>BookSculp reserves the right to change the Terms from time to time without notice. You acknowledge and agree that it is your responsibility to review the Terms periodically to familiarize yourself with any modifications. Your continued use of the Service after such modifications will constitute acknowledgment and agreement of the modified Terms.</p>
<h3>What Book Sculp Offers</h3>
<p>Book Sculp is a listing service wherein models and talent scouts may create profiles and photo books and for creating business contacts, including with modeling agents and/or agencies and photographers. Book Sculp does not participate in any communication between its users, whether models, agencies or otherwise. If its users utilize BookSculp to enter into agreements with each other, Book Sculp is not a party to these agreements, is not liable for any breach of these agreements, and is not responsible for ensuring these agreements are executed or fulfilled. BookSculp’s users are responsible for setting their own pricing schemes in accordance with the law in whichever jurisdiction applies, including state and federal income tax and sales tax. Book Sculp collects its fees from its users directly, and is not a party to any payment agreement between users and cannot be held liable for any breach of payment between users.</p>

<h3>Responsible Use and Conduct</h3>
<p>By visiting our website and accessing the Service, you agree to use these Services only for the purposes intended as permitted by (a) the terms of these Terms, and (b) applicable laws, regulations and generally accepted online practices or guidelines.</p>
<p>Wherein, you understand that:</p>
<ol>
<li>Account Information: In order to access our Service, you may be required to provide certain information about yourself (such as identification, contact details, etc.) as part of the registration process, or as part of your ability to use the Service. You agree that any information you provide will always be accurate, correct, and up to date.</li>
<li>Confidentiality of Account Information: You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Service. Accordingly, you are responsible for all activities that occur under your account/s</li>
<li>Age of Users: By creating an account with Book Sculp, you affirm that you are 18 years of age or older, or possess legal parental or guardian consent, and are fully competent to enter into these Terms. If you are under 18 years of age, please do not use Book Sculp’s Services</li>
<li>Member Profiles: In the making of a model profile, you represent and warrant that you own the necessary licenses, rights, consents and permissions to use any and all member content, and likewise authorize Book Sculp to use all trademark, copyright or other proprietary rights in and to any and all content to enable inclusion and use of the profile content in the manner intended. You agree that you will not submit profile content that violates these Terms</li>
<li>Access: Accessing (or attempting to access) any of our Service by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Services through any automated, unethical or unconventional means.</li>
<li>Permitted Activity: Engaging in any activity that disrupts or interferes with our Services, including the servers and/or networks to which our Services are located or connected, is strictly prohibited.&nbsp;Attempting to copy, duplicate, reproduce, sell, trade, or resell our Services is strictly prohibited.</li>
<li>Disclaimer of Liability: You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.</li>
<li>Posting Content: Book Sculp may provide various open communication tools on the website, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that generally Book Sculp does not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our website, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content that:
<ol>
<li>is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;</li>
<li>infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party;</li>
<li>contains any type of unauthorized or unsolicited advertising; or</li>
<li>impersonates any person or entity, including any Book Sculp employees or representatives.</li>
</ol>
</li>
<li>Removal of Content: Book Sculp has the right at its sole discretion to remove any content that does not comply with these Terms, along with any content that it deems otherwise offensive, harmful, objectionable, inaccurate, or violates any third party copyrights or trademarks. Book Sculp not responsible for any delay or failure in removing such content. If you post content that Book Sculp chooses to remove, you hereby consent to such removal, and consent to waive any claim against Book Sculp.</li>
<li>Ownership of Public Postings: Book Sculp does not assume any liability for any content posted by you or any other third party users of www.booksculp.com. However, any content posted by you using any open communication tools on www.booksculp.com, provided that it doesn’t violate or infringe on any third party copyrights or trademarks, becomes the property of Book Sculp, and as such, gives BookSculp a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as it sees fit. This only refers and applies to content posted via open communication tools as described, and does not refer to information that is provided as part of the registration process, necessary in order to use the Services.</li>
<li>: You agree to indemnify and hold harmless Book Sculp and its parent company and affiliates, and their directors, officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from any violation of these Terms or the failure to fulfill any obligations relating to your account incurred by you or any other person using your account. Book Sculp reserves the right to take over the exclusive defense of any claim for which it is entitled to indemnification under these Terms. In such event, you shall provide Book Sculp with such cooperation as is reasonably requested.</li>
</ol>
<h3>Limitation of Warranties</h3>
<p>By using www.booksculp.com, you understand and agree that all Services provided are “as is” and “as available.” This means that Book Sculp does not represent or warrant to you that:</p>
<ol>
<li>the use of the Services will meet your needs or requirements;</li>
<li>the use of the Services will be uninterrupted, timely, secure or free from errors;</li>
<li>information obtained by using the Services will be accurate or reliable;</li>
<li>any defects in the operation or functionality of any Services provided will be repaired or corrected;</li>
<li>any content downloaded or otherwise obtained through the use of the Services is done at your own discretion and risk, and that you are solely responsible for any damage to your computer or other devices for any loss of data that may result from the download of such content; and</li>
<li>no information or advice, whether expressed, implied, oral or written, obtained by you from www.booksculp.com or through any Services provided shall create any warranty, guarantee, or conditions of any kind, except for those expressly outlined in these Terms.</li>
</ol>
<h3>Limitation of Liability</h3>
<p>In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against Book Sculp shall be limited to the amount you paid, if any, for use of products and/or services. Book Sculp will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using the Services, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.</p>
<h3>Copyrights/Trademarks</h3>
<p>All content and materials available on www.booksculp.com, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of Book Sculp, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by BookSculp.</p>
<h3>Termination of Use</h3>
<p>You agree that Book Sculp may, at its sole discretion, suspend or terminate your access to all or part of the website and Services with or without notice and for any reason, including, without limitation, breach of these Terms. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Services will immediately cease, and BookSculp reserves the right to remove or delete any information that you may have on file, including any account or login information.</p>
<h3>Arbitration</h3>
<p>Any controversy or claim arising out of these Terms shall be settled by the arbitration administered by the American Arbitration Association and governed by the American Arbitration Association Consumer Arbitration Rules. All claims for arbitration must be brought within one (1) year after the claim or cause of action arose or the claim is waived, regardless of any statute or law to the contrary. You agree to arbitrate only in your individual capacity, not as a representative or a member of a class. No claims may be joined with any other claims.</p>
<h3>Governing Law</h3>
<p>This website is controlled by Book Sculp from our offices located in the state of Arkansas, USA. It can be accessed by most countries around the world. As each country has laws that may differ from those of Arkansas, by accessing our website, you agree that the statutes and laws of Arkansas, without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this site.</p>
<p>Furthermore, for any claims or causes of action arising out of these Terms that are not subject to arbitration shall be brought in the federal or state courts located in Pulaski County, Arkansas, USA. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.</p>
<h3>Guarantee</h3>
<p>UNLESS OTHERWISE EXPRESSED, BOOKSCULP EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.&nbsp; If you have any questions or comments about these our Terms of Service as outlined above, you can contact Book Sculp at:</p>
<p>Book Sculp <br/>1901 Napa Valley Drive, Suite 100 <br/>Little Rock, Arkansas 72212 <br/>USA <br/><a href="mailto:info@booksculp.com">Info@booksculp.com</a> <br/><a href="tel:5017778090">(501) 777-8090</a>&nbsp;</p>

      </article>
      </div>
      <Footer />
    </div>
  );
};
