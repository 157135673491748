import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch } from "react-redux";
import { getFaqList } from "../../Redux/Services/OtherServices";
import { DeletePost, Loader } from "../../Components";
import { Accordion, Button, Card, Container } from "react-bootstrap";
import { addEditFaqListAPi } from "../../Redux/Services/AdminServices";
import { showToast } from "../../Utility";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const FaqDetail = () => {
  const dispatch = useDispatch();

  const [faqs, setFaqs] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedCategory, setselectedCategory] = useState("");

  const getFaq = async () => {
    try {
      setLoading(true);
      let body = {
        action: "get",
      };
      let res = await dispatch(addEditFaqListAPi(body));
      setLoading(false);
      if (res?.status === 200) {
        setFaqs(res?.results);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getFaq();
  }, []);

  const handleAddFaq = () => {
    if (!question || !answer || !selectedCategory) {
      return;
    }
    if (editIndex !== null) {
      const updatedFaqs = [...faqs];
      updatedFaqs[editIndex] = { faq_question: question, faq_answer: answer };
      setFaqs(updatedFaqs);
      setEditIndex(null);
      handleSave(updatedFaqs);
    } else {
      setFaqs([{ faq_question: question, faq_answer: answer }, ...faqs]);
      handleSave(
        [{ faq_question: question, faq_answer: answer }, ...faqs],
        "add"
      );
    }
    setQuestion("");
    setAnswer("");
    setIsDialogOpen(false);
  };

  const handleEditFaq = (index) => {
    setQuestion(faqs[index].faq_question);
    setAnswer(faqs[index].faq_answer);
    setEditIndex(index);
    setIsDialogOpen(true);
  };

  const handleDeleteFaq = () => {
    setFaqs(faqs.filter((_, i) => i !== selectedIndex));
    handleSave(
      faqs.filter((_, i) => i !== selectedIndex),
      "delete"
    );
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setQuestion("");
    setAnswer("");
    setEditIndex(null);
  };

  const handleSave = async (faqsList, type) => {
    try {
      setLoading(true);
      let body = {
        faq_options: faqsList,
      };

      const response = await dispatch(addEditFaqListAPi(body));
      setLoading(false);
      if (response?.status === 200) {
        showToast(
          type === "add"
            ? "FAQ added successfully"
            : type === "delete"
            ? `FAQ deleted successfully`
            : `FAQ's updated successfully`,
          "success"
        );
        setFaqs(response?.results);
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  // console.log(faqs);

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      <Container>
        <div className="list_cnnt">
          <h3>{`MANAGE FAQ's`}</h3>

          <a
            class="wt-btn"
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            Add Faq
          </a>
        </div>
      </Container>
      <Container>
        <div className="container">
          <div class="wt-userprofileholder acrdList">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  {faqs?.map((item, index) => {
                    return (
                      <>
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header>
                            {item?.faq_question}
                          </Accordion.Header>
                          <Accordion.Body>{item?.faq_answer}</Accordion.Body>
                          <div className="actIcon">
                            <i
                              onClick={() => handleEditFaq(index)}
                              class="fa fa-pencil"
                              aria-hidden="true"
                            ></i>
                            <i
                              onClick={() => {
                                setSelectedIndex(index);
                                setIsDeleteModal(true);
                              }}
                              class="fa fa-trash-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Accordion.Item>
                      </>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Dialog
        className="fullModel"
        open={isDialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>{editIndex !== null ? "Edit FAQ" : "Add FAQ"}</DialogTitle>
        <DialogContent>
          <div>
            <span>Question</span>
            <input
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="form-control"
              placeholder="Question"
            />
          </div>
          <div>
            <span>Answer</span>
            <textarea
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="form-control"
              placeholder="Answer"
            ></textarea>
          </div>
          <div className="form-group">
            <span>Select Category</span>
            <select
              name="category"
              className="form-control"
              onChange={(e) => setselectedCategory(e.target.value)}
              value={selectedCategory}
            >
              <option value="Model">Model</option>
              <option value={"Actor"}>Actor</option>
              <option value="Photographer">Photographer</option>
              <option value={"Client"}>Client</option>
              <option value="Partner">Partner</option>
            </select>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleDialogClose}
            color="primary"
            className="wt-btn"
          >
            Cancel
          </button>
          <button
            className={!question || !answer || !selectedCategory ? "disabled_btn" : "wt-btn"}
            onClick={handleAddFaq}
          >
            {editIndex !== null ? "Save" : "Add"}
          </button>
        </DialogActions>
      </Dialog>
      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeleteFaq}
          text="Are you sure you want to delete this Faq?"
        />
      ) : undefined}
      {/* <Button onClick={handleSave}>Save</Button> */}
    </SidebarLayout>
  );
};
